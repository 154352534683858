// eslint-disable-next-line no-restricted-imports
import { Button } from '@cbhq/cds-web/buttons/Button'
import type { ButtonProps } from '@cbhq/cds-web/buttons/Button'

import { useIsExternalUrl } from './useIsExternalUrl'
import { useAnalyticsContext } from '../../providers/AnalyticsProvider'
import { useLinkComponent } from '../../providers/LinkComponentProvider'
import { useLocaleContext } from '../../providers/LocaleProvider'
import { cleanUrlDuplicateSlashes } from '../../utils/cleanUrlDuplicateSlashes'
import { getIsAnchorLink } from '../../utils/getIsAnchorLink'
import { getIsRelativeUrl } from '../../utils/getIsRelativeUrl'
import { getLocalizedUrl } from '../../utils/getLocalizedUrl'
import { scrollToAnchor } from '../../utils/scrollToAnchor'
import { Div } from '../Div'

type LinkButtonProps = {
  children: React.ReactNode
  href: string
  'data-qa'?: string
} & Omit<ButtonProps, 'to'>

export const LinkButton = ({
  children,
  href,
  'data-qa': dataQa = '',
  ...props
}: LinkButtonProps) => {
  const cleanHref = cleanUrlDuplicateSlashes(href)
  const isRelativeUrl = getIsRelativeUrl(cleanHref)
  const isAnchorLink = getIsAnchorLink(cleanHref)
  const isExternalUrl = useIsExternalUrl(cleanHref)

  const { locale } = useLocaleContext()
  const LinkComponent = useLinkComponent()
  const { analyticsPrefix } = useAnalyticsContext()

  const localizedUrl = isRelativeUrl
    ? cleanHref
    : getLocalizedUrl(cleanHref, locale)

  const finalDataQa = `${analyticsPrefix}${
    dataQa && analyticsPrefix ? '-' : ''
  }${dataQa}`

  const onAnchorLinkPress = (event: React.MouseEvent) => {
    event.preventDefault()
    scrollToAnchor(cleanHref.replace('#', ''))
  }

  if (!!LinkComponent && isRelativeUrl) {
    return (
      <Div>
        <LinkComponent data-qa={finalDataQa} href={cleanHref}>
          <Button {...props} data-qa={finalDataQa}>
            {children}
          </Button>
        </LinkComponent>
      </Div>
    )
  }

  return (
    <Div>
      <Button
        {...props}
        to={localizedUrl}
        data-qa={finalDataQa}
        target={isExternalUrl ? '_blank' : undefined}
        onPress={isAnchorLink ? onAnchorLinkPress : undefined}
      >
        {children}
      </Button>
    </Div>
  )
}
