import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { composeRichTextComponents } from './composeRichTextComponents'
import type { ComposeRichTextComponentsProps } from './composeRichTextComponents'
import { ANALYTICS_KEY } from '../../../constants'
import { Text } from '../../Text'
import { InlineHyperLink, Paragraph } from '../components'
import type { RichTextOption, RichTextPropsType } from '../types'

export const disclaimerRichTextOptionsConfig: ComposeRichTextComponentsProps = {
  customRenderOptions: {
    [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
      InlineHyperLink({
        dataQa: ANALYTICS_KEY.LegalRichText,
        ...props,
      }),

    [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
      Paragraph({
        ...props,
        variant: 'legal',
        display: 'inline',
        color: props?.secondaryColor,
      }),

    [BLOCKS.HEADING_5]: (props?: RichTextPropsType) => (node, children) =>
      (
        <Text variant="label1" as="p" color={props?.color} spacingBottom={3}>
          {children}
        </Text>
      ),
  },
  hasOverrideContentfulParagraphWrapping: true,
}

export const disclaimerRichTextOptions: RichTextOption =
  composeRichTextComponents(disclaimerRichTextOptionsConfig)
