import type { Document } from '@contentful/rich-text-types'

import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { RichText, articleRichTextOptions } from '../../ui/RichText'

export type CountryFooterDisclaimerProps = {
  disclaimer: Document
  hasSpacingTop?: boolean
  hasSpacingHorizontal?: boolean
}

export const CountryFooterDisclaimer = ({
  disclaimer,
  hasSpacingTop = true,
  hasSpacingHorizontal = true,
}: CountryFooterDisclaimerProps) => {
  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="CountryFooterDisclaimer">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={hasSpacingHorizontal}
        >
          <RichText
            content={disclaimer}
            richTextOptions={articleRichTextOptions}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
