import type { MARKS } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { ANALYTICS_KEY } from '../../../constants'
import {
  List,
  Quote,
  Table,
  Heading,
  ListItem,
  Paragraph,
  InlineHyperLink,
  BlockEmbeddedEntry,
  BlockEmbeddedAsset,
  Divider,
} from '../components'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'
import { overrideContentfulParagraphWrapping } from '../utils'

export type ComposeRichTextComponentsProps = {
  hasOverrideContentfulParagraphWrapping?: boolean
  customRenderMarks?: Partial<{
    [P in MARKS]: (props?: any) => JSX.Element
  }>
  customRenderOptions?: Partial<{
    [P in BLOCKS | INLINES]: (props?: RichTextPropsType) => RenderNodePropsTypes
  }>
}

export const composeRichTextComponents =
  ({
    customRenderOptions,
    customRenderMarks,
    hasOverrideContentfulParagraphWrapping,
  }: ComposeRichTextComponentsProps = {}) =>
  (props?: RichTextPropsType) => {
    const mappedToPropsRenderOptions =
      customRenderOptions &&
      Object.keys(customRenderOptions)
        .map((key) => {
          const fn = customRenderOptions[key as BLOCKS | INLINES]
          return [key, fn && fn({ ...props })]
        })
        .reduce((acc, cur) => {
          return { ...acc, [cur[0] as string]: cur[1] }
        }, {})

    return {
      renderMark: { ...customRenderMarks },
      renderNode: {
        [BLOCKS.HEADING_1]: Heading({
          as: 'h1',
          ...props,
        }),
        [BLOCKS.HEADING_2]: Heading({
          as: 'h2',
          ...props,
        }),

        [BLOCKS.HEADING_3]: Heading({
          as: 'h3',
          ...props,
        }),

        [BLOCKS.HEADING_4]: Heading({
          as: 'h4',
          ...props,
        }),
        [BLOCKS.HEADING_5]: Heading({
          as: 'h5',
          ...props,
        }),
        [BLOCKS.HEADING_6]: Heading({
          as: 'h6',
          ...props,
        }),
        [BLOCKS.PARAGRAPH]: Paragraph({ ...props }),
        [BLOCKS.EMBEDDED_ASSET]: BlockEmbeddedAsset({ ...props }),
        [BLOCKS.EMBEDDED_ENTRY]: BlockEmbeddedEntry({
          ...props,
          additionalProps: { ...props, isFullWidth: false },
        }),
        [BLOCKS.LIST_ITEM]: ListItem({ ...props }),
        [BLOCKS.UL_LIST]: List({ as: 'ul', ...props }),
        [BLOCKS.OL_LIST]: List({ as: 'ol', ...props }),
        [BLOCKS.QUOTE]: Quote({ ...props }),
        [BLOCKS.TABLE]: Table(),
        [BLOCKS.HR]: Divider(),
        [INLINES.HYPERLINK]: InlineHyperLink({
          dataQa: ANALYTICS_KEY.SharedRichText,
          ...props,
        }),
        [INLINES.ASSET_HYPERLINK]: InlineHyperLink({
          ...props,
        }),

        ...mappedToPropsRenderOptions,
        ...(hasOverrideContentfulParagraphWrapping &&
          overrideContentfulParagraphWrapping()),
      },
    }
  }
