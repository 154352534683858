import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Highlight, themes } from 'prism-react-renderer'
import styled from 'styled-components'

import type { ComposeRichTextComponentsProps } from './composeRichTextComponents'
import { composeRichTextComponents } from './composeRichTextComponents'
import { media } from '../../../breakpoints'
import { ANALYTICS_KEY } from '../../../constants'
import { Div } from '../../Div'
import {
  InlineHyperLink,
  ListItem,
  Paragraph,
  BlockEmbeddedEntry,
} from '../components'
import type { RichTextOption, RichTextPropsType } from '../types'

type MarkTextProps = {
  props: Record<string, string>
}

const getLinesToHighlight = ({ props }: MarkTextProps) => {
  if (!props.children) {
    return ''
  }
  return props.children
}

export const articleRichTextOptionsConfig: ComposeRichTextComponentsProps = {
  customRenderMarks: {
    [MARKS.CODE]: function Code(text) {
      const textToRender =
        typeof text === 'string' ? text : getLinesToHighlight(text)
      return (
        <Highlight theme={themes.github} code={textToRender} language="tsx">
          {({ tokens, getLineProps, getTokenProps }) => (
            <Div
              as="pre"
              flexDirection="column"
              overflowX="scroll"
              css={{
                /* Chrome */
                '::-webkit-scrollbar': {
                  display: 'none',
                },
                /* Firefox */
                'scrollbar-width': 'none',
              }}
            >
              {tokens.map((line, i) => (
                <Div key={i} {...getLineProps({ line })} as="div">
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </Div>
              ))}
            </Div>
          )}
        </Highlight>
      )
    },
  },
  customRenderOptions: {
    [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
      InlineHyperLink({
        ...props,
        dataQa: ANALYTICS_KEY.ArticleRichText,
        foregroundInlineLink: props?.foregroundInlineLink,
      }),

    [BLOCKS.LIST_ITEM]: (props?: RichTextPropsType) =>
      ListItem({
        ...props,
        listWrapper: StyledLi,
        color: props?.secondaryColor,
      }),

    [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
      Paragraph({
        ...props,
        color: props?.secondaryColor,
      }),
    [BLOCKS.EMBEDDED_ENTRY]: (props?: RichTextPropsType) =>
      BlockEmbeddedEntry({
        ...props,
        additionalProps: {
          ...props,
          isFullWidth: false,
          hasSpacingHorizontal: false,
        },
      }),
  },
}

export const articleRichTextOptions: RichTextOption = composeRichTextComponents(
  articleRichTextOptionsConfig,
)

const StyledLi = styled.span`
  p {
    padding-top: 12px;
    ${media.md} {
      padding-top: 16px;
    }
  }
  overflow: hidden;
  word-break: break-all;
`
