import { useState, useEffect } from 'react'

import { IconButton } from '@cbhq/cds-web/buttons/IconButton'
import { Icon } from '@cbhq/cds-web/icons/Icon'

import { legacyButtonRadius } from '../../../constants'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGlobalMicrocopy } from '../../../providers/MicrocopyProvider'
import { Div } from '../../../ui/Div'
import { Text } from '../../../ui/Text'

const sanitizeSearchString = (val: string | string[] | undefined) =>
  (Array.isArray(val) ? val[0] : val)?.toLowerCase().trim()

export type SearchInputProps = {
  term?: string | string[]
  showSearchBar?: boolean
  navigateOnTyping?: boolean
  onSearch?: (nextTerm: string) => void
  setShowSearchBar?: (value: boolean) => void
}

export const SearchInput = ({
  term = '',
  onSearch = () => null,
  navigateOnTyping,
  setShowSearchBar,
  showSearchBar,
}: SearchInputProps) => {
  const [search, setSearch] = useState(Array.isArray(term) ? term[0] : term)
  const microcopies = useGlobalMicrocopy()

  const [_, cancel] = useDebounce(
    () => {
      if (!navigateOnTyping || !search) return

      const sanitizedSearch = sanitizeSearchString(search)
      const sanitizedTerm = sanitizeSearchString(term)

      if (
        sanitizedSearch &&
        sanitizedSearch?.length > 2 &&
        sanitizedSearch !== sanitizedTerm
      ) {
        onSearch(search)
      }
    },
    300,
    [search],
  )

  useEffect(() => {
    const firstTerm = Array.isArray(term) ? term[0] : term
    const sanitizedTerm = sanitizeSearchString(firstTerm)

    setSearch((current) => {
      const sanitizedSearch = sanitizeSearchString(current)

      return sanitizedSearch !== sanitizedTerm ? firstTerm : current
    })
  }, [term, setSearch])

  const onEnter = () => {
    cancel()
    if (search) onSearch(search)
  }

  return (
    <Div
      display="block"
      position="relative"
      borderRadius="rounded"
      gap={{ sm: 2, md: 3 }}
      maxWidth={{ sm: '100%', md: 700 }}
      css={{
        '> button': {
          top: -2,
          right: -11,
          position: 'absolute',
        },
      }}
    >
      <Text
        variant="label2"
        display="inherit"
        position="relative"
        css={{
          input: {
            height: 36,
            width: '100%',
            paddingLeft: 24,
            paddingRight: 8,
            borderRadius: legacyButtonRadius,

            '&:focus-visible': {
              outline: 'none !important',
            },
          },
          '> div': {
            top: 11,
            left: 0,
            position: 'absolute !important',
          },
        }}
      >
        <Icon name="search" size="s" color="primary" />
        <input
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && onEnter()}
          placeholder={microcopies.global.searchForTopicOrArticle}
        />
      </Text>
      {showSearchBar && setShowSearchBar && (
        <IconButton
          transparent
          name="close"
          variant="foregroundMuted"
          onPress={() => setShowSearchBar(false)}
        />
      )}
    </Div>
  )
}
