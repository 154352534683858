import { Fragment, useMemo } from 'react'

import styled from 'styled-components'

import type { PaletteAlias } from '@cbhq/cds-common'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { useGlobalMicrocopy } from '../../providers/MicrocopyProvider'
import { Div } from '../../ui/Div'
import { LinkPressable } from '../../ui/Link'
import { Media } from '../../ui/media'
import { Text } from '../../ui/Text'

export type WayfindingCardArticleProps = {
  headline: string
  href?: string
  body?: string
  mediaUrl?: string
  mediaAlt?: string
  mediaType?: 'video' | 'image' | 'none'
  thumbnailUrl?: string
  hasLightbox?: boolean
  readTime?: string
  tagLabel?: string
  tagHref?: string
  datePublished?: string
  style?: ComponentsThemeStyle
  maxLines?: number
}

export type WayfindingCardArticleInnerProps = {
  compact?: boolean
  largeThumbnail?: boolean
  long?: boolean
  aspectRatio?: 'long' | 'square'
}

const DEFAULT_ALT_TEXT = 'Card Image'

export const WayfindingCardArticle = ({
  headline,
  href,
  body,
  mediaUrl,
  mediaAlt,
  mediaType = 'image',
  thumbnailUrl,
  hasLightbox,
  readTime,
  datePublished: date,
  tagLabel,
  tagHref,
  style = 'default',
  largeThumbnail,
  long: renderAsLong,
  maxLines = 5,
  compact,
  aspectRatio = 'long',
}: WayfindingCardArticleProps & WayfindingCardArticleInnerProps) => {
  const { foreground, foregroundSecondary, primary } = componentsThemeMap[style]
  const microcopies = useGlobalMicrocopy()

  const LinkWrapper = useMemo(() => {
    if (!href) return Fragment
    else {
      const Link = ({ children }: { children: React.ReactElement }) => (
        <Div
          display="contents"
          css={{
            a: {
              display: 'contents',
            },
          }}
        >
          <LinkPressable href={href}>{children}</LinkPressable>
        </Div>
      )

      return Link
    }
  }, [href])

  const cardEyebrow = useMemo(
    () => (
      <Div
        spacingTop={2}
        gap={0.5}
        alignItems="center"
        css={{ whiteSpace: 'nowrap' }}
      >
        {tagLabel &&
          (tagHref ? (
            <LinkPressable href={tagHref} data-qa={ANALYTICS_KEY.CardEyebrow}>
              <Text
                variant="legal"
                as="p"
                color={primary}
                css={{ textDecoration: 'underline' }}
              >
                {tagLabel}
                {(date || readTime) && ','}
              </Text>
            </LinkPressable>
          ) : (
            <Text variant="legal" as="p" color={foreground}>
              {tagLabel}
              {(date || readTime) && ','}
            </Text>
          ))}

        {date && (
          <Text variant="legal" as="p" color={foregroundSecondary}>
            {date}
            {readTime && ','}
          </Text>
        )}

        {readTime && (
          <Text variant="legal" as="p" color={foregroundSecondary}>
            {readTime}{' '}
            {mediaType === 'video'
              ? microcopies.global.videoDuration
              : microcopies.global.readTime}
          </Text>
        )}
      </Div>
    ),
    [
      date,
      readTime,
      tagHref,
      tagLabel,
      mediaType,
      foregroundSecondary,
      primary,
      foreground,
      microcopies,
    ],
  )

  const cardWidth = renderAsLong
    ? { sm: undefined, md: 200, lg: compact ? 278 : 379 }
    : undefined

  const imageHeight =
    aspectRatio === 'square'
      ? undefined
      : compact
      ? 146
      : { sm: 172, md: 172, lg: largeThumbnail ? 311 : 202 }

  const isVideo = mediaType === 'video'
  const isNoMedia = mediaType === 'none'
  const MediaLinkWrapper = isVideo ? Fragment : LinkWrapper

  return (
    <CardContainer
      flexDirection={renderAsLong ? { sm: 'column', md: 'row' } : 'column'}
      alignItems={renderAsLong ? { sm: 'baseline', md: 'center' } : 'baseline'}
      overflow="hidden"
      $isVideo={isVideo}
      $hoverColor={primary}
    >
      <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.CardImage}>
        {mediaType !== 'none' && (
          <MediaLinkWrapper>
            <Media
              alt={mediaAlt || DEFAULT_ALT_TEXT}
              src={mediaUrl}
              height={imageHeight}
              maxWidth={cardWidth}
              minWidth={cardWidth}
              className={IMAGE_CLASS}
              overflow="hidden"
              mediaType={mediaType}
              thumbnailUrl={thumbnailUrl}
              thumbnailAlt={mediaAlt}
              hasLightbox={hasLightbox}
            />
          </MediaLinkWrapper>
        )}
      </AnalyticsProvider>

      {!renderAsLong && cardEyebrow}
      <Div
        flexDirection="column"
        spacingStart={renderAsLong ? { sm: 0, md: isNoMedia ? 0 : 6 } : 0}
        spacingTop={renderAsLong ? { sm: isNoMedia ? 0 : 2, md: 0 } : { sm: 2 }}
        className={CONTENT_CLASS}
        flex={1.7}
      >
        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.CardHeader}>
          <LinkWrapper>
            <>
              <Text
                variant={{ sm: 'title4', lg: compact ? 'title4' : 'title2' }}
                as="h3"
                color={foreground}
                css={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': `${maxLines}`,
                  '-webkit-box-orient': 'vertical',
                }}
              >
                {headline}
              </Text>

              {body && (
                <Text
                  variant="body"
                  spacingTop={1}
                  as="p"
                  color={foregroundSecondary}
                  css={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': `${maxLines}`,
                    '-webkit-box-orient': 'vertical',
                  }}
                >
                  {body}
                </Text>
              )}
            </>
          </LinkWrapper>
        </AnalyticsProvider>
        {renderAsLong && cardEyebrow}
      </Div>
    </CardContainer>
  )
}

const IMAGE_CLASS = 'card-article-image'
const CONTENT_CLASS = 'card-article-content'

const CardContainer = styled(Div)<{
  $isVideo?: boolean
  $hoverColor?: PaletteAlias
}>`
  .${IMAGE_CLASS} {
    border: solid 1px;
    border-color: ${colorToVariable('line')};
  }

  ${({ $isVideo, $hoverColor = 'primary' }) =>
    $isVideo
      ? `
        .${CONTENT_CLASS} {
          &:hover {
            h3 {
              color: ${colorToVariable($hoverColor)};
            }
          }
        }
      `
      : `
        &:hover {
          h3 {
            color: ${colorToVariable($hoverColor)};
          }
        }
      `}
`
