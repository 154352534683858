import { BLOCKS } from '@contentful/rich-text-types'
import type { Document } from '@contentful/rich-text-types'

export const splitArticlePerParagraphs = (content: Document) => {
  if (content?.content?.length < 1) return []

  const sectionedContent = content?.content?.reduce<Document[]>((acc, cur) => {
    const isHeader =
      cur.nodeType === BLOCKS.HEADING_2 ||
      cur.nodeType === BLOCKS.HEADING_3 ||
      cur.nodeType === BLOCKS.HEADING_4 ||
      cur.nodeType === BLOCKS.HEADING_5 ||
      cur.nodeType === BLOCKS.HEADING_6

    if (isHeader || acc.length < 1) {
      return [
        ...acc,
        {
          content: [cur],
          data: {},
          nodeType: BLOCKS.DOCUMENT,
        },
      ]
    }

    acc[acc.length - 1].content.push(cur)

    return acc
  }, [])

  return sectionedContent
}
