import { useMemo, useRef, useEffect } from 'react'

import { useInView } from '../../hooks/useInView'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import type { SelectInputRef } from '../../ui/Select/SelectInput'
import { SelectInput } from '../../ui/Select/SelectInput'
import { scrollToAnchor } from '../../utils/scrollToAnchor'
import type { QuickAnchor } from '../EditorialArticleNavigationList'

export type EditorialArticleNavigationSelectProps = {
  quickScrollContent?: QuickAnchor[]
}

export const EditorialArticleNavigationSelect = ({
  quickScrollContent = [],
  children,
}: React.PropsWithChildren<EditorialArticleNavigationSelectProps>) => {
  const anchors = useMemo(
    () => quickScrollContent?.map((q) => q.anchor),
    [quickScrollContent],
  )

  const anchorIndexInView = useInView(anchors, 20)

  const selectRef = useRef<SelectInputRef>()
  const scrollInMotionToRef = useRef<string | null>(null)

  const selectOptions = useMemo(
    () =>
      quickScrollContent.map((opt) => ({ value: opt.anchor, label: opt.text })),
    [quickScrollContent],
  )

  useEffect(() => {
    if (anchorIndexInView !== -1) {
      if (scrollInMotionToRef.current) {
        if (
          selectOptions[anchorIndexInView].value === scrollInMotionToRef.current
        )
          scrollInMotionToRef.current = null
      } else {
        selectRef.current?.setSelectValue(selectOptions[anchorIndexInView])
      }
    }
  }, [anchorIndexInView, selectOptions])

  const onSelectChange = (nextValue: string) => {
    scrollInMotionToRef.current = nextValue
    scrollToAnchor(nextValue, 180)
  }

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="EditorialArticleNavigationSelect">
        <SelectInput
          onChange={onSelectChange}
          label=""
          optionValues={selectOptions}
          selectRef={selectRef}
        >
          {children}
        </SelectInput>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
