type ShareLinkSvgProps = {
  width?: string | number
  height?: string | number
  className?: string
  children: React.ReactNode
}

export const ShareLinkSvg = ({
  width = '12',
  height = '12',
  className,
  children,
}: ShareLinkSvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {children}
    </svg>
  )
}
