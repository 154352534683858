import type { RenderNode } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document, Block, Inline } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'

import { Text } from '../../Text'

function generateRenderNodeOptions(nodeTypes: string[]) {
  const result: RenderNode = {}

  for (const nodeType of nodeTypes) {
    result[nodeType] = (_, children) => children
  }

  return result
}

export function untagContentfulNode(node: Document, nodeTypes: string[]) {
  return documentToReactComponents(node, {
    renderNode: generateRenderNodeOptions(nodeTypes),
  })
}

export function overrideContentfulParagraphWrapping() {
  return {
    [BLOCKS.LIST_ITEM]: (node: Block | Inline, _: React.ReactNode) =>
      untagContentfulNode(node as Document, [BLOCKS.PARAGRAPH]),
    [BLOCKS.QUOTE]: (node: Block | Inline, _: React.ReactNode) => (
      <Text variant="display2" as="h2">
        {untagContentfulNode(node as Document, [BLOCKS.PARAGRAPH])}
      </Text>
    ),
  }
}
