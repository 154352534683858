import { Banner } from './Banner'
import { MainNavbar } from './MainNavbar'
import { SubNav } from './SubNav'
import type { NavbarProps } from './types'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'

export const GlobalNavbar = ({
  primaryLogoTitle,
  primaryLogoHref,
  primaryLogoImageUrl,
  navLinks,
  secondaryCtaLabel,
  secondaryCtaUrl,
  primaryCtaLabel,
  primaryCtaUrl,

  subnav,
  banner,
  style = 'default',

  secondaryLogo,
  secondaryLogoHref,
  showSecondaryLogoOnMobile = false,
  columnCount = 2,
  regionDropdownCTA,
  customSignInCTA,
  contactUsUrl,
  contactUsText,
  customLogoHeight,
}: NavbarProps) => {
  const { label, url: bannerUrl } = banner || {}

  const hasSubnav =
    (subnav?.productName && subnav?.productUrl) ||
    subnav?.subNavLinks?.length ||
    subnav?.hasSearch

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="GlobalNavbar">
        <Div
          as="header"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="sticky"
          zIndex={10}
          width="100%"
          top={0}
          css={{ pointerEvents: 'none', nav: { pointerEvents: 'all' } }}
        >
          {label && <Banner label={label} url={bannerUrl} />}

          <MainNavbar
            hasBanner={!!label}
            primaryLogoTitle={primaryLogoTitle}
            primaryLogoHref={primaryLogoHref}
            primaryLogoImageUrl={primaryLogoImageUrl}
            navLinks={navLinks}
            secondaryCtaLabel={secondaryCtaLabel}
            secondaryCtaUrl={secondaryCtaUrl}
            primaryCtaLabel={primaryCtaLabel}
            primaryCtaUrl={primaryCtaUrl}
            style={style}
            secondaryLogo={secondaryLogo}
            secondaryLogoHref={secondaryLogoHref}
            showSecondaryLogoOnMobile={showSecondaryLogoOnMobile}
            columnCount={columnCount}
            regionDropdownCTA={regionDropdownCTA}
            customSignInCTA={customSignInCTA}
            contactUsUrl={contactUsUrl}
            contactUsText={contactUsText}
            customLogoHeight={customLogoHeight}
          />

          {hasSubnav && (
            <SubNav {...subnav} hasBanner={!!label} style={style} />
          )}
        </Div>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
