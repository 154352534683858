import type { Document } from '@contentful/rich-text-types'
import type { Asset } from 'contentful'

import {
  Wayfinding,
  WayfindingCardArticle,
  splitArticlePerParagraphs,
  formatDateWithoutTimeZone,
} from '@cbhq/cdx-components'

import type { ComposePageEntry } from '../../globals/composePage'
import { getComposeEntryProps } from '../../globals/composePage'
import { mapEntryLink } from '../../globals/navlink'
import type { EditorialTemplateFields } from '../Editorial'

type ListingWayfindingProps = {
  articles: ComposePageEntry<EditorialTemplateFields>[]
  fallbackImageUrl?: string
  fallbackImageAlt?: string
}

type ComponentAuthorFields = {
  authorName: string
  authorTitle?: string
  authorImage?: Asset
  authorBioRichText?: Document
}

export const ListingWayfinding = ({
  articles,
  fallbackImageUrl,
  fallbackImageAlt,
}: ListingWayfindingProps) => {
  return (
    <Wayfinding columns={1} rows="all" compact>
      {articles
        ?.filter((entry) => entry?.fields.content?.fields.headline)
        .map((entry, index) => {
          const {
            headline,
            image,
            readTime,
            composeSlug,
            tags,
            excerpt,
            publicationDate,
            articleBody,
          } = getComposeEntryProps(entry)

          const firstSentence =
            articleBody && getFirstSentenceOfArticle(articleBody)

          const { label: tagLabel, link } = tags?.[0].fields || {}
          const tagLink = mapEntryLink(link)

          return (
            <WayfindingCardArticle
              key={index}
              mediaAlt={image?.fields?.title || fallbackImageAlt}
              mediaUrl={image?.fields?.file?.url || fallbackImageUrl}
              headline={headline || ''}
              href={composeSlug}
              body={excerpt || firstSentence}
              datePublished={formatDateWithoutTimeZone(publicationDate)}
              readTime={readTime}
              tagLabel={tagLabel}
              tagHref={tagLink.url && `/${tagLink.url}`}
              maxLines={2}
            />
          )
        })}
    </Wayfinding>
  )
}

const firstSentenceRegex = /^(.*?)[.?!]\s/

export const getFirstSentenceOfArticle = (article: Document) => {
  const articleParagraphs = splitArticlePerParagraphs(article)

  const articleParagraph = articleParagraphs[0].content.map(
    (paragraph: { content: any[] }) =>
      paragraph.content.map(
        (data: { value: any; content: { value: any }[] }) => {
          if (data.value) return data.value
          if (data.content) return data.content[0]?.value
        },
      ),
  )
  const firstSentence = articleParagraph
    .flat()
    .join(' ')
    .match(firstSentenceRegex)
  return (firstSentence && firstSentence[0]) || ''
}
