import { lazy, useState, Suspense, useEffect } from 'react'

import { LogoWordmark } from '@cbhq/cds-web/icons/LogoWordmark'
import { palette } from '@cbhq/cds-web/tokens'

import { BurgerButton } from './BurgerButton'
import {
  NAVBAR_SECONDARY_LOGO_WIDTH,
  NAVBAR_LOGO_HEIGHT,
  NAVBAR_LOGO_WIDTH,
  NAVBAR_MAX_WIDTH,
  NAVBAR_NAV_HEIGHT,
} from './constants'
import { Navlink } from './Navlink'
import type { MainNavbarProps } from './types'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { useWindowResize } from '../../hooks/useWindowResize'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { useLogger } from '../../providers/LoggerProvider'
import { CoinbaseLogo } from '../../ui/CoinbaseLogo'
import { Div } from '../../ui/Div'
import { Image } from '../../ui/Image'
import { Link, LinkButton } from '../../ui/Link'
import { LinkPressable } from '../../ui/Link/LinkPressable'
import { SkeletonLoader } from '../../ui/SkeletonLoader'
import { modifyLoginUrlPerEnvironment } from '../../utils/modifyLoginUrlPerEnvironment'

const LazyMobileMenu = lazy(async () => ({
  default: (await import('./MobileMenu')).MobileMenu,
}))

export const MainNavbar = ({
  primaryLogoHref,
  primaryLogoImageUrl,
  navLinks,
  secondaryCtaLabel,
  secondaryCtaUrl,
  primaryCtaLabel,
  primaryCtaUrl,
  hasBanner,
  style = 'default',

  secondaryLogo,
  secondaryLogoHref,
  showSecondaryLogoOnMobile,
  columnCount,
  regionDropdownCTA,
  customSignInCTA,
  contactUsUrl,
  contactUsText,
  customLogoHeight,
}: MainNavbarProps & { hasBanner: boolean }) => {
  const [activeTab, setActiveTab] = useState<string>()
  const handleCloseTrapdoor = () => setActiveTab(undefined)

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [envSecondaryCtaUrl, setEnvSecondaryCtaUrl] = useState(secondaryCtaUrl)
  const { notifyError } = useLogger()

  useEffect(() => {
    try {
      const envUrl = modifyLoginUrlPerEnvironment(secondaryCtaUrl)
      if (secondaryCtaUrl !== envUrl) setEnvSecondaryCtaUrl(envUrl)
    } catch (error) {
      notifyError('Error modifying login URL', (error as Error).message)
    }
  }, [secondaryCtaUrl])

  useWindowResize(() => setMobileMenuOpen(false))

  const toggleIsOpen = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      toggleIsOpen()
    }

    if (event.key === 'Escape' && isMobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }

  const { foreground, background } = componentsThemeMap[style]

  return (
    <>
      <Div
        as="nav"
        width="100%"
        zIndex={3}
        justifyContent="center"
        flexDirection="column"
        background={background}
        alignItems="center"
        css={{ borderBottom: `1px solid ${palette.line}` }}
      >
        <Div
          maxWidth={NAVBAR_MAX_WIDTH}
          height={NAVBAR_NAV_HEIGHT}
          width="100%"
          spacingHorizontal={3}
          onMouseLeave={handleCloseTrapdoor}
          background={background}
        >
          <Div
            justifyContent="flex-start"
            flexGrow={2}
            flexBasis={{ sm: 'auto', lg: NAVBAR_LOGO_WIDTH }}
            alignItems="center"
            onMouseEnter={handleCloseTrapdoor}
          >
            {!secondaryLogo && (
              <LinkPressable
                href={
                  primaryLogoImageUrl
                    ? secondaryLogoHref || primaryLogoHref
                    : primaryLogoHref || '/'
                }
                data-qa={ANALYTICS_KEY.MainLogo}
              >
                {primaryLogoImageUrl ? (
                  <Div
                    width="auto"
                    height={
                      customLogoHeight && primaryLogoImageUrl
                        ? customLogoHeight
                        : NAVBAR_LOGO_HEIGHT
                    }
                    css={{
                      img: {
                        width: 'auto',
                      },
                    }}
                  >
                    <Image
                      src={primaryLogoImageUrl}
                      alt="Logo"
                      priority
                      loading="eager"
                    />
                  </Div>
                ) : (
                  <Div width={NAVBAR_LOGO_WIDTH} height={NAVBAR_LOGO_HEIGHT}>
                    <CoinbaseLogo style={style} />
                  </Div>
                )}
              </LinkPressable>
            )}

            {!!secondaryLogo && (
              <LinkPressable
                href={secondaryLogoHref || primaryLogoHref || ''}
                data-qa={ANALYTICS_KEY.MainLogoHelpCenter}
              >
                <>
                  <Div
                    width={NAVBAR_SECONDARY_LOGO_WIDTH}
                    height={NAVBAR_LOGO_HEIGHT}
                    display={{
                      sm: showSecondaryLogoOnMobile ? 'flex' : 'none',
                      lg: 'flex',
                    }}
                  >
                    {secondaryLogo}
                  </Div>
                  {!showSecondaryLogoOnMobile && (
                    <Div
                      width={NAVBAR_LOGO_WIDTH}
                      height={NAVBAR_LOGO_HEIGHT}
                      display={{ sm: 'flex', lg: 'none' }}
                    >
                      <LogoWordmark />
                    </Div>
                  )}
                </>
              </LinkPressable>
            )}
          </Div>

          <Div
            justifyContent="center"
            flexGrow={6}
            flexBasis={{ sm: 'auto', lg: 0 }}
            display={{ sm: 'none', lg: 'flex' }}
          >
            {navLinks &&
              navLinks?.map(
                ({ url, label, trapdoorLinks, productLink }, index) => (
                  <AnalyticsProvider
                    key={`${index}-${label}`}
                    analyticsPrefix={`${ANALYTICS_KEY.Navlink}${index}`}
                  >
                    <Navlink
                      url={url}
                      label={label}
                      trapdoorLinks={trapdoorLinks || []}
                      productLink={productLink}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      hasBanner={hasBanner}
                      columnCount={columnCount}
                      color={foreground}
                    />
                  </AnalyticsProvider>
                ),
              )}
          </Div>

          <Div
            justifyContent="flex-end"
            alignItems="center"
            flexGrow={2}
            flexBasis={0}
            onMouseEnter={handleCloseTrapdoor}
          >
            <Div
              spacingHorizontal={2}
              css={{ 'span:hover': { color: palette.primary } }}
              display={{ sm: 'none', lg: 'flex' }}
            >
              {secondaryCtaLabel && (
                <Link
                  href={envSecondaryCtaUrl || ''}
                  variant="label1"
                  data-qa={ANALYTICS_KEY.SecondaryCtaLabel}
                >
                  <Div as="span" color={foreground}>
                    {secondaryCtaLabel}
                  </Div>
                </Link>
              )}
            </Div>

            {primaryCtaUrl && primaryCtaLabel && (
              <Div display="contents">
                <LinkButton
                  compact
                  href={primaryCtaUrl}
                  data-qa={ANALYTICS_KEY.PrimaryCtaLabel}
                >
                  {primaryCtaLabel}
                </LinkButton>
              </Div>
            )}

            <Div>
              {regionDropdownCTA && (
                <Div display={{ sm: 'none', lg: 'contents' }}>
                  {regionDropdownCTA}
                </Div>
              )}

              {!!customSignInCTA && customSignInCTA}
            </Div>

            <Div
              onClick={toggleIsOpen}
              onKeyDown={handleKeyPress}
              spacing={1}
              css={{ tabIndex: 0, margin: '10px -10px 10px 20px' }}
              display={{ sm: 'flex', lg: 'none' }}
            >
              <BurgerButton isOpen={isMobileMenuOpen} />
            </Div>
          </Div>
        </Div>
      </Div>

      {isMobileMenuOpen && (
        <Div as="nav" width="100%" flexDirection="column" zIndex={2}>
          <Suspense fallback={<SkeletonLoader />}>
            <LazyMobileMenu
              navLinks={navLinks}
              secondaryCtaLabel={secondaryCtaLabel}
              secondaryCtaUrl={envSecondaryCtaUrl}
              primaryCtaLabel={primaryCtaLabel}
              primaryCtaUrl={primaryCtaUrl}
              handleClose={() => setMobileMenuOpen(false)}
              hasBanner={hasBanner}
              contactUsUrl={contactUsUrl}
              contactUsText={contactUsText}
            />
          </Suspense>
        </Div>
      )}
    </>
  )
}
