// TODO: This file is temporary until we export it from cb/components
// this locales for language picker

export const localeCodeToDisplayName = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español - España',
  'es-LA': 'Español - América Latina',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português - Brasil',
  'pt-PT': 'Português - Portugal',
  ru: 'Pусский',
  th: 'ไทย',
  tr: 'Türkçe',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
}

export type langPickerSupportedLocales = keyof typeof localeCodeToDisplayName

export const langPickerSupportedLocales: string[] = [
  'es-us',
  'en-de',
  'en-gb',
  'en-fr',
  'fr-fr',
  'en-ca',
  'fr-ca',
  'es-es',
  'en-es',
  'pt-br',
  'en-br',
  'pt-pt',
  'en-pt',
  'en-au',
  'en-sg',
  'en-nl',
  'en-it',
  'zh-sg',
  'en-tr',
  'es-la',
  'zh-cn',
  'zh-tw',
  'de',
  'es',
  'fr',
  'it',
  'nl',
  'pl',
  'ru',
  'th',
  'tr',
  'en',
]
