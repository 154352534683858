import { useMemo } from 'react'

import type { TopLevelBlock, Text, Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import { useDeviceSpectrum } from '@cbhq/cds-web/hooks/useDeviceSpectrum'
import { Divider } from '@cbhq/cds-web/layout'
import { ThemeProvider as CDSThemeProvider } from '@cbhq/cds-web/system/ThemeProvider'
import type { ThemeProviderProps } from '@cbhq/cds-web/system/ThemeProvider'
import type { RichTextOption } from '@cbhq/cdx-components'
import {
  Div,
  NAVBAR_NAV_HEIGHT,
  NAVBAR_BANNER_HEIGHT,
  INTRODUCTION_ID,
  formatDateWithoutTimeZone,
  HeroTertiary,
  createAnchorId,
} from '@cbhq/cdx-components'

import { EditorialBody } from './EditorialBody'
import { EditorialHero } from './EditorialHero'
import { RelatedStories } from './RelatedStories'
import { Sidebar } from './sidebar'
import type { WayfindingCardArticleFields } from '../../components/WayfindingCardArticle'
import { WayfindingCardArticle } from '../../components/WayfindingCardArticle'
import type { CDXGlobalAuthorFields } from '../../globals/author'
import type { ComposePageEntry } from '../../globals/composePage'
import { useContentfulMicrocopy } from '../../globals/microcopy'
import type { CDXSettingsProjectFields } from '../../globals/projectSettings'
import type { CDXSettingsSiteFields } from '../../globals/siteSettings'
import type { CDXGlobalTagFields } from '../../globals/tag'
import { capitalizeRichtextParagraph } from '../../utils/capitalizeRichtextParagraph'
import { getEntryProps } from '../../utils/getEntryProps'
import type { TemplateLayoutProps } from '../PageTemplateLayout'
import { PageTemplateLayout } from '../PageTemplateLayout'
import { PageTemplateProviders } from '../PageTemplateProviders'
import type { PageTemplateProvidersProps } from '../PageTemplateProviders'

export const EDITORIAL_TEMPLATE_CONTENT_MODEL_ID = 'cdxTemplateEditorialPage'

export type EditorialTemplateFields = {
  headline?: string
  excerpt?: string
  subheadRichText?: Document
  image?: Asset
  imageFit?: 'cover' | 'contain'
  heroType?: 'tertiary' | 'editorial'
  readTime?: string
  articleBody: Document
  tags?: Entry<CDXGlobalTagFields>[]
  authors?: Entry<CDXGlobalAuthorFields>[]
  projectSettings: Entry<CDXSettingsProjectFields>
  editorialWayfinding?: Entry<WayfindingCardArticleFields>
  relatedStories?: ComposePageEntry<EditorialTemplateFields>[]
}

export type EditorialTemplateExtraProps = {
  publicationDate?: string | null
  siteConfig: Entry<CDXSettingsSiteFields>
  canonicalUrl?: string
  showSocialSharingLinks?: boolean
}

type EditorialTemplateContentProps = {
  richTextOptions?: RichTextOption
  isEmbeddedView?: boolean
}

const CDSThemeProviderWithChildren =
  CDSThemeProvider as React.FunctionComponent<
    React.PropsWithChildren<ThemeProviderProps>
  >

const EditorialTemplateContent = ({
  headline,
  subheadRichText,
  tags,
  image,
  imageFit,
  authors,
  readTime,
  articleBody,
  heroType = 'editorial',
  projectSettings,
  editorialWayfinding,
  showSocialSharingLinks = true,
  isEmbeddedView = false,

  siteConfig,
  relatedStories,
  publicationDate,

  path,
  onSearch,
  templateWidth,
  richTextOptions,
  applicationSettingsExtraProps,
}: EditorialTemplateFields &
  EditorialTemplateExtraProps &
  EditorialTemplateContentProps &
  TemplateLayoutProps) => {
  const microcopies = useContentfulMicrocopy()
  const deviceSpectrum = useDeviceSpectrum()

  const hasBanner =
    !!siteConfig?.fields.bannerText || !!projectSettings?.fields.bannerText
  const bannerHeight = hasBanner ? NAVBAR_BANNER_HEIGHT : 0

  const anchors = useMemo(() => {
    if (!articleBody) return

    capitalizeRichtextParagraph(articleBody?.content)

    let headingAnchors = articleBody.content
      .filter((heading) => heading.nodeType === 'heading-2')
      .map((heading: TopLevelBlock) => ({
        text: (heading.content[0] as Text).value,
        anchor: createAnchorId((heading.content[0] as Text).value),
      }))

    if (articleBody.content[0]?.nodeType === 'paragraph') {
      headingAnchors = [
        {
          text: microcopies.global?.introduction || 'Introduction',
          anchor: INTRODUCTION_ID,
        },
        ...headingAnchors,
      ]
    }

    return headingAnchors
  }, [articleBody, microcopies])

  const { fallbackImage, defaultEditorialWayfinding } =
    getEntryProps(projectSettings)

  const fallbackImageUrl = fallbackImage?.fields.file.url
  const fallbackImageAlt = fallbackImage?.fields.title

  if (isEmbeddedView) {
    return (
      <CDSThemeProviderWithChildren spectrum={deviceSpectrum}>
        <Div
          flexShrink={1}
          flexGrow={1}
          flexBasis={{ sm: '100%', md: '70%' }}
          flexDirection="column"
        >
          <HeroTertiary headline={headline || ''} />
          <EditorialBody
            authors={authors}
            tags={tags}
            content={articleBody}
            richTextOptions={richTextOptions}
            isEmbeddedView={isEmbeddedView}
          />
        </Div>
      </CDSThemeProviderWithChildren>
    )
  }

  return (
    <PageTemplateLayout
      path={path}
      onSearch={onSearch}
      siteConfig={siteConfig}
      templateWidth={templateWidth}
      projectConfig={projectSettings}
      applicationSettingsExtraProps={applicationSettingsExtraProps}
    >
      <Div
        flexWrap={{
          sm: 'wrap-reverse',
          md: 'nowrap',
        }}
        justifyContent="space-evenly"
        width="100%"
        height="fit-content"
      >
        <Div
          flexShrink={1}
          flexGrow={1}
          flexBasis={{ sm: '100%', md: '70%' }}
          flexDirection="column"
        >
          <Div width="100%" id={INTRODUCTION_ID}>
            {heroType === 'editorial' ? (
              <EditorialHero
                title={headline || ''}
                authors={authors}
                tags={tags}
                subheadRichText={subheadRichText}
                image={image}
                imageFit={imageFit}
                readTime={readTime}
                showSocialSharingLinks={showSocialSharingLinks}
                datePublished={formatDateWithoutTimeZone(publicationDate)}
              />
            ) : (
              <HeroTertiary headline={headline || ''} />
            )}
          </Div>
          <EditorialBody
            authors={authors}
            tags={tags}
            content={articleBody}
            richTextOptions={richTextOptions}
          />
        </Div>

        <Div
          flexShrink={1}
          flexGrow={1}
          flexBasis={{ sm: '100%', md: '30%' }}
          flexDirection="column"
          position={{ sm: 'fixed', md: 'sticky' }}
          left={0}
          height="fit-content"
          top={{
            sm: NAVBAR_NAV_HEIGHT + bannerHeight,
            md: 140,
          }}
        >
          <Sidebar
            anchors={anchors}
            showSocialSharingLinks={showSocialSharingLinks}
          />
          {relatedStories && <RelatedStories articles={relatedStories} />}
        </Div>
      </Div>

      {editorialWayfinding && <Divider spacingVertical={10} />}

      {(editorialWayfinding || defaultEditorialWayfinding) && (
        <WayfindingCardArticle
          options={{ fallbackImageUrl, fallbackImageAlt }}
          {...(editorialWayfinding
            ? editorialWayfinding.fields
            : defaultEditorialWayfinding?.fields)}
        />
      )}
    </PageTemplateLayout>
  )
}

export type EditorialTemplateProps = EditorialTemplateFields &
  EditorialTemplateExtraProps &
  EditorialTemplateContentProps &
  PageTemplateProvidersProps &
  TemplateLayoutProps

export const EditorialTemplate = (props: EditorialTemplateProps) => {
  return (
    <PageTemplateProviders
      siteConfig={props.siteConfig}
      componentMap={props.componentMap}
      cdsPalette={props.cdsPalette}
      cdsThemeMode={props.cdsThemeMode}
    >
      <EditorialTemplateContent {...props} />
    </PageTemplateProviders>
  )
}
