import { useEffect, useRef } from 'react'

type useOnScrollProps = {
  onScrollUp?: () => void
  onScrollDown?: () => void
  disabledUp?: boolean
  disabledDown?: boolean
}

export function useOnScroll({
  onScrollUp,
  onScrollDown,
  disabledUp = false,
  disabledDown = false,
}: useOnScrollProps) {
  const lastScrollTop = useRef<number>(0)
  const shouldSkipUp = useRef<boolean>(disabledUp)
  const shouldSkipDown = useRef<boolean>(disabledDown)

  useEffect(() => {
    shouldSkipUp.current = disabledUp
  }, [disabledUp])

  useEffect(() => {
    shouldSkipDown.current = disabledDown
  }, [disabledDown])

  /**
   * This implementation is using setInterval instead of event listeners (over wheel, key, and touch events) because Mobile browsers do not trigger touch events during the circumstance of
   * an ongoing scroll caused by a quick touch scroll release, which hinders the users experience.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const { top } = document.body.getBoundingClientRect()
      if (top > 0 || top === lastScrollTop.current) return

      if (
        lastScrollTop.current > top &&
        onScrollDown &&
        !shouldSkipDown.current
      )
        onScrollDown()

      if (lastScrollTop.current < top && onScrollUp && !shouldSkipUp.current)
        onScrollUp()

      lastScrollTop.current = top
    }, 50)

    return () => clearInterval(interval)
  }, [onScrollDown, onScrollUp])
}
