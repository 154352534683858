import { ImageBase } from './ImageBase'
import type { ImageBaseProps } from './ImageBase'
import type { DivProps } from '../Div'
import { Div } from '../Div'
import type { ResponsiveValue } from '../Div/types'

export type ImageFit = 'cover' | 'contain'

export type ImageProps = Omit<ImageBaseProps, 'height' | 'width' | 'src'> & {
  imageFit?: ImageFit
  aspectRatio?: ResponsiveValue<number>
  src?: string
} & Omit<DivProps, 'aspectRatio'>

export const Image = ({
  src,
  alt = '',
  priority: aboveTheFold,
  loading,

  aspectRatio,
  imageFit = 'cover',
  className,
  ...divProps
}: ImageProps) => {
  if (!src) return null

  return (
    <Div
      width="100%"
      height="100%"
      css={{
        aspectRatio,
        img: {
          width: '100%',
          minHeight: '100%', // safari fix
          height: '100%',

          objectFit: imageFit,
        },
      }}
      {...divProps}
    >
      <ImageBase
        alt={alt}
        src={src}
        priority={aboveTheFold}
        loading={loading}
        className={className}
      />
    </Div>
  )
}
