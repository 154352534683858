import { useCallback, useRef, useEffect } from 'react'

export const useDebounce = (
  fn: Function,
  ms: number = 0,
  deps: React.DependencyList = [],
): [() => boolean | null, () => void] => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reset, deps)

  return [isReady, cancel]
}

export type UseTimeoutFnReturn = [() => boolean | null, () => void, () => void]

const useTimeoutFn = (fn: Function, ms: number = 0): UseTimeoutFnReturn => {
  const ready = useRef<boolean | null>(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const callback = useRef(fn)

  const isReady = useCallback(() => ready.current, [])

  const set = useCallback(() => {
    ready.current = false
    timeout.current && clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      ready.current = true
      callback.current()
    }, ms)
  }, [ms])

  const clear = useCallback(() => {
    ready.current = null
    timeout.current && clearTimeout(timeout.current)
  }, [])

  useEffect(() => {
    callback.current = fn
  }, [fn])

  useEffect(() => {
    set()

    return clear
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms])

  return [isReady, clear, set]
}
