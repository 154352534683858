import styled from 'styled-components'

import { curves, durations } from '@cbhq/cds-common/motion/tokens'
import { spacing, palette } from '@cbhq/cds-web/tokens'

import { Div } from '../../ui/Div'

const TOGGLE_WIDTH = '18px'
const TOGGLE_HEIGHT = '17px'

export const BurgerButton = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Div
      position="relative"
      width={TOGGLE_WIDTH}
      height={TOGGLE_HEIGHT}
      css={{ cursor: 'pointer' }}
    >
      <Toggle isOpen={isOpen} closeState={!isOpen} />
    </Div>
  )
}

const Toggle = styled.div<{
  isOpen: boolean
  closeState: boolean
}>`
  top: ${spacing[1]};
  transform: ${({ closeState }) => (!closeState ? 'rotate(45deg)' : 'initial')};

  &,
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${palette.foreground};
    border-radius: ${spacing[0.5]};
    transition: ${durations.moderate3}ms transform,
      background-color cubic-bezier(${curves.global.join(',')});
  }

  &:before {
    transform: ${({ closeState }) =>
      !closeState ? 'translateY(0)' : 'translateY(-8px)'};
  }

  &:after {
    transform: ${({ closeState }) =>
      !closeState
        ? 'translateY(0) rotate(-90deg)'
        : 'translateY(8px) rotate(0)'};
  }
`
