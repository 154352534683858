import type { IconName } from '@cbhq/cds-web'
import { Icon } from '@cbhq/cds-web/icons'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { useGlobalMicrocopy } from '../../providers/MicrocopyProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { SelectInput } from '../../ui/Select'
import { Text } from '../../ui/Text'

export type SortOptions = 'asc' | 'desc'

export type HeroTertiaryProps = {
  headline: string
  icon?: IconName
  sortValue?: string
  setSortValue?: (sortValue: SortOptions) => void
  articlesTotalCount?: number
  pre?: string
  anchor?: string
  style?: ComponentsThemeStyle
}

export const HeroTertiary = ({
  headline,
  icon,
  sortValue,
  setSortValue,
  articlesTotalCount,
  pre,
  anchor,
  style = 'default',
}: HeroTertiaryProps) => {
  const { background, foreground, foregroundSecondary, borderLine } =
    componentsThemeMap[style]
  const microcopies = useGlobalMicrocopy()

  const hasArticleCountDisplay = !!articlesTotalCount && articlesTotalCount > 0
  const hasSorting = sortValue && setSortValue

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HeroTertiary">
        <SectionComponentLayout
          as="header"
          hasSpacingHorizontal
          anchor={anchor}
          background={background}
        >
          <Div
            spacingVertical={{ sm: 4, md: 5 }}
            columnGap={3}
            flexDirection={{ sm: 'column', lg: 'row' }}
            alignItems={{ sm: 'start', lg: 'center' }}
            justifyContent={{ sm: 'center', lg: 'start' }}
          >
            <Text
              as="h1"
              variant={
                hasArticleCountDisplay || hasSorting
                  ? 'title2'
                  : { sm: 'title2', md: 'display3', lg: 'display2' }
              }
              color={foreground}
              flex={1}
              spacingBottom={{ sm: 2, md: 0 }}
              css={{
                whiteSpace: 'break-spaces',
              }}
              alignItems="center"
            >
              {icon && (
                <Icon
                  name={icon}
                  size="l"
                  color={style !== 'primary' ? 'primary' : 'foreground'}
                  spacingEnd={2}
                />
              )}

              <Div display="inline-block">
                {pre && (
                  <Div
                    as="span"
                    color={foregroundSecondary}
                    css={{ whiteSpaces: 'pre' }}
                    display="inline-block"
                  >
                    {pre}{' '}
                  </Div>
                )}

                {headline}
              </Div>
            </Text>

            {(hasArticleCountDisplay || hasSorting) && (
              <Div
                gap={{ sm: 2, md: 3 }}
                justifyContent="space-between"
                alignItems={{ sm: 'start', md: 'center' }}
                flexDirection={{ sm: 'column', md: 'row' }}
              >
                {hasArticleCountDisplay && (
                  <Text variant="title4" color={foregroundSecondary}>
                    {articlesTotalCount}{' '}
                    {articlesTotalCount && articlesTotalCount > 1
                      ? microcopies.global.articles
                      : microcopies.global.article}
                  </Text>
                )}

                {hasSorting && (
                  <Div maxWidth={500}>
                    <SelectInput
                      bordered
                      onChange={(opt) => setSortValue(opt as SortOptions)}
                      initialValue={sortValue}
                      label={microcopies.global.sortBy}
                      optionValues={[
                        { value: 'asc', label: microcopies.global.newest },
                        { value: 'desc', label: microcopies.global.oldest },
                      ]}
                    />
                  </Div>
                )}
              </Div>
            )}
          </Div>

          <Div spacingBottom={7}>
            <Div as="hr" background={borderLine} height="1px" flex={1} />
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
