import styled from 'styled-components'

// eslint-disable-next-line no-restricted-imports
import { Pressable } from '@cbhq/cds-web/system/Pressable'

import { useIsExternalUrl } from './useIsExternalUrl'
import { useAnalyticsContext } from '../../providers/AnalyticsProvider'
import { useLinkComponent } from '../../providers/LinkComponentProvider'
import { useLocaleContext } from '../../providers/LocaleProvider'
import { cleanUrlDuplicateSlashes } from '../../utils/cleanUrlDuplicateSlashes'
import { getIsAnchorLink } from '../../utils/getIsAnchorLink'
import { getIsRelativeUrl } from '../../utils/getIsRelativeUrl'
import { getLocalizedUrl } from '../../utils/getLocalizedUrl'
import { scrollToAnchor } from '../../utils/scrollToAnchor'

type LinkPressableProps = {
  children: React.ReactElement
  href?: string
  rel?: string
  target?: string
  width?: string | number
  'data-qa'?: string
}

const WrappedPressable: typeof Pressable = styled(Pressable)`
  margin: 0;
  padding: 0;
  border: 0;
  display: contents;
`
export const LinkPressable = ({
  children,
  rel,
  target,
  href = '',
  width,
  'data-qa': dataQa = '',
}: LinkPressableProps) => {
  const cleanHref = cleanUrlDuplicateSlashes(href)
  const isRelativeUrl = getIsRelativeUrl(cleanHref)
  const isAnchorLink = getIsAnchorLink(cleanHref)
  const isExternalUrl = useIsExternalUrl(cleanHref)

  const LinkComponent = useLinkComponent()
  const { analyticsPrefix } = useAnalyticsContext()
  const { locale } = useLocaleContext()

  const localizedUrl = isRelativeUrl
    ? cleanHref
    : getLocalizedUrl(cleanHref, locale)

  const finalDataQa = `${analyticsPrefix}${
    dataQa && analyticsPrefix ? '-' : ''
  }${dataQa}`

  const onAnchorLinkPress = (event: React.MouseEvent) => {
    event.preventDefault()
    scrollToAnchor(cleanHref.replace('#', ''))
  }

  if (!!LinkComponent && isRelativeUrl) {
    return (
      <LinkComponent href={cleanHref} data-qa={finalDataQa}>
        <WrappedPressable
          data-qa={finalDataQa}
          background="transparent"
          noScaleOnPress
          as="a"
          color="foregroundMuted"
          rel={rel}
          target={target}
          href={cleanHref}
          width={width}
        >
          {children}
        </WrappedPressable>
      </LinkComponent>
    )
  }

  return (
    <Pressable
      data-qa={finalDataQa}
      background="transparent"
      noScaleOnPress
      as="a"
      color="foregroundMuted"
      rel={rel}
      href={localizedUrl}
      width={width}
      target={isExternalUrl ? '_blank' : target}
      onPress={isAnchorLink ? onAnchorLinkPress : undefined}
    >
      {children}
    </Pressable>
  )
}
