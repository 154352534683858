import styled, { keyframes } from 'styled-components'

const slideInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

export const SlideIn = styled.div`
  width: 100%;
  animation: ${slideInAnimation} 200ms ease-in;
`
