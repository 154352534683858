import { createContext } from 'react'

export type CMSComponentMap = {
  [modelId: string]: React.ComponentType<any>
}

export type CMSProviderProps = {
  locale: string
  onError: (message: string, error?: any, context?: string) => void
  components: CMSComponentMap
}

export const CMSContext = createContext<CMSProviderProps>({
  locale: 'en',
  onError: console.error,
  components: {},
})

export const CMSProvider = ({
  children,
  ...options
}: React.PropsWithChildren<CMSProviderProps>) => (
  <CMSContext.Provider value={options}>{children}</CMSContext.Provider>
)
