export const createAnchorId = (title: string): string => {
  let result = title.replace(/^\s+|\s+$/g, '')
  result = result.toLowerCase()

  const fromAccents =
    'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
  const toLetters =
    'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'

  // 1. Replace accents with letters
  for (let index = 0; index < fromAccents.length; index++) {
    result = result.replace(
      new RegExp(fromAccents.charAt(index), 'g'),
      toLetters.charAt(index),
    )
  }

  result = result
    // 2. Remove invalid chars
    .replace(/[-'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    // 3. Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // 4. Collapse dashes
    .replace(/-+/g, '-')

  return `anchor-${result}`
}
