import type { Document } from '@contentful/rich-text-types'

import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-common'

import type { DivProps } from '../../ui/Div'
import { Div } from '../../ui/Div'
import { RichText } from '../../ui/RichText'
import { disclaimerRichTextOptions } from '../../ui/RichText/options'

export type LegalDisclaimerProps = {
  legalDisclaimer: Document
  color?: PaletteAlias
  foregroundInlineLink?: SpectrumAlias
} & DivProps

export const LegalDisclaimer = ({
  legalDisclaimer,
  color,
  foregroundInlineLink,
  ...rest
}: LegalDisclaimerProps) => (
  <Div flexDirection="column" alignItems="center" {...rest}>
    <Div flexDirection="column">
      <RichText
        content={legalDisclaimer}
        richTextOptions={disclaimerRichTextOptions}
        color={color}
        secondaryColor={color}
        paragraphSpacingTop={2}
        foregroundInlineLink={foregroundInlineLink}
      />
    </Div>
  </Div>
)
