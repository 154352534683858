import styled from 'styled-components'

import { spacing } from '@cbhq/cds-web/tokens'

import type { RenderNodePropsTypes } from '../types'

export const Table = (): RenderNodePropsTypes =>
  function table(node, children) {
    return (
      <StyledTable>
        <tbody>{children}</tbody>
      </StyledTable>
    )
  }

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  p {
    padding: ${spacing[1]};
  }
  td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  th {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 2px solid rgba(91, 99, 110, 0.66);
  }
  tr:first-child {
    position: sticky;
    top: 0;
  }
  tr:nth-child(odd) {
    background: #eef0f3;
  }
`
