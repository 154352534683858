import { BLOCKS } from '@contentful/rich-text-types'
import type { Document } from '@contentful/rich-text-types'

import type { PaletteAlias } from '@cbhq/cds-web'

import { articleRichTextOptions } from './options'
import { RichText } from './RichText'
import type { RichTextOption } from './types'
import { splitArticlePerParagraphs } from './utils'
import { createAnchorId } from '../../utils/createAnchorId'
import { Div } from '../Div'

export const INTRODUCTION_ID = 'article_introduction'

type ArticleSectionsProps = {
  content: Document
  richTextOptions?: RichTextOption
  color?: PaletteAlias
}

export const ArticleSections = ({
  color,
  content,
  richTextOptions,
}: ArticleSectionsProps) => {
  const articleParagraphs = splitArticlePerParagraphs(content)

  return (
    <>
      {articleParagraphs?.map((paragraph, index) => {
        const firstParagraph = paragraph.content[0]

        const isHeading =
          firstParagraph.nodeType === BLOCKS.HEADING_2 ||
          firstParagraph.nodeType === BLOCKS.HEADING_3 ||
          firstParagraph.nodeType === BLOCKS.HEADING_4 ||
          firstParagraph.nodeType === BLOCKS.HEADING_5 ||
          firstParagraph.nodeType === BLOCKS.HEADING_6

        const anchorId = isHeading
          ? createAnchorId((firstParagraph.content[0] as any)?.value ?? '')
          : index === 0
          ? INTRODUCTION_ID
          : ''

        return (
          <Div
            flexDirection="column"
            key={index}
            id={anchorId}
            css={{ '.rich-text h3 + ul': { paddingTop: 16 } }}
          >
            <RichText
              color={color}
              content={paragraph}
              richTextOptions={richTextOptions || articleRichTextOptions}
              paragraphSpacingTop={{ sm: 3, lg: 4 }}
              defaultSpacingTop={{ sm: 3, lg: 5 }}
            />
          </Div>
        )
      })}
    </>
  )
}
