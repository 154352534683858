import styled from 'styled-components'

import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-common'

import type { FooterLinkGroup } from './types'
import { colorToVariable } from '../../componentsThemeMap'
import { Pressable } from '../../ui/Button'
import { Div } from '../../ui/Div'
import { Text } from '../../ui/Text'

type FooterColumnProps = {
  column: FooterLinkGroup[]
  foregroundSecondary?: PaletteAlias | SpectrumAlias
  foreground?: PaletteAlias | SpectrumAlias
}

export const FooterColumn = ({
  column,
  foregroundSecondary = 'foregroundMuted',
  foreground = 'gray80',
}: FooterColumnProps) => {
  return (
    <Div flexDirection="column">
      {column
        .filter((group) => group.links.length > 0)
        .map(({ category, links }, index) => (
          <LinkGroup key={index}>
            <Text variant="headline" as="p" color={foreground}>
              {category}
            </Text>

            <Div
              as="ul"
              spacingBottom={3}
              spacingTop={1}
              flexDirection="column"
              css={{
                listStyleType: 'none',
                paddingInline: 0,
                marginBlock: 0,
              }}
            >
              {links
                .filter(
                  (link) => !!link.label && (!!link.url || !!link.onClick),
                )
                .map(({ label, url, onClick, target }, linkIndex) => {
                  const content = (
                    <Text
                      variant="body"
                      as="span"
                      color={foregroundSecondary}
                      css={{
                        ':hover': {
                          color: colorToVariable('primary'),
                        },
                      }}
                    >
                      {label}
                    </Text>
                  )
                  if (onClick) {
                    return (
                      <Text
                        variant="body"
                        key={linkIndex}
                        as="li"
                        spacingBottom={0.5}
                      >
                        <Pressable onPress={onClick} noScaleOnPress>
                          {content}
                        </Pressable>
                      </Text>
                    )
                  }

                  return (
                    <Text
                      variant="body"
                      key={linkIndex}
                      as="li"
                      spacingBottom={0.5}
                    >
                      <Pressable
                        rel={
                          target === '_blank'
                            ? 'noopener noreferrer'
                            : undefined
                        }
                        as="a"
                        href={url}
                        target={target}
                      >
                        {content}
                      </Pressable>
                    </Text>
                  )
                })}
            </Div>
          </LinkGroup>
        ))}
    </Div>
  )
}

const LinkGroup = styled.div`
  display: inline-block;
  width: 100%;
`
