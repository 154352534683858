import { useMemo, useState, useEffect } from 'react'

import type { Entry } from 'contentful'
import Cookies from 'js-cookie'

import { CookiePreferencesModal } from '@cb/cookie-manager'
import {
  CMSContent,
  Div,
  GlobalNavbar as CDXHeader,
  CountryFooterDisclaimer,
} from '@cbhq/cdx-components'

import { ResponsiveHeader as Header } from './Header'
import { getFlashMessage, FlashMessage } from '../components/FlashMessage'
import { GlobalFooter } from '../components/GlobalFooter'
import { useContentfulMicrocopy } from '../globals/microcopy'
import type { CDXSettingsProjectFields } from '../globals/projectSettings'
import { getProjectConfigProps } from '../globals/projectSettings'
import type { CDXSettingsSiteFields } from '../globals/siteSettings'
import { getSiteConfigProps } from '../globals/siteSettings'
import { getEntryProps } from '../utils/getEntryProps'

export type TemplateLayoutProps = {
  path: string

  siteConfig?: Entry<CDXSettingsSiteFields>
  projectConfig?: Entry<CDXSettingsProjectFields>
  templateWidth?: number
  onSearch?: (term: string) => void
  applicationSettingsExtraProps?: {
    showCDXHeader?: boolean
    customLogoHeight?: number
  }
  countrySpecificFooterDisclaimer?: Entry<any>[]
}

type TemplateLayoutInnerProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const PageTemplateLayout = ({
  children,
  path,

  siteConfig,
  projectConfig,
  templateWidth = 1356,
  onSearch,
  countrySpecificFooterDisclaimer,
  applicationSettingsExtraProps,
}: TemplateLayoutProps & TemplateLayoutInnerProps) => {
  const [isCookiePreferenceModalOpen, setOpenCookiePreference] = useState(false)
  const [flashMessageComponent, setFlashMessageComponent] =
    useState<null | React.ReactNode>(null)
  const { customLogo, flashMessages } = projectConfig?.fields || {}

  useEffect(() => {
    const errorMessage = getFlashMessage(flashMessages)

    errorMessage &&
      setFlashMessageComponent(<FlashMessage message={errorMessage} />)
  }, [flashMessages])

  const { showCDXHeader, customLogoHeight } =
    applicationSettingsExtraProps || {}

  const { sharedContent, legalDisclaimer } = useMemo(
    () => getProjectConfigProps(projectConfig, path),
    [projectConfig, path],
  )

  const { footer } = useMemo(() => getSiteConfigProps(siteConfig), [siteConfig])

  const microcopies = useContentfulMicrocopy()

  const columnOneLinksWithCookieBanner = useMemo(() => {
    if (!footer) return

    const firstLinkGroup = footer.columnOne[0]

    if (!firstLinkGroup) return []

    return [
      {
        ...firstLinkGroup,
        links: [
          ...firstLinkGroup.links,
          {
            label: microcopies.global?.cookiePreferences || '',
            onClick: () => setOpenCookiePreference(true),
          },
        ],
      },
      ...footer.columnOne.slice(1),
    ]
  }, [footer, microcopies])

  const {
    productName,
    productUrl,
    subNavLinks,
    hasSearch,
    searchTags,
    secondaryCtaLabel,
    secondaryCtaUrl,
    primaryCtaUrl,
    primaryCtaLabel,
    banner: projectSettingsBanner,
    navbarStyleOverride,
    primaryLogoImageUrl,
    secondaryLogoHref,
  } = useMemo(
    () => getProjectConfigProps(projectConfig, path),
    [projectConfig, path],
  )

  const { navbar, banner: siteSettingsBanner } = useMemo(
    () => getSiteConfigProps(siteConfig),
    [siteConfig],
  )

  const ipCountryFromCookie = Cookies.get('ip_country')?.toUpperCase()

  return (
    <Div alignItems="center" flexDirection="column" width="100%">
      {showCDXHeader ? (
        <CDXHeader
          banner={projectSettingsBanner || siteSettingsBanner}
          subnav={{
            productName,
            productUrl,
            subNavLinks,
            hasSearch,
            searchTags,
            onSearch,
          }}
          {...navbar}
          style={navbarStyleOverride || navbar.style}
          secondaryCtaLabel={secondaryCtaLabel || navbar.secondaryCtaLabel}
          secondaryCtaUrl={secondaryCtaUrl || navbar.secondaryCtaUrl}
          primaryCtaLabel={primaryCtaLabel || navbar.primaryCtaLabel}
          primaryCtaUrl={primaryCtaUrl || navbar.primaryCtaUrl}
          primaryLogoImageUrl={primaryLogoImageUrl}
          secondaryLogoHref={secondaryLogoHref}
          customLogoHeight={customLogoHeight}
        />
      ) : (
        <Header
          path={path}
          onSearch={onSearch}
          siteConfig={siteConfig}
          projectConfig={projectConfig}
        />
      )}
      {flashMessageComponent && (
        <Div spacingTop={2.5} justifyContent="center">
          <Div
            width="90%"
            spacing={2.5}
            maxWidth={700}
            background="negative"
            borderRadius="rounded"
          >
            {flashMessageComponent}
          </Div>
        </Div>
      )}
      <Div
        alignItems="center"
        flexDirection="column"
        background="background"
        width="100%"
        overflowX="clip"
      >
        <Div flexDirection="column" maxWidth={templateWidth} width="100%">
          {children}

          <CMSContent
            content={sharedContent ?? []}
            additionalProps={{
              productId: projectConfig?.fields?.productId,
            }}
          />

          {countrySpecificFooterDisclaimer &&
            countrySpecificFooterDisclaimer.length &&
            countrySpecificFooterDisclaimer.map((entry) => {
              const { countryCode, disclaimer } = getEntryProps(entry)
              const uppercaseCountryCodes = countryCode.map((code: string) =>
                code.toUpperCase(),
              )
              if (uppercaseCountryCodes.includes(ipCountryFromCookie)) {
                return (
                  <CountryFooterDisclaimer
                    key={entry.sys.id}
                    disclaimer={disclaimer}
                  />
                )
              }
              return null
            })}

          {footer && (
            <GlobalFooter
              legalDisclaimer={legalDisclaimer}
              socialMediaLinks={footer.socialMediaLinks}
              columnOne={columnOneLinksWithCookieBanner}
              columnTwo={footer.columnTwo}
              columnThree={footer.columnThree}
              customLogo={customLogo}
              customLogoHeight={customLogoHeight}
              copyrightText={microcopies.global.copyright}
              renderCdxFooter={showCDXHeader}
            />
          )}
        </Div>
      </Div>

      <CookiePreferencesModal
        isOpen={isCookiePreferenceModalOpen}
        onClose={() => setOpenCookiePreference(false)}
      />
    </Div>
  )
}
