import type { QuickAnchor } from '@cbhq/cdx-components'
import { Only } from '@cbhq/cdx-components'

import { DesktopSidebar } from './DesktopSidebar'
import { MobileSidebar } from './MobileSidebar'

export type SidebarProps = {
  anchors?: QuickAnchor[]
  showSocialSharingLinks?: boolean
}

export const Sidebar = ({
  anchors = [],
  showSocialSharingLinks,
}: SidebarProps) => {
  return (
    <>
      <Only on="phone">
        <MobileSidebar
          anchors={anchors}
          showSocialSharingLinks={showSocialSharingLinks}
        />
      </Only>

      <Only on={['tablet', 'desktop']}>
        <DesktopSidebar
          anchors={anchors}
          showSocialSharingLinks={showSocialSharingLinks}
        />
      </Only>
    </>
  )
}
