import styled from 'styled-components'

import { Icon } from '@cbhq/cds-web/icons/Icon'
import { palette } from '@cbhq/cds-web/tokens'

import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { LinkPressable } from '../../ui/Link'
import { Text } from '../../ui/Text'

const ICON_SIZE = '40'
const ITEM_MIN_WIDTH = 145

type TrapdoorProductListColumnProps = {
  iconUrl?: string
  header?: string
  subhead?: string
  url?: string
  target?: '_blank' | '_self' | undefined
}

export const TrapdoorProductListColumn = ({
  iconUrl,
  header,
  subhead,
  url,
  target,
}: TrapdoorProductListColumnProps) => (
  <LinkPressable
    href={url || ''}
    data-qa={ANALYTICS_KEY.TrapDoorProductListColumn}
    target={target}
    rel={target === '_blank' ? 'noopener noreferrer' : undefined}
  >
    <ItemWrapper key={header} title={header} tabIndex={0}>
      {iconUrl ? (
        <StyledIcon
          src={iconUrl}
          height={ICON_SIZE}
          width={ICON_SIZE}
          alt={header}
        />
      ) : (
        <Icon name="caretRight" color="foreground" size="xs" spacingEnd={2} />
      )}
      <Div flexDirection="column" justifyContent="center" maxWidth={316}>
        <Text variant="headline" as="h4" color="foreground">
          {header}
        </Text>
        <Text variant="body" as="p" color="foregroundMuted">
          {subhead}
        </Text>
      </Div>
    </ItemWrapper>
  </LinkPressable>
)

const StyledIcon = styled.img`
  margin-right: 12px;
  filter: drop-shadow(0px 2px 2px rgba(91, 97, 110, 0.14))
    drop-shadow(0px 3px 1px rgba(91, 97, 110, 0.12))
    drop-shadow(0px 1px 5px rgba(91, 97, 110, 0.2));
`
export const ItemWrapper = styled.div`
  display: inline-flex;
  color: inherit;
  align-items: center;
  min-width: ${ITEM_MIN_WIDTH}px;
  cursor: pointer;
  &:hover {
    h4 {
      color: ${palette.primary};
    }
    p {
      color: ${palette.foreground};
    }
    div {
      color: ${palette.primary};
    }
  }
`
