const coinbaseLoginUrl = 'https://www.coinbase.com/login'
const primeLoginUrl = 'https://prime.coinbase.com/login'

export const modifyLoginUrlPerEnvironment = (url: string = ''): string => {
  if (
    !url ||
    process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' ||
    typeof window === 'undefined'
  )
    return url

  const isStaging =
    window.location.hostname.includes('-stage.cbhq.net') ||
    window.location.hostname.includes('-staging.cbhq.net')

  if (url.startsWith(coinbaseLoginUrl)) {
    if (isStaging)
      return url.replace(
        coinbaseLoginUrl,
        'https://coinbase-staging.cbhq.net/login',
      )
    else
      return url.replace(
        coinbaseLoginUrl,
        'https://coinbase-dev.cbhq.net/login',
      )
  }

  if (url.startsWith(primeLoginUrl)) {
    if (isStaging)
      return url.replace(primeLoginUrl, 'https://prime-staging.cbhq.net/login')
    else return url.replace(primeLoginUrl, 'https://prime-dev.cbhq.net/login')
  }

  return url
}
