import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { Link } from '../../ui/Link'
import { Text } from '../../ui/Text'

type TrapdoorDescriptionColumnProps = {
  url?: string
  urlLabel?: string
  header?: string
  subhead?: string
  labelCta?: string
}

export const TrapdoorDescriptionColumn = ({
  header,
  subhead,
  labelCta,
  url,
}: TrapdoorDescriptionColumnProps) => (
  <Div flexDirection="column" maxWidth={320} flexGrow={1}>
    <Text variant="headline" as="h4">
      {header}
    </Text>
    <Text variant="body" as="p" spacingTop={1}>
      {subhead}
    </Text>
    {labelCta && url && (
      <Div spacingTop={3}>
        <Link
          href={url}
          title={labelCta}
          color="primary"
          variant="headline"
          data-qa={ANALYTICS_KEY.TrapDoorLabel}
        >
          {labelCta}
        </Link>
      </Div>
    )}
  </Div>
)
