import { useEffect, useRef, useState, useCallback } from 'react'

import { InputIcon } from '@cbhq/cds-web/controls/InputIcon'

import { SelectOptions } from './SelectOptions'
import type { Option } from './SelectOptions'
import { ANALYTICS_KEY } from '../../constants'
import { useClickOutside } from '../../hooks/useClickOutside'
import { Div } from '../../ui/Div'
import { Pressable } from '../Button'
import { Text } from '../Text'

export type SelectInputRef = {
  changeHandler: (option: Option) => void
  setSelectValue: (option: Option) => void
}

export type SelectProps = {
  optionValues: Array<Option>
  initialValue?: string
  label?: string
  onChange?: (value: string) => void
  isError?: boolean
  errorMessage?: string
  selectRef?: React.MutableRefObject<SelectInputRef | undefined>
  bordered?: boolean
  children?: React.ReactNode
}

export const SelectInput = ({
  optionValues,
  initialValue,
  label,
  onChange,
  isError,
  errorMessage,
  selectRef,
  bordered,
  children,
}: SelectProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectValue, setSelectValue] = useState<Option | undefined>(
    optionValues?.find((opt) => opt.value === initialValue),
  )

  const changeHandler = useCallback(
    (option: Option) => {
      setSelectValue(option)
      setDropdownOpen(false)

      if (onChange && option.value) onChange(option.value)
    },
    [onChange],
  )

  const openDropDown = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutside(() => setDropdownOpen(false), containerRef, isDropdownOpen)

  useEffect(() => {
    if (selectRef) {
      selectRef.current = {
        changeHandler,
        setSelectValue,
      }
    }
  }, [changeHandler, selectRef])

  return (
    <Div
      flexDirection="column"
      position="relative"
      width="100%"
      minWidth={240}
      ref={containerRef}
      data-qa={ANALYTICS_KEY.Select}
    >
      <Div
        alignItems="center"
        gap={0.5}
        flexGrow={1}
        css={{ '> button': { overflow: 'hidden' } }}
      >
        <Pressable
          as="button"
          background="background"
          onPress={openDropDown}
          borderRadius="rounded"
          borderColor="transparent"
          noScaleOnPress
          width="100%"
        >
          <Div
            gap={0.5}
            alignItems="center"
            bordered={bordered}
            height={bordered ? '40px' : '100%'}
            borderColor={
              isError ? 'negative' : isDropdownOpen ? 'primary' : 'lineHeavy'
            }
            borderRadius="rounded"
            spacingStart={2}
            spacingVertical={2}
            overflow="hidden"
            flexGrow={1}
          >
            <Div flexShrink={0}>
              <Text variant="headline" as="p" color="foregroundMuted">
                {label}
              </Text>
            </Div>

            <Div
              flexGrow={1}
              flexShrink={1}
              overflow="hidden"
              css={{ textAlign: 'left' }}
            >
              <Text
                variant="headline"
                spacingVertical={0.5}
                as="p"
                color="foreground"
                overflow="truncate"
              >
                {selectValue?.label}
              </Text>
            </Div>

            <InputIcon
              name={isDropdownOpen ? 'caretUp' : 'caretDown'}
              color={isDropdownOpen ? 'primary' : 'foreground'}
            />
          </Div>
        </Pressable>

        {children && (
          <Div spacingEnd={1} onClick={(e) => e.preventDefault()}>
            {children}
          </Div>
        )}
      </Div>

      {isDropdownOpen && (
        <SelectOptions
          top={containerRef.current?.offsetHeight}
          changeHandler={changeHandler}
          optionValues={optionValues}
          selectedOption={selectValue}
        />
      )}

      {isError && (
        <Text variant="label2" as="p" color="negative">
          {errorMessage}
        </Text>
      )}
    </Div>
  )
}
