import styled from 'styled-components'

import type { PaletteAlias } from '@cbhq/cds-common'
import { palette, spacing } from '@cbhq/cds-web/tokens'

import { Trapdoor } from './Trapdoor'
import type { NavLinkProps } from './types'
import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { Link } from '../../ui/Link'

type InnerProps = {
  activeTab?: string
  setActiveTab: (next: string) => void
  hasBanner?: boolean
  columnCount?: number
  color?: PaletteAlias
}

export const Navlink = ({
  url,
  label = '',
  trapdoorLinks = [],
  productLink,

  activeTab,
  setActiveTab,
  columnCount,
  hasBanner,
  color = 'foreground',
}: NavLinkProps & InnerProps) => {
  const isDuplicateUrlPath =
    typeof window !== 'undefined' && window.location.pathname === url

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      label && setActiveTab(label)
    }

    if (event.key === 'Escape') {
      setActiveTab('')
    }
  }

  if (url && isDuplicateUrlPath) {
    return (
      <Div css={{ button: { height: '100%' } }}>
        <Link
          variant="label1"
          href={url}
          onKeyDown={handleKeyPress}
          data-qa={ANALYTICS_KEY.NavLinkButton}
        >
          <StyledLink
            $hasUrl={!!url}
            $isActive={activeTab === label}
            position="relative"
            alignItems="center"
            height="100%"
            color={color}
            onMouseEnter={() => {
              label && setActiveTab(label)
            }}
          >
            {label}
          </StyledLink>
        </Link>
      </Div>
    )
  }

  if (url) {
    return (
      <Div css={{ button: { height: '100%' } }}>
        <Link
          href={url}
          variant="label1"
          onKeyDown={handleKeyPress}
          data-qa={ANALYTICS_KEY.NavLinkButton}
        >
          <StyledLink
            $hasUrl={!!url}
            $isActive={activeTab === label}
            position="relative"
            alignItems="center"
            height="100%"
            color={color}
            onMouseEnter={() => {
              label && setActiveTab(label)
            }}
          >
            {label}
          </StyledLink>
        </Link>
      </Div>
    )
  }

  return (
    <Link
      variant="label1"
      color="foreground"
      onKeyDown={handleKeyPress}
      data-qa={ANALYTICS_KEY.NavLinkButton}
    >
      <StyledLink
        $hasUrl={!!url}
        $isActive={activeTab === label}
        position="relative"
        alignItems="center"
        height="100%"
        color={color}
        onMouseEnter={() => {
          label && setActiveTab(label)
        }}
      >
        {label}
      </StyledLink>
      {trapdoorLinks.length > 0 && (
        <Trapdoor
          isActive={activeTab === label}
          links={trapdoorLinks}
          hasBanner={hasBanner}
          columnCount={columnCount}
          productLink={productLink}
        />
      )}
    </Link>
  )
}

const StyledLink = styled(Div)<{
  $isActive: boolean
  $hasUrl?: boolean
}>`
  width: 100%;
  text-align: center;
  margin: auto;
  height: 100%;
  padding: 0 ${spacing[2]};
  cursor: ${({ $hasUrl }) => ($hasUrl ? 'cursor' : 'initial')};
  ${({ $isActive }) => {
    return (
      $isActive &&
      `
        &:before {
          position: absolute;
          padding: 0 ${spacing[2]};
          top: calc(100% - 3px);
          width: calc(100% - 32px);
          height: 4px;
          background: ${palette.primary};
          display: inline-block;
          content: '';
          cursor: inherit;
        }
      `
    )
  }};
`
