import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { Link } from '../../ui/Link'
import { Text } from '../../ui/Text'

export type BannerProps = {
  label?: string
  url?: string
}

export const Banner = ({ label, url }: BannerProps) => {
  if (!label) return null

  return (
    <Div
      as="nav"
      width="100%"
      alignItems="center"
      justifyContent="center"
      background="primary"
      spacingVertical={0.5}
      spacingHorizontal={3}
      zIndex={20}
    >
      <Link href={url || ''} data-qa={ANALYTICS_KEY.Banner}>
        <Text variant="body" color="background" css={{ textAlign: 'center' }}>
          {label}
        </Text>
      </Link>
    </Div>
  )
}
