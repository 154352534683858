import type { Entry } from 'contentful'

import type { MicrocopyGlobalValues } from '@cbhq/cdx-components'
import { useMicrocopyContext } from '@cbhq/cdx-components'

/**
 * Keys should always be defined as 'context.key'
 */
export type CDXSettingsMicrocopyFields = {
  key: `${MicrocopyContextKeys}.${
    | MicrocopyExtraGlobalKeys
    | MicrocopyGlobalValues
    | MicrocopyBlogKeys}`
  value: string
}

export type CDXSettingsMicrocopyGroupFields = {
  microcopies: Entry<CDXSettingsMicrocopyFields>[]
}

export type MicrocopyContextKeys = 'global' | 'blog' | 'cloud'
export type MicrocopyExtraGlobalKeys =
  | 'about'
  | 'all'
  | 'and'
  | 'asset'
  | 'copyright'
  | 'commissionFee'
  | 'cookiePreferences'
  | 'defiYield'
  | 'goBackToCoinbase'
  | 'mostRecent'
  | 'introduction'
  | 'mechanism'
  | 'noResults'
  | 'podcasts'
  | 'publicValidator'
  | 'resultsFor'
  | 'runYourOwn'
  | 'rewardRate'
  | 'standard'
  | 'subscribe'
  | 'seeAll'
  | 'share'
  | 'showMore'
  | 'staking'
  | 'yield'

export type MicrocopyBlogKeys = 'recentStories' | 'relatedStories'
export type MicrocopyCloudKeys =
  | 'firstNameLabel'
  | 'firstNamePlaceholder'
  | 'lastNameLabel'
  | 'lastNamePlaceholder'
  | 'emailLabel'
  | 'emailPlaceholder'
  | 'companyLabel'
  | 'companyPlaceholder'
  | 'locationLabel'
  | 'locationPlaceholder'
  | 'websiteLabel'
  | 'websitePlaceholder'
  | 'companySizeLabel'
  | 'companySizePlaceholder'
  | 'companySizeValue'
  | 'phoneLabel'
  | 'phonePlaceholder'
  | 'productOfInterestLabel'
  | 'productOfInterestPlaceholder'
  | 'productOptionValue'
  | 'descriptionLabel'
  | 'descriptionPlaceholder'

export type ContentfulMicrocopyValues = Record<
  'blog',
  Record<MicrocopyBlogKeys, string>
> &
  Record<'cloud', Record<MicrocopyCloudKeys, string>> &
  Record<
    'global',
    Record<MicrocopyGlobalValues | MicrocopyExtraGlobalKeys, string>
  >

export const mapMicrocopyProps = (
  microcopiesEntry: Entry<CDXSettingsMicrocopyGroupFields> | undefined,
): ContentfulMicrocopyValues | undefined => {
  const groups = microcopiesEntry?.fields?.microcopies

  if (!groups) return undefined

  const nextGroups = groups.reduce<
    Record<MicrocopyContextKeys, Record<string, string> | undefined>
  >(
    (acc, cur) => {
      const { key, value } = cur.fields || {}

      const [group, subkey] = key.split('.')

      return {
        ...acc,
        [group]: {
          ...(acc[group as MicrocopyContextKeys] || {}),
          [subkey]: value,
        },
      }
    },
    {
      blog: undefined,
      cloud: undefined,
      global: undefined,
    },
  )

  return nextGroups as ContentfulMicrocopyValues
}

export const useContentfulMicrocopy = () =>
  useMicrocopyContext<ContentfulMicrocopyValues>()
