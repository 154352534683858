import { BLOCKS } from '@contentful/rich-text-types'

import type { RichTextOption, RichTextPropsType } from '@cbhq/cdx-components'
import { Paragraph, composeRichTextComponents } from '@cbhq/cdx-components'

export const authorBioFormatOptions: RichTextOption = composeRichTextComponents(
  {
    customRenderOptions: {
      [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
        Paragraph({
          ...props,
          color: props?.secondaryColor,
        }),
    },
  },
)
