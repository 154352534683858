// eslint-disable-next-line no-restricted-imports
import { Pressable } from '@cbhq/cds-web/system'

import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { LinkPressable } from '../../ui/Link'
import { Text } from '../Text'

type TagsProps = {
  tags?: PillTag[]
}

export const PillTags = ({ tags }: TagsProps) => (
  <Div flexWrap="wrap">
    {tags?.map(({ label, url, onClick }, index) => (
      <Div spacingEnd={1} spacingBottom={1} key={index}>
        <AnalyticsProvider analyticsPrefix={`${ANALYTICS_KEY.PillTag}${index}`}>
          {onClick ? (
            <Pressable
              background="transparent"
              onPress={() => onClick({ label })}
            >
              <PillTag label={label} />
            </Pressable>
          ) : url ? (
            <LinkPressable href={url}>
              <PillTag label={label} />
            </LinkPressable>
          ) : (
            <PillTag label={label} />
          )}
        </AnalyticsProvider>
      </Div>
    ))}
  </Div>
)

export type PillTag = {
  label: string
  url?: string
  onClick?: (tag: { label: string }) => void
}

const PillTag = ({ label }: PillTag) => (
  <Div background="primaryWash" borderRadius="roundedLarge">
    <Text
      color="foreground"
      variant="label1"
      spacingVertical={1}
      spacingHorizontal={4}
      as="p"
    >
      {label}
    </Text>
  </Div>
)
