import { ShareLinkSvg } from '../../../ui/ShareLinks/ShareLinkSvg'

export const Facebook = ({ fill = 'white', ...rest }) => {
  return (
    <ShareLinkSvg {...rest}>
      <path
        d="M 11.242 5.6932 H 8.6265 V 4.099 C 8.6265 3.5003 9.0533 3.3607 9.3542 3.3607 C 9.6542 3.3607 11.1999 3.3607 11.1999 3.3607 V 0.7288 L 8.6579 0.7195 C 5.836 0.7195 5.1939 2.6825 5.1939 3.9388 V 5.6932 H 3.562 V 8.4053 H 5.1939 C 5.1939 11.8858 5.1939 16.0795 5.1939 16.0795 H 8.6265 C 8.6265 16.0795 8.6265 11.8445 8.6265 8.4053 H 10.9426 L 11.242 5.6932 Z"
        fill={fill}
      />
    </ShareLinkSvg>
  )
}
