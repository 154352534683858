import { useMemo } from 'react'

import type { Entry, Asset } from 'contentful'

import {
  formatDateWithoutTimeZone,
  Wayfinding,
  WayfindingCardArticle as CardArticle,
} from '@cbhq/cdx-components'
import type {
  ComponentsThemeStyle,
  CardGridColumnsOpt,
  CardGridRowsOpt,
} from '@cbhq/cdx-components'

import type { ComposePageEntry } from '../../globals/composePage'
import { getComposeEntryProps } from '../../globals/composePage'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import type { PageTemplateFields } from '../../globals/pageTemplateTypes'
import type { CDXGlobalTagFields } from '../../globals/tag'
import type { EditorialTemplateFields } from '../../templates/Editorial'
import { getEntryProps } from '../../utils/getEntryProps'
import { getEntryTypeId } from '../../utils/getEntryTypeId'

export type WayfindingCardArticleFields = {
  headline?: string
  body?: string
  link?: Entry<CDXGlobalNavLinkFields>
  hasSpacingTop?: boolean
  columns?: CardGridColumnsOpt
  rows?: CardGridRowsOpt

  cardGeneration?: 'automatic' | 'manual'
  /** Cards of type ComposePage are provided from the Generation result, and should be injected  */
  cards?: Entry<CardArticleFields>[] | ComposePageEntry<PageTemplateFields>[]
  tags?: Entry<CDXGlobalTagFields>[]
  hideDescriptions?: boolean
  descriptionsMaxLines?: '1' | '2' | '3' | 'all'

  style?: ComponentsThemeStyle
  anchor?: string
}

type InnerProps = {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  aspectRatio?: 'long' | 'square'
  hasSpacingHorizontal?: boolean
  options?: {
    fallbackImageAlt?: string
    fallbackImageUrl?: string
  }
}

export type CardArticleFields = {
  headline?: string
  body?: string
  url?: string
  thumbnail?: Asset
  style?: ComponentsThemeStyle
  link?: Entry<CDXGlobalNavLinkFields>
  releaseDate?: string
}

export const WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID =
  'cdxWayfindingArticleCardGrid'

export const COMPONENT_CARD_ARTICLE_CONTENT_MODEL_ID = 'cdxComponentCardArticle'

export const WayfindingCardArticle = ({
  headline,
  body,
  link,
  hasSpacingTop = true,
  columns = 3,
  rows = 2,

  cards = [],
  hideDescriptions,
  descriptionsMaxLines = 'all',

  style,
  anchor,

  aspectRatio,
  hasSpacingHorizontal = true,
  as,
  options,
}: WayfindingCardArticleFields & InnerProps) => {
  const linkProps = link ? mapEntryLink(link) : undefined

  const articleCards = useMemo(() => {
    return cards.map((entry, index) => {
      const isCard =
        getEntryTypeId(entry as Entry<CardArticleFields>) ===
        COMPONENT_CARD_ARTICLE_CONTENT_MODEL_ID

      if (isCard) {
        const { headline, body, thumbnail, link, url } = getEntryProps(
          entry as Entry<CardArticleFields>,
        )
        const navLink = link && mapEntryLink(link).url

        return (
          <CardArticle
            key={index}
            headline={headline || ''}
            maxLines={
              descriptionsMaxLines !== 'all'
                ? Number(descriptionsMaxLines)
                : undefined
            }
            body={hideDescriptions ? undefined : body || ''}
            mediaUrl={thumbnail?.fields.file?.url}
            mediaAlt={thumbnail?.fields?.title}
            href={navLink || url}
            style={style}
          />
        )
      } else {
        const {
          headline,
          excerpt,
          composeSlug,
          image,
          readTime,
          tags,
          publicationDate,
        } = getComposeEntryProps(
          entry as ComposePageEntry<EditorialTemplateFields>,
        )

        const firstTag = tags?.find((tag) => tag.fields.type !== 'internal')
        const { label, link } = getEntryProps(firstTag)
        const tagLink = mapEntryLink(link)

        return (
          <CardArticle
            key={index}
            headline={headline || ''}
            maxLines={2}
            body={hideDescriptions ? '' : excerpt}
            href={composeSlug}
            mediaUrl={image?.fields.file.url || options?.fallbackImageUrl}
            mediaAlt={image?.fields.title || options?.fallbackImageAlt}
            datePublished={formatDateWithoutTimeZone(publicationDate, {
              month: 'short',
              year: 'numeric',
              day: 'numeric',
            })}
            readTime={readTime}
            tagLabel={label}
            tagHref={tagLink.url && `/${tagLink.url}`}
          />
        )
      }
    })
  }, [cards, hideDescriptions, descriptionsMaxLines, style, options])

  if (!articleCards || articleCards.length < 1) return null

  return (
    <Wayfinding
      headline={headline}
      body={body}
      columns={columns}
      rows={rows}
      aspectRatio={aspectRatio}
      style={style}
      hasSpacingTop={hasSpacingTop}
      hasSpacingHorizontal={hasSpacingHorizontal}
      as={as}
      anchor={anchor}
      ctaButtonLabel={linkProps?.label}
      ctaButtonUrl={linkProps?.url && `/${linkProps?.url}`}
      {...options}
    >
      {articleCards}
    </Wayfinding>
  )
}
