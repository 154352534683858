import {
  EditorialArticleNavigationList,
  Div,
  ShareLinks,
  Text,
  useCanonicalUrl,
} from '@cbhq/cdx-components'

import type { SidebarProps } from './Sidebar'
import { useContentfulMicrocopy } from '../../../globals/microcopy'

export const DesktopSidebar = ({
  anchors = [],
  showSocialSharingLinks,
}: SidebarProps) => {
  const canonicalUrl = useCanonicalUrl()
  const microcopies = useContentfulMicrocopy()

  return (
    <Div flexDirection="column" spacingTop={8} spacingBottom={2} as="aside">
      <Div flexDirection="column" borderColor="line">
        {showSocialSharingLinks && (
          <Div flexDirection="column" spacingStart={4} spacingBottom={4}>
            <Text
              variant="legal"
              as="p"
              color="foregroundMuted"
              spacingBottom={1}
            >
              {microcopies.global?.share}
            </Text>

            {canonicalUrl && <ShareLinks canonicalUrl={canonicalUrl} />}
          </Div>
        )}

        {anchors.length > 1 && (
          <EditorialArticleNavigationList quickScrollContent={anchors} />
        )}
      </Div>
    </Div>
  )
}
