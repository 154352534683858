import { useState } from 'react'

import type { Entry } from 'contentful'

import type { SortOptions } from '@cbhq/cdx-components'
import { Spinner, Button, Div, HeroTertiary } from '@cbhq/cdx-components'

import { ListingWayfinding } from './ListingWayfinding'
import { NoResults } from './NoResults'
import type { ComposePageEntry } from '../../globals/composePage'
import { useContentfulMicrocopy } from '../../globals/microcopy'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import type { CDXSettingsProjectFields } from '../../globals/projectSettings'
import type { CDXSettingsSiteFields } from '../../globals/siteSettings'
import type { CDXGlobalTagFields } from '../../globals/tag'
import { getEntryProps } from '../../utils/getEntryProps'
import type { EditorialTemplateFields } from '../Editorial'
import type { TemplateLayoutProps } from '../PageTemplateLayout'
import { PageTemplateLayout } from '../PageTemplateLayout'
import type { PageTemplateProvidersProps } from '../PageTemplateProviders'
import { PageTemplateProviders } from '../PageTemplateProviders'

export const LISTING_TEMPLATE_CONTENT_MODEL_ID = 'cdxTemplateListingPage'

export type ListingTemplateFields = {
  listingType: 'search' | 'listing'
  title?: string
  tags?: Entry<CDXGlobalTagFields>[]
  projectSettings: Entry<CDXSettingsProjectFields>
  noSearchResultsLink?: Entry<CDXGlobalNavLinkFields>
  noSearchResultsText?: string
}

export type ListingTemplateExtraProps = {
  siteConfig: Entry<CDXSettingsSiteFields>

  articles: ComposePageEntry<EditorialTemplateFields>[]
  isLoading?: boolean
  canLoadMore?: boolean
  onLoadMore?: () => void
  onSort?: (direction: SortOptions) => void
  totalCount?: number
  searchTerm?: string
  canonicalUrl?: string
}

/**
 * This template expects that you provide a loading and sorting method depending on the listing method. Not providing, will only render as a static wayfinding listing the articles provided.
 */
const ListingTemplateContent = ({
  title,
  projectSettings,
  listingType,
  noSearchResultsText,
  noSearchResultsLink,

  siteConfig,
  articles,
  isLoading,
  canLoadMore,
  onLoadMore,
  onSort,
  totalCount,
  searchTerm,

  path,
  onSearch,
  templateWidth,
  applicationSettingsExtraProps,
}: ListingTemplateFields & ListingTemplateExtraProps & TemplateLayoutProps) => {
  const microcopies = useContentfulMicrocopy()

  const { fallbackImage } = getEntryProps(projectSettings)
  const fallbackImageUrl = fallbackImage?.fields.file.url
  const fallbackImageAlt = fallbackImage?.fields.title

  const [sortValue, setSortValue] = useState<SortOptions>('asc')

  const handleSort = (nextSort: SortOptions) => {
    setSortValue(nextSort)

    if (onSort) onSort(nextSort)
  }

  const isSearch = listingType === 'search'

  return (
    <PageTemplateLayout
      siteConfig={siteConfig}
      projectConfig={projectSettings}
      path={path}
      templateWidth={templateWidth}
      onSearch={onSearch}
      applicationSettingsExtraProps={applicationSettingsExtraProps}
    >
      <HeroTertiary
        pre={
          isSearch && searchTerm ? microcopies.global?.resultsFor : undefined
        }
        headline={isSearch && searchTerm ? searchTerm : title || ''}
        sortValue={sortValue}
        setSortValue={onSort ? handleSort : undefined}
        articlesTotalCount={totalCount}
      />

      {isLoading && (
        <Div position="fixed" zIndex={1} bottom={16} right={16}>
          <Spinner size={2} color="primary" />
        </Div>
      )}

      <Div flexDirection="column">
        <ListingWayfinding
          articles={articles}
          fallbackImageUrl={fallbackImageUrl}
          fallbackImageAlt={fallbackImageAlt}
        />

        {isSearch && articles?.length < 1 && (
          <>
            {isLoading ? (
              <Div
                flex={1}
                spacingHorizontal={5}
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size={4} color="primary" />
              </Div>
            ) : (
              <NoResults
                onSearch={onSearch}
                searchTerms={projectSettings?.fields.suggestedSearchTerms}
                noSearchResultsText={noSearchResultsText}
                goBackLink={noSearchResultsLink}
              />
            )}
          </>
        )}

        {canLoadMore && onLoadMore && (
          <>
            <Div justifyContent="center" flex={1} spacingTop={5}>
              <Button onPress={() => onLoadMore()} disabled={isLoading}>
                {microcopies.global?.showMore}
              </Button>
            </Div>
          </>
        )}
      </Div>
    </PageTemplateLayout>
  )
}

export const ListingTemplate = (
  props: ListingTemplateFields &
    ListingTemplateExtraProps &
    PageTemplateProvidersProps &
    TemplateLayoutProps,
) => {
  return (
    <PageTemplateProviders
      siteConfig={props.siteConfig}
      componentMap={props.componentMap}
      cdsPalette={props.cdsPalette}
      cdsThemeMode={props.cdsThemeMode}
    >
      <ListingTemplateContent {...props} />
    </PageTemplateProviders>
  )
}
