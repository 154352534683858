import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { ANALYTICS_KEY } from '../../constants'
import type { RichTextOption, RichTextPropsType } from '../../ui/RichText'
import { composeRichTextComponents } from '../../ui/RichText'
import {
  Heading,
  InlineHyperLink,
  ListItem,
  Paragraph,
} from '../../ui/RichText/components'

export const editorialHeroFormatOptions: RichTextOption =
  composeRichTextComponents({
    customRenderOptions: {
      [BLOCKS.HEADING_3]: (props?: RichTextPropsType) =>
        Heading({
          as: 'h3',
          variant: { sm: 'body', lg: 'title2' },
          ...props,
        }),
      [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
        Paragraph({
          display: 'inline',
          variant: { sm: 'body', lg: 'title4' },
          ...props,
        }),
      [BLOCKS.LIST_ITEM]: (props?: RichTextPropsType) =>
        ListItem({
          css: { p: { paddingTop: 0 } },
          ...props,
        }),
      [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
        InlineHyperLink({
          dataQa: ANALYTICS_KEY.EditorialRichText,
          ...props,
        }),
    },
  })
