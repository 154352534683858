import type { Entry } from 'contentful'

import {
  PillTags,
  Div,
  Text,
  SectionComponentLayout,
  LinkButton,
} from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'

export type NoResultsProps = {
  searchTerms?: string[]
  onSearch?: (term: string) => void
  noSearchResultsText?: string
  goBackLink?: Entry<CDXGlobalNavLinkFields>
}

export const NoResults = ({
  searchTerms = [],
  onSearch,
  noSearchResultsText,
  goBackLink,
}: NoResultsProps) => {
  const tags = searchTerms.map((term) => ({
    label: term,
    onClick: onSearch ? () => onSearch(term) : undefined,
  }))

  const linkProps = goBackLink ? mapEntryLink(goBackLink) : undefined

  return (
    <SectionComponentLayout hasSpacingHorizontal>
      <Text variant="title4" color="foregroundMuted" as="p">
        <Div as="span" maxWidth="900px">
          {noSearchResultsText}
        </Div>
      </Text>

      {tags && (
        <Div spacingTop={4} spacingBottom={7} maxWidth="600px">
          <PillTags tags={tags} />
        </Div>
      )}

      {linkProps && (
        <Div justifyContent="center">
          <LinkButton href={linkProps.url}>{linkProps.label}</LinkButton>
        </Div>
      )}
    </SectionComponentLayout>
  )
}
