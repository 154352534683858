import { Fragment } from 'react'

import type { Document } from '@contentful/rich-text-types'
import type { Asset } from 'contentful'

import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-common'

import { FooterColumn } from './FooterColumn'
import type { FooterLinkGroup, FooterLink } from './types'
import { colorToVariable } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CoinbaseLogo } from '../../ui/CoinbaseLogo'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Image } from '../../ui/Image'
import { LegalDisclaimer } from '../../ui/LegalDisclaimer'
import { Link } from '../../ui/Link'
import { Text } from '../../ui/Text'
import { NAVBAR_LOGO_HEIGHT, NAVBAR_LOGO_WIDTH } from '../GlobalNavbar'

export type GlobalFooterProps = {
  socialMediaLinks?: FooterLink[]
  legalDisclaimer?: Document
  columnOne?: FooterLinkGroup[]
  columnTwo?: FooterLinkGroup[]
  columnThree?: FooterLinkGroup[]
  style?: 'default' | 'dark'
  customLogo?: Asset
  customLogoHeight?: number
  copyrightText?: string
}

export const customComponentsThemeMap: {
  [key in 'dark' | 'default']: {
    background: PaletteAlias
    foreground: PaletteAlias
    foregroundSecondary: PaletteAlias | SpectrumAlias
    foregroundTertiary: PaletteAlias | SpectrumAlias
  }
} = {
  default: {
    background: 'background',
    foreground: 'foreground',
    foregroundSecondary: 'foregroundMuted',
    foregroundTertiary: 'gray40',
  },
  dark: {
    background: 'foreground',
    foreground: 'background',
    foregroundSecondary: 'gray60',
    foregroundTertiary: 'gray60',
  },
}

export const GlobalFooter = ({
  socialMediaLinks = [],
  legalDisclaimer,
  columnOne = [],
  columnTwo = [],
  columnThree = [],
  style = 'default',
  customLogo,
  customLogoHeight,
  copyrightText = 'Coinbase',
}: GlobalFooterProps) => {
  const { foreground, foregroundSecondary, foregroundTertiary, background } =
    customComponentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="GlobalFooter">
        <SectionComponentLayout
          as="footer"
          hasSpacingHorizontal
          background={background}
        >
          <Div spacingVertical={6} justifyContent="start">
            {legalDisclaimer && (
              <LegalDisclaimer legalDisclaimer={legalDisclaimer} />
            )}
          </Div>

          <Div
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Div
              display="grid"
              gridTemplateColumns={{ sm: '1fr', lg: '1fr 2fr' }}
              gap={1}
              rowGap={4}
              width="100%"
              spacingBottom={10}
            >
              <Div flexDirection="column" gap={1}>
                {customLogo ? (
                  <Div
                    spacingBottom={3}
                    css={{
                      img: {
                        height: `${
                          customLogoHeight
                            ? customLogoHeight
                            : NAVBAR_LOGO_HEIGHT
                        }px !important`,
                        width: 'auto',
                      },
                    }}
                  >
                    <Image
                      alt={customLogo?.fields?.description || ''}
                      src={customLogo?.fields?.file?.url || ''}
                    />
                  </Div>
                ) : (
                  <Div width={NAVBAR_LOGO_WIDTH} spacingBottom={3}>
                    <CoinbaseLogo style={style} />
                  </Div>
                )}

                {/* TODO EMKTG-1464: localization picker here */}

                <Text variant="body" as="p" color={foregroundTertiary}>
                  &copy; {new Date().getFullYear()} {copyrightText}
                </Text>

                <Div alignItems="center" flexWrap="wrap">
                  {socialMediaLinks.map(({ label, url, target }, index) => (
                    <Fragment key={index}>
                      <Link
                        target={target}
                        href={url}
                        data-qa={`${ANALYTICS_KEY.SocialMedia}${index}`}
                      >
                        <Text
                          variant="body"
                          as="span"
                          color={foregroundTertiary}
                          css={{
                            ':hover': {
                              color: colorToVariable('primary'),
                            },
                          }}
                        >
                          {label}
                        </Text>
                      </Link>

                      {index !== socialMediaLinks.length - 1 && (
                        <Text
                          variant="body"
                          as="span"
                          spacingHorizontal={0.5}
                          color={foregroundTertiary}
                        >
                          &bull;
                        </Text>
                      )}
                    </Fragment>
                  ))}
                </Div>
              </Div>

              <Div
                display="grid"
                columnGap={4}
                gridTemplateColumns={{
                  sm: '1fr',
                  md: '1fr 1fr',
                  lg: '1fr 1fr 1fr',
                }}
              >
                {[columnOne, columnTwo, columnThree].map((column, index) => {
                  return (
                    <AnalyticsProvider
                      key={index}
                      analyticsPrefix={`${ANALYTICS_KEY.Column}${index}`}
                    >
                      <FooterColumn
                        column={column}
                        foreground={foreground}
                        foregroundSecondary={foregroundSecondary}
                      />
                    </AnalyticsProvider>
                  )
                })}
              </Div>
            </Div>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
