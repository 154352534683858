import { memo } from 'react'

import { Link, Text } from '@cbhq/cdx-components'

export const getFlashMessage = (flashMessages?: Record<string, string>) => {
  if (typeof window === 'undefined' || !flashMessages) return undefined

  const queryKey = new URLSearchParams(window.location.search).get('message')
  return queryKey ? flashMessages?.[queryKey] : undefined
}

export const FlashMessage = memo(function FlashMessage({
  message,
}: {
  message?: string
}) {
  if (!message) return null

  const msgArray = message.split(/\[\[(.*?)\]\]/)

  const components = msgArray.reduce<React.ReactNode[]>((acc, text, idx) => {
    if (text.includes('LINK|')) {
      const [, href, value] = text.split('|')
      acc.push(
        <Link key={idx.toString()} href={href} color="negativeForeground">
          {value}
        </Link>,
      )
    } else {
      acc.push(<span key={idx.toString()}>{text}</span>)
    }
    return acc
  }, [])

  return (
    <Text as="p" color="negativeForeground" variant="body" display="block">
      {components}
    </Text>
  )
})
