import { ShareLinkSvg } from '../../../ui/ShareLinks/ShareLinkSvg'

export const Twitter = ({ fill = 'white', ...rest }) => {
  return (
    <ShareLinkSvg {...rest}>
      <path
        d="M 15 2.8493 C 14.4484 3.0937 13.8558 3.2588 13.2328 3.3335 C 13.8756 2.9489 14.3565 2.3435 14.5858 1.6304 C 13.9819 1.9891 13.3209 2.2416 12.6317 2.3769 C 12.1682 1.882 11.5543 1.554 10.8852 1.4438 C 10.2162 1.3336 9.5295 1.4473 8.9317 1.7673 C 8.3339 2.0874 7.8586 2.5958 7.5794 3.2137 C 7.3002 3.8316 7.2328 4.5244 7.3877 5.1845 C 6.164 5.1231 4.9669 4.8051 3.8742 4.251 C 2.7814 3.697 1.8173 2.9194 1.0444 1.9687 C 0.7802 2.4245 0.6282 2.953 0.6282 3.5158 C 0.628 4.0225 0.7527 4.5214 0.9915 4.9684 C 1.2303 5.4153 1.5757 5.7963 1.997 6.0777 C 1.5084 6.0622 1.0305 5.9301 0.6031 5.6926 V 5.7322 C 0.6031 6.4429 0.8489 7.1316 1.2989 7.6817 C 1.7489 8.2317 2.3753 8.6091 3.0719 8.7499 C 2.6185 8.8726 2.1433 8.8907 1.6819 8.8028 C 1.8785 9.4142 2.2613 9.9489 2.7768 10.332 C 3.2923 10.7151 3.9148 10.9274 4.5569 10.9392 C 3.4668 11.795 2.1205 12.2592 0.7346 12.2571 C 0.4891 12.2572 0.2438 12.2429 0 12.2142 C 1.4068 13.1187 3.0444 13.5987 4.7168 13.5969 C 10.3783 13.5969 13.4733 8.9078 13.4733 4.841 C 13.4733 4.7089 13.47 4.5755 13.4641 4.4433 C 14.0661 4.008 14.5857 3.4689 14.9987 2.8513 L 15 2.8493 Z"
        fill={fill}
      />
    </ShareLinkSvg>
  )
}
