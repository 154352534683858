import { cloneElement, Children, useMemo, Fragment } from 'react'

import styled from 'styled-components'

import { Divider } from '@cbhq/cds-web/layout'

import type {
  WayfindingCardArticleProps,
  WayfindingCardArticleInnerProps,
} from './CardArticle'
import { media } from '../../breakpoints'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { LinkButton } from '../../ui/Link'
import { Text } from '../../ui/Text'

export type CardGridColumnsOpt = 1 | 2 | 3 | 4
export type CardGridRowsOpt = 1 | 2 | 3 | 'all'

type CardType = WayfindingCardArticleProps & WayfindingCardArticleInnerProps

export type WayfindingProps = {
  columns: CardGridColumnsOpt
  rows?: CardGridRowsOpt
  style?: ComponentsThemeStyle
  headline?: string
  body?: string
  hasSpacingTop?: boolean
  hasSpacingHorizontal?: boolean
  children?: React.ReactElement<CardType> | React.ReactElement<CardType>[]
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  compact?: boolean
  hasOneColumnDivider?: boolean
  ctaPosition?: 'top' | 'bottom'
  aspectRatio?: 'long' | 'square'
  anchor?: string
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

export const Wayfinding = ({
  headline,
  body,
  style = 'default',
  columns,
  rows = 'all',
  hasSpacingTop = false,
  hasSpacingHorizontal = true,
  ctaButtonLabel,
  ctaButtonUrl,
  children,
  compact,
  hasOneColumnDivider,
  ctaPosition = 'bottom',
  aspectRatio = 'long',
  anchor,
  as,
}: WayfindingProps) => {
  const { foreground, foregroundSecondary, background } =
    componentsThemeMap[style]

  const childrenToRender = useMemo(() => {
    let arrayChildren = Children.toArray(children)

    if (rows !== 'all') arrayChildren = arrayChildren.slice(0, rows * columns)

    return arrayChildren.map((child, index) => {
      return (
        <Fragment key={index}>
          <AnalyticsProvider analyticsPrefix={`${ANALYTICS_KEY.Child}${index}`}>
            {cloneElement<CardType>(child as any, {
              style,
              largeThumbnail: columns === 2,
              long: columns === 1,
              compact,
              aspectRatio,
            })}
            {hasOneColumnDivider &&
              columns === 1 &&
              index !== arrayChildren.length - 1 && <Divider opacity={1} />}
          </AnalyticsProvider>
        </Fragment>
      )
    })
  }, [
    children,
    rows,
    columns,
    style,
    compact,
    hasOneColumnDivider,
    aspectRatio,
  ])

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="Wayfinding">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={hasSpacingHorizontal}
          background={background}
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical
          as={as}
        >
          <Div flexDirection="column" gap={7}>
            {(headline || body) && (
              <Div
                flexDirection={{ sm: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems="flex-end"
                gap={1}
              >
                <Div flexDirection="column" width="100%" gap={1}>
                  {headline && (
                    <Text
                      as="h2"
                      variant={{
                        sm: 'title2',
                        lg: compact ? 'display2' : 'display3',
                      }}
                      color={foreground}
                    >
                      {headline}
                    </Text>
                  )}

                  {body && (
                    <Text
                      as="p"
                      variant={{ sm: 'body', lg: 'title4' }}
                      color={foregroundSecondary}
                    >
                      {body}
                    </Text>
                  )}
                </Div>
                {ctaPosition === 'top' && ctaButtonLabel && ctaButtonUrl && (
                  <Div
                    flexDirection="column"
                    display={{ sm: 'none', md: 'flex' }}
                    maxWidth={{ sm: '100%', md: '75%' }}
                    gap={1}
                  >
                    <LinkButton
                      href={ctaButtonUrl}
                      variant="secondary"
                      data-qa={ANALYTICS_KEY.ButtonLabel}
                    >
                      {ctaButtonLabel}
                    </LinkButton>
                  </Div>
                )}
              </Div>
            )}

            {childrenToRender && (
              <Div
                data-qa={ANALYTICS_KEY.CardGrid}
                display="grid"
                gridTemplateColumns={{
                  sm: '1fr',
                  md: columns === 1 ? '1fr' : '1fr 1fr',
                  lg: `repeat(${columns}, 1fr)`,
                }}
                columnGap={{ sm: 3, md: 4 }}
                rowGap={{
                  sm: 7,
                  md: 8,
                  lg: !hasOneColumnDivider && columns === 1 ? 11 : 8,
                }}
                maxWidth={compact ? { sm: '100%', lg: 900 } : '100%'}
              >
                <SkipRows
                  total={childrenToRender.length}
                  columns={columns}
                  rows={rows}
                >
                  {childrenToRender}
                </SkipRows>
              </Div>
            )}

            {ctaPosition === 'bottom' && ctaButtonLabel && ctaButtonUrl && (
              <Div
                justifyContent="center"
                display={{ sm: 'flex', md: compact ? 'none' : 'flex' }}
              >
                <LinkButton
                  href={ctaButtonUrl}
                  data-qa={ANALYTICS_KEY.ButtonLabel}
                >
                  {ctaButtonLabel}
                </LinkButton>
              </Div>
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

const SkipRows = styled.div<{
  rows: CardGridRowsOpt
  columns: CardGridColumnsOpt
  total: number
}>`
  display: contents;

  ${({ rows, columns, total }) =>
    columns > 2 &&
    rows !== 'all' &&
    total % 2 !== 0 &&
    `
    > div:last-child {
      display: flex;
    }

    ${media.md} {
      > div:last-child {
        display: none;
      }
    }

    ${media.lg} {
      > div:last-child {
        display: flex;
      }
  `}
`
