import type { ResponsiveValue } from '../../Div/types'
import { Text } from '../../Text'
import type { TextVariant } from '../../Text'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type HeadingProps = RichTextPropsType & {
  display?: string
  variant?: ResponsiveValue<TextVariant>
  as: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

const variantMap: Record<string, ResponsiveValue<TextVariant>> = {
  h1: 'title2',
  h2: 'title2',
  h3: 'title3',
  h4: 'title4',
  h5: 'label1',
  h6: 'label2',
}

export const Heading = ({
  as,
  variant,
  display = 'inline',
  color = 'foreground',
  defaultSpacingTop = 3,
}: HeadingProps): RenderNodePropsTypes => {
  return function heading(node, children) {
    return (
      <Text
        as={as}
        color={color}
        display={display}
        variant={variant || variantMap[as as string]}
        spacingTop={defaultSpacingTop}
      >
        {children}
      </Text>
    )
  }
}
