import styled from 'styled-components'

import { durations } from '@cbhq/cds-common/motion/tokens'
import { palette, spacing } from '@cbhq/cds-web/tokens'

import { NAVBAR_BANNER_HEIGHT, NAVBAR_NAV_HEIGHT } from './constants'
import { TrapdoorDescriptionColumn } from './TrapdoorDescriptionColumn'
import { TrapdoorProductListColumn } from './TrapdoorProductListColumn'
import type { TrapdoorLinkProps } from './types'
import { Div } from '../../ui/Div'

type TrapdoorProps = {
  isActive: boolean
  links: TrapdoorLinkProps[]
  productLink?: TrapdoorLinkProps
  hasBanner?: boolean
  columnCount?: number
}

export const Trapdoor = ({
  isActive,
  links,
  productLink,
  hasBanner = false,
  columnCount = 2,
}: TrapdoorProps) => (
  <Wrapper isActive={isActive} hasBanner={hasBanner}>
    <Div maxWidth={1065} justifyContent="center">
      <ColumnWrapper columns={columnCount}>
        {links.map(({ url, label, description, icon, linkTarget }, index) => {
          return (
            <TrapdoorProductListColumn
              key={`${index}-${label}`}
              header={label}
              subhead={description}
              iconUrl={icon}
              url={url}
              target={linkTarget}
            />
          )
        })}
      </ColumnWrapper>

      {productLink && (
        <TrapdoorDescriptionColumn
          header={productLink.label}
          subhead={productLink.description}
          labelCta={productLink.urlLabel}
          url={productLink.url}
        />
      )}
    </Div>
  </Wrapper>
)

const COLUMN_GAP = 34
const ROW_GAP = 20

const Wrapper = styled.section<{
  isActive: boolean
  hasBanner: boolean
}>`
  background: ${palette.background};
  transition: all ${durations.fast3}ms linear, box-shadow 0ms linear;
  text-align: left;
  display: none;
  position: absolute;
  top: ${({ hasBanner }) =>
    hasBanner
      ? NAVBAR_NAV_HEIGHT + NAVBAR_BANNER_HEIGHT + 1
      : NAVBAR_NAV_HEIGHT + 1}px;

  box-sizing: border-box;
  cursor: initial;

  left: 0;
  width: 100%;
  ${({ isActive }) =>
    isActive
      ? `
          display: flex;
          justify-content: center;
          padding: ${spacing[6]};
          transition: display ${durations.fast3}ms linear;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        `
      : ''}
`
const ColumnWrapper = styled.div<{
  columns?: number
}>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 2}, max-content);
  column-gap: ${COLUMN_GAP}px;
  row-gap: ${ROW_GAP}px;
  grid-auto-flow: row;
  margin-right: 60px;
`
