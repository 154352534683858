import type { Entry } from 'contentful'

import type {
  FooterLink,
  FooterLinkGroup,
  NavbarProps,
} from '@cbhq/cdx-components'

import type { CDXSettingsFooterFields } from './footer'
import { mapFooterProps } from './footer'
import { mapMicrocopyProps } from './microcopy'
import type {
  CDXSettingsMicrocopyGroupFields,
  ContentfulMicrocopyValues,
} from './microcopy'
import type { CDXSettingsNavbarFields } from './navbar'
import { mapNavbarProps } from './navbar'
import { mapEntryLink } from './navlink'
import type { CDXSettingsNavLinkFields } from './navlink'
import { getEntryProps } from '../utils/getEntryProps'

export const CDX_SETTINGS_SITE_CONTENT_MODEL_ID = 'cdxSettingsSite'

export type CDXSettingsSiteFields = {
  footer?: Entry<CDXSettingsFooterFields>
  navbar?: Entry<CDXSettingsNavbarFields>
  bannerText?: string
  bannerLink?: Entry<CDXSettingsNavLinkFields>
  microcopies: Entry<CDXSettingsMicrocopyGroupFields>
}

export type SiteConfigProps = {
  navbar: NavbarProps
  footer: {
    socialMediaLinks: FooterLink[]
    columnOne: FooterLinkGroup[]
    columnTwo: FooterLinkGroup[]
    columnThree: FooterLinkGroup[]
  }
  banner: {
    message: string
    url?: string
  }
  microcopies?: ContentfulMicrocopyValues
}

export const getSiteConfigProps = (
  siteConfig: Entry<CDXSettingsSiteFields> | undefined,
): SiteConfigProps => {
  const { footer, navbar, bannerText, bannerLink, microcopies } = getEntryProps(
    siteConfig,
    {},
  )

  const navbarProps = mapNavbarProps(navbar)

  return {
    footer: mapFooterProps(footer),

    navbar: navbarProps,

    banner: {
      message: bannerText || '',
      url: bannerLink ? mapEntryLink(bannerLink).url : '',
    },

    microcopies: mapMicrocopyProps(microcopies),
  }
}

export const getSiteConfigMicrocopies = (
  siteConfig: Entry<CDXSettingsSiteFields> | undefined,
) => {
  const { microcopies } = getEntryProps(siteConfig)

  return mapMicrocopyProps(microcopies)
}
