import { Div } from '../../Div'
import { Image as ImageComponent } from '../../Image'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

export const BlockEmbeddedAsset = ({
  defaultSpacingTop,
}: RichTextPropsType = {}): RenderNodePropsTypes =>
  function embeddedAsset(node, children) {
    return (
      <Div spacingTop={defaultSpacingTop}>
        <ImageComponent
          loading="lazy"
          maxWidth="100%"
          alt={node?.data?.target?.fields?.title || ''}
          src={node?.data?.target?.fields?.file?.url || ''}
        />
      </Div>
    )
  }
