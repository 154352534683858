import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document } from '@contentful/rich-text-types'

import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-web'

import { composeRichTextComponents } from './options'
import type { RichTextOption } from './types'
import type { DivProps } from '../Div'
import { Div } from '../Div'

const SPACING_TOP = 3

export type RichTextProps = {
  content: Document
  color?: PaletteAlias
  foregroundInlineLink?: SpectrumAlias
  defaultSpacingTop?: DivProps['spacing']
  paragraphSpacingTop?: DivProps['spacing']
  secondaryColor?: PaletteAlias
  richTextOptions?: RichTextOption
}

const defaultOptions = composeRichTextComponents({
  hasOverrideContentfulParagraphWrapping: true,
})

export const RichText = ({
  content,
  color = 'foreground',
  foregroundInlineLink = 'blue60',
  defaultSpacingTop = SPACING_TOP,
  paragraphSpacingTop,
  secondaryColor = 'foregroundMuted',
  richTextOptions = defaultOptions,
}: RichTextProps) => (
  <Div display="contents" className="rich-text">
    {documentToReactComponents(
      content,
      richTextOptions({
        color,
        foregroundInlineLink,
        defaultSpacingTop,
        paragraphSpacingTop,
        secondaryColor,
      }),
    )}
  </Div>
)
