import type { FlexAxisValue, FlexSpaceCommon } from '@cbhq/cds-web'

import { ANALYTICS_KEY } from '../constants'
import { Div } from '../ui/Div'
import type { DivProps } from '../ui/Div'

export type JustifyContentTypes =
  | FlexAxisValue
  | FlexSpaceCommon
  | 'space-evenly'

export type TwoColumnContainerProps = {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
  rightPercentage?: number
  leftPercentage?: number
  justifyContent?: JustifyContentTypes
  reverseOnWrap?: boolean
  wrapOnTablet?: boolean
  gap?: DivProps['gap']
}

export const TwoColumnContainer = ({
  leftCol,
  rightCol,
  rightPercentage = 50,
  leftPercentage = 50,
  reverseOnWrap,
  wrapOnTablet,
  gap = 0,
}: TwoColumnContainerProps) => {
  const wrap = reverseOnWrap ? 'wrap-reverse' : 'wrap'

  const sumWrapProp = leftPercentage + rightPercentage > 100 ? wrap : 'nowrap'

  return (
    <Div
      flexWrap={{
        sm: wrap,
        md: wrapOnTablet ? wrap : sumWrapProp,
        lg: sumWrapProp,
      }}
      justifyContent="space-evenly"
      width="100%"
      height="fit-content"
      gap={gap}
    >
      <Div
        flexShrink={1}
        flexGrow={1}
        flexBasis={{
          sm: '100%',
          md: wrapOnTablet ? '100%' : `${leftPercentage}%`,
          lg: `${leftPercentage}%`,
        }}
        maxWidth="100%"
        data-qa={ANALYTICS_KEY.TwoColumnContainerLeft}
      >
        {leftCol}
      </Div>

      <Div
        flexShrink={1}
        flexGrow={1}
        flexBasis={{
          sm: '100%',
          md: wrapOnTablet ? '100%' : `${rightPercentage}%`,
          lg: `${rightPercentage}%`,
        }}
        maxWidth="100%"
        data-qa={ANALYTICS_KEY.TwoColumnContainerRight}
      >
        {rightCol}
      </Div>
    </Div>
  )
}
