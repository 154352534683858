import type { PaletteAlias } from '@cbhq/cds-common'

import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { Pressable } from '../Button/index'
import { LinkPressable } from '../Link/LinkPressable'

type SvgLinkAsHref = {
  onClick?: never
  link: string
}

type SvgLinkAsAction = {
  onClick: () => void
  link?: never
}

type SvgLinkProps = {
  svg: React.ReactNode
  background?: PaletteAlias
} & (SvgLinkAsHref | SvgLinkAsAction)

export const SvgLink = ({ link, onClick, svg, background }: SvgLinkProps) => {
  const content = (
    <Div
      borderColor="line"
      bordered
      spacing={1}
      borderRadius="roundedFull"
      alignItems="center"
      justifyContent="center"
      background={background}
      css={{ transition: 'background 250ms' }}
      width={32}
      height={32}
    >
      {svg}
    </Div>
  )

  if (Boolean(link)) {
    return (
      <LinkPressable
        href={link || ''}
        rel="noopener noreferrer"
        target="_blank"
        data-qa={ANALYTICS_KEY.SvgLink}
      >
        {content}
      </LinkPressable>
    )
  }

  return (
    <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.SvgContent}>
      <Pressable background="transparent" onPress={onClick}>
        {content}
      </Pressable>
    </AnalyticsProvider>
  )
}
