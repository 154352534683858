import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import { HeroEditorial } from '@cbhq/cdx-components'
import type { HeroEditorialImageFit } from '@cbhq/cdx-components'

import type { CDXGlobalAuthorFields } from '../../globals/author'
import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalTagFields } from '../../globals/tag'
import { getEntryProps } from '../../utils/getEntryProps'

export type EditorialHeroProps = {
  title: string
  subheadRichText?: Document
  image?: Asset
  imageFit?: HeroEditorialImageFit

  readTime?: string
  datePublished?: string
  tags?: Entry<CDXGlobalTagFields>[]
  showSocialSharingLinks?: boolean
  authors?: Entry<CDXGlobalAuthorFields>[]
}

export const EditorialHero = ({
  title,
  subheadRichText,
  image,
  readTime,
  datePublished,
  tags,
  authors,
  imageFit,
  showSocialSharingLinks,
}: EditorialHeroProps) => {
  const authorsName = authors
    ?.map((author) => {
      const props = getEntryProps(author)
      return props.authorName
    })
    .join(', ')

  const categoryTag = tags?.find((tag) => tag.fields.type !== 'internal')

  const { label, link } = getEntryProps(categoryTag)
  const tagLink = mapEntryLink(link)

  return (
    <HeroEditorial
      headline={title}
      subhead={subheadRichText}
      mediaUrl={image?.fields.file?.url}
      mediaAlt={image?.fields.title}
      author={authorsName}
      datePublished={datePublished}
      categoryTagLabel={label}
      categoryTagUrl={tagLink.url && `/${tagLink.url}`}
      readTime={readTime}
      imageFit={imageFit}
      bordered
      showSocialSharingLinks={showSocialSharingLinks}
    />
  )
}
