import { LogoWordmark } from '@cbhq/cds-web/icons/LogoWordmark'

import { Div } from '../Div'

type CoinbaseLogoProps = {
  style?: 'default' | 'dark'
}

export const CoinbaseLogo = ({ style = 'default' }: CoinbaseLogoProps) => {
  return (
    <Div as="span" display="contents">
      <LogoWordmark />
    </Div>
  )
}
