import type { Asset, Entry } from 'contentful'

import type { ComposePageEntry } from '../globals/composePage'
import { getComposeEntryProps } from '../globals/composePage'
import type { PageTemplateFields } from '../globals/pageTemplateTypes'
import { getEntryProps } from '../utils/getEntryProps'

export const GLOBAL_NAV_LINK_CONTENT_MODEL_ID = 'cdxGlobalNavLink'

export type CDXGlobalNavLinkFields = {
  label: string
  url?: string
  urlLabel?: string
  composePage: ComposePageEntry<PageTemplateFields>
  subPath?: string
  appendCurrentSubpathToUrl?: boolean
  supportedCountries?: string[]
}

/**
 * To be used only with Settings models (e.g. navbar, footer)
 */
export type CDXSettingsNavLinkFields = CDXGlobalNavLinkFields & {
  icon?: Asset
  description?: string
}

export type CDXSettingsLinkGroupFields = {
  category: string
  links: Entry<CDXSettingsNavLinkFields>[]
  categoryLink?: Entry<CDXSettingsNavLinkFields>
}

const getUrl = (
  url: string,
  navLink?: ComposePageEntry<PageTemplateFields>,
  options?: {
    subPath?: string
    appendCurrentSubpathToUrl?: boolean
  },
): string => {
  if (!navLink) return url

  if (!navLink.fields) {
    // If fields are empty, it means the current page is the composePage (by circular ref), so the url should point to the current URL.
    return ''
  }

  const { composeSlug } = getComposeEntryProps(navLink)

  return options?.subPath && options?.appendCurrentSubpathToUrl
    ? `${options.subPath}/${composeSlug}`
    : `${url ? `${url}/` : ''}${composeSlug}`.replace('//', '/')
}

export const mapEntryLink = (
  entry?: Entry<CDXSettingsNavLinkFields | CDXGlobalNavLinkFields>,
): {
  label: string
  url: string
  urlLabel: string
} => {
  const {
    label = '',
    url = '',
    urlLabel = '',
    composePage: navLinkContent,
    subPath,
    appendCurrentSubpathToUrl,
  } = getEntryProps(entry)

  return {
    url: getUrl(url, navLinkContent, { subPath, appendCurrentSubpathToUrl }),
    label,
    urlLabel,
  }
}

export const mapSettingNavLink = (
  entry: Entry<CDXSettingsNavLinkFields>,
): {
  label: string
  url: string
  urlLabel: string
  description?: string
  icon?: string
} => {
  const linkProps = mapEntryLink(entry)

  const { description, icon } = getEntryProps(entry)

  return {
    ...linkProps,
    description,
    icon: icon?.fields.file.url,
  }
}

export const mapLinkGroup = (
  linkGroupEntry: Entry<CDXSettingsLinkGroupFields>,
) => {
  const { category = '', links = [] } = getEntryProps(linkGroupEntry)

  return {
    category,
    links: links?.map(mapEntryLink),
  }
}
