import { useImageComponent } from '../../providers/ImageComponentProvider'

export type ImageLoad = 'lazy' | 'eager'

export type ImageBaseProps = {
  src:
    | string
    | {
        src: string
        width: number
        height: number
      }
  alt?: string

  width?: number
  height?: number

  /** Usually used when above the fold */
  priority?: boolean
  loading?: ImageLoad
  className?: string
}

export const ImageBase = ({
  src = '',
  alt = 'image',
  width = 1000,
  height = 1000,
  priority,
  loading,
  className,
}: ImageBaseProps) => {
  const ImageComponent = useImageComponent()

  if (!src) return null

  return (
    <ImageComponent
      src={src}
      alt={alt}
      width={width}
      height={height}
      priority={priority}
      loading={loading}
      className={className}
    />
  )
}

export type ImageComponent = (props: ImageBaseProps) => React.ReactElement
