import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-common'

export type ComponentsThemeStyle =
  | 'default'
  | 'dark'
  | 'subtle'
  | 'wash'
  | 'primary'
  | 'darkwash'

export type ComponenThemeProps = {
  background: PaletteAlias
  foreground: PaletteAlias
  foregroundSecondary: PaletteAlias
  foregroundInlineLink: SpectrumAlias
  backgroundAlternate?: PaletteAlias
  backgroundHover?: PaletteAlias
  borderLine?: PaletteAlias
  primary?: PaletteAlias
}

export const componentsThemeMap: {
  [key in ComponentsThemeStyle]: ComponenThemeProps
} = {
  default: {
    background: 'background',
    foreground: 'foreground',
    foregroundSecondary: 'foregroundMuted',
    foregroundInlineLink: 'blue60',
    backgroundAlternate: 'backgroundAlternate',
    borderLine: 'line',
    primary: 'primary',
  },
  dark: {
    background: 'foreground',
    foreground: 'background',
    foregroundSecondary: 'background',
    foregroundInlineLink: 'blue30',
    backgroundAlternate: 'foregroundMuted',
    backgroundHover: 'primary',
    borderLine: 'background',
    primary: 'primary',
  },
  darkwash: {
    background: 'gray90' as PaletteAlias,
    foreground: 'background',
    foregroundSecondary: 'background',
    foregroundInlineLink: 'blue30',
    backgroundAlternate: 'foregroundMuted',
    backgroundHover: 'primary',
    borderLine: 'background',
    primary: 'primary',
  },
  subtle: {
    background: 'backgroundAlternate',
    foreground: 'foreground',
    foregroundSecondary: 'foregroundMuted',
    foregroundInlineLink: 'blue60',
    backgroundAlternate: 'background',
    borderLine: 'foregroundMuted',
    primary: 'primary',
  },
  wash: {
    background: 'primaryWash',
    foreground: 'foreground',
    foregroundSecondary: 'foregroundMuted',
    foregroundInlineLink: 'blue60',
    backgroundAlternate: 'background',
    backgroundHover: 'backgroundAlternate',
    borderLine: 'foregroundMuted',
    primary: 'primary',
  },
  primary: {
    background: 'primary',
    foreground: 'background',
    foregroundSecondary: 'backgroundAlternate',
    foregroundInlineLink: 'gray0',
    borderLine: 'background',
    backgroundAlternate: 'foreground',
    backgroundHover: 'line',
    primary: 'background',
  },
}

const camelToKebabCase = (str: string) =>
  str.replace(/[A-Z]/g, (s: string) => `-${s.toLowerCase()}`)

export const colorToVariable = (color: PaletteAlias) =>
  `var(--${camelToKebabCase(color)})`

export const formatCustomColor = (value: SpectrumAlias | PaletteAlias) =>
  `rgb(var(--${value}))`
