import { useMemo } from 'react'

import { TwoColumnContainer } from '../layout/TwoColumnContainer'
import type { DivProps } from '../ui/Div'

export type TwoColumnLayoutMainContentPosition =
  | 'right'
  | 'left'
  | 'top'
  | 'bottom'

export type TwoColumnLayoutProps = {
  mainContent: React.ReactNode
  secondaryContent: React.ReactNode
  mainContentPercentage?: number
  secondaryContentPercentage?: number
  mainContentPosition: TwoColumnLayoutMainContentPosition
  mainContentPositionOnWrap?: 'top' | 'bottom'
  wrapOnTablet?: boolean
  gap?: DivProps['gap']
}

const FULL_PERCENTAGE: number = 100

export const TwoColumnLayout = ({
  mainContentPosition,
  mainContent,
  secondaryContent,
  mainContentPercentage = 50,
  secondaryContentPercentage = 50,
  mainContentPositionOnWrap = 'top',
  wrapOnTablet,
  gap,
}: TwoColumnLayoutProps) => {
  const [leftPercentage, rightPercentage] = useMemo((): number[] => {
    if (mainContentPosition === 'right') {
      return [secondaryContentPercentage, mainContentPercentage]
    }

    if (mainContentPosition === 'left') {
      return [mainContentPercentage, secondaryContentPercentage]
    }

    return [FULL_PERCENTAGE, FULL_PERCENTAGE]
  }, [mainContentPercentage, mainContentPosition, secondaryContentPercentage])

  const [leftContent, rightContent, reverseOnWrap] = useMemo(() => {
    if (mainContentPosition === 'right' || mainContentPosition === 'bottom') {
      return [
        secondaryContent,
        mainContent,
        mainContentPositionOnWrap === 'top',
      ]
    }

    return [
      mainContent,
      secondaryContent,
      mainContentPositionOnWrap === 'bottom',
    ]
  }, [
    mainContentPositionOnWrap,
    mainContent,
    mainContentPosition,
    secondaryContent,
  ])

  return (
    <TwoColumnContainer
      leftCol={leftContent}
      rightCol={rightContent}
      leftPercentage={leftPercentage}
      rightPercentage={rightPercentage}
      reverseOnWrap={reverseOnWrap}
      wrapOnTablet={wrapOnTablet}
      gap={gap}
    />
  )
}
