import { Fragment } from 'react'

import { Text } from '../../Text'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type ListItemProps = RichTextPropsType & {
  listWrapper?: string
  css?: Record<string, any>
}

export const ListItem = ({
  color,
  css,
  listWrapper,
}: ListItemProps = {}): RenderNodePropsTypes => {
  const ListWrapper = listWrapper ? listWrapper : Fragment
  return function listItem(node, children) {
    return (
      <ListWrapper>
        <Text
          as="li"
          css={css}
          variant="body"
          display="list-item"
          color={color}
        >
          {children}
        </Text>
      </ListWrapper>
    )
  }
}
