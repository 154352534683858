import { useEffect, useState } from 'react'

import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { Facebook } from '../../ui/ShareLinks/icons/Facebook'
import { LinkChain } from '../../ui/ShareLinks/icons/LinkChain'
import { Linkedin } from '../../ui/ShareLinks/icons/Linkedin'
import { Twitter } from '../../ui/ShareLinks/icons/Twitter'
import { SvgLink } from '../../ui/ShareLinks/SvgLink'

export type ShareLinksProps = {
  canonicalUrl: string
}

export const ShareLinks = ({ canonicalUrl }: ShareLinksProps) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const [documentTitle, setDocumentTitle] = useState('')

  const copyUrl = async (url: string) => {
    await navigator.clipboard.writeText(url)
    const text = await navigator.clipboard.readText()
    text && setCopySuccess(true)

    setTimeout(() => {
      setCopySuccess(false)
    }, 500)
  }

  useEffect(() => {
    setDocumentTitle(encodeURIComponent(document?.title))
  }, [])

  return (
    <Div position="relative" flexDirection="column">
      <Div gap={1}>
        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.Twitter}>
          <SvgLink
            link={`https://twitter.com/intent/tweet?text=${documentTitle}&url=${canonicalUrl}`}
            svg={<Twitter fill="var(--primary)" />}
          />
        </AnalyticsProvider>
        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.Facebook}>
          <SvgLink
            link={`https://www.facebook.com/share.php?u=${canonicalUrl}`}
            svg={<Facebook fill="var(--primary)" />}
          />
        </AnalyticsProvider>
        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.LinkedIn}>
          <SvgLink
            link={`https://linkedin.com/sharing/share-offsite/?url=${canonicalUrl}`}
            svg={<Linkedin fill="var(--primary)" />}
          />
        </AnalyticsProvider>
        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.LinkChain}>
          <SvgLink
            onClick={() => {
              copyUrl(canonicalUrl)
            }}
            svg={
              <LinkChain
                fill={
                  copySuccess ? 'var(--positive-foreground)' : 'var(--primary)'
                }
              />
            }
            background={copySuccess ? 'positive' : undefined}
          />
        </AnalyticsProvider>
      </Div>
    </Div>
  )
}
