import { useEffect, useState } from 'react'

export const useInView = (
  elementsIds?: string[],
  viewportPositionTopTrigger: number | string = '50%',
  viewportPositionBottomTrigger: number | string = '50%',
  outwardTrigger = false,
) => {
  const [inView, setInView] = useState(-1)

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined' || !elementsIds) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const anchorsEnteringView = entries
          .filter((entry) => entry.isIntersecting)
          .map((entry) => elementsIds.indexOf(entry.target.id))

        if (anchorsEnteringView.length > 0) {
          const index = anchorsEnteringView[0]

          setInView(index)
        } else {
          const entry = entries[0]
          const rootY = entry?.rootBounds?.y || 0

          if (entry && entry.boundingClientRect.y > rootY) {
            setInView((index) => (index === 0 ? -1 : index))
          }
        }
      },
      {
        threshold: 0,
        rootMargin: `${!outwardTrigger ? '-' : ''}${
          typeof viewportPositionTopTrigger === 'number'
            ? `${viewportPositionTopTrigger}px`
            : viewportPositionTopTrigger
        } 0px ${!outwardTrigger ? '-' : ''}${
          typeof viewportPositionBottomTrigger === 'number'
            ? `${viewportPositionBottomTrigger}px`
            : viewportPositionBottomTrigger
        } 0px`,
      },
    )

    elementsIds.forEach((anchor) => {
      const els = document.querySelectorAll(`#${anchor}`)

      els?.forEach((el) => {
        if (el) observer.observe(el)
      })
    })

    return () => observer.disconnect()
  }, [
    elementsIds,
    viewportPositionTopTrigger,
    viewportPositionBottomTrigger,
    outwardTrigger,
  ])

  return inView
}
