import styled from 'styled-components'

import type { PaletteAlias, SpectrumAlias } from '@cbhq/cds-common'

import { media } from '../../breakpoints'
import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { LinkButton } from '../../ui/Link'
import { StyledInlineLink } from '../../ui/RichText'
import { Text } from '../../ui/Text'

export type CTAButtonsProps = {
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  secondaryButtonLabel?: string
  secondaryButtonUrl?: string
}

type CTAButtonsInnerProps = {
  contentBackground: PaletteAlias
  foregroundInlineLink?: SpectrumAlias
}

export const CTAButtons = ({
  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
  contentBackground = 'background',
  foregroundInlineLink = 'blue60',
}: CTAButtonsProps & CTAButtonsInnerProps) => {
  const isPrimaryBackground = contentBackground === 'primary'

  const primaryButton =
    ctaButtonLabel && ctaButtonUrl ? (
      <LinkButton
        href={ctaButtonUrl}
        data-qa={ANALYTICS_KEY.CTAButtonsFirst}
        variant={isPrimaryBackground ? 'secondary' : 'primary'}
      >
        {ctaButtonLabel}
      </LinkButton>
    ) : undefined

  const secondaryButton =
    secondaryButtonLabel && secondaryButtonUrl ? (
      <Div
        css={{ display: 'flex', alignItems: 'center' }}
        justifyContent={{ sm: 'center', md: 'flex-start' }}
      >
        <StyledInlineLink
          href={secondaryButtonUrl}
          data-qa={ANALYTICS_KEY.CTAButtonsSecond}
          $color={foregroundInlineLink}
        >
          <Text variant="body">{secondaryButtonLabel}</Text>
        </StyledInlineLink>
      </Div>
    ) : undefined

  return primaryButton || secondaryButton ? (
    <ButtonsContainer
      flexDirection={{ sm: 'column', md: 'row' }}
      width={{ sm: '100%', md: 'auto' }}
      spacingTop={3}
      gap={3}
    >
      {primaryButton}
      {secondaryButton}
    </ButtonsContainer>
  ) : null
}

const ButtonsContainer = styled(Div)`
  a {
    width: 100% !important;
    text-align: center;
  }

  ${media.md} {
    a {
      width: initial;
    }
  }
`
