import Cookies from 'js-cookie'

import { GlobalFooter as SharedFooter } from '@cb/components'
import type { PaletteAlias } from '@cbhq/cds-common'
import {
  GlobalFooter as CDXFooter,
  Div,
  SectionComponentLayout,
  ErrorBoundary,
  LegalDisclaimer,
  useLocaleContext,
} from '@cbhq/cdx-components'
import type { GlobalFooterProps } from '@cbhq/cdx-components'

import {
  langPickerSupportedLocales,
  localeCodeToDisplayName,
} from '../../globals/locales'
import getRegionCodeFromLocale, {
  getLocaleCodeWithoutRegion,
} from '../../utils/splitLocale'

type FooterProps = GlobalFooterProps & {
  renderCdxFooter?: boolean
}

const customComponentsThemeMap: {
  [key in 'dark' | 'default']: {
    background: PaletteAlias
  }
} = {
  default: {
    background: 'background',
  },
  dark: {
    background: 'foreground',
  },
}

export const GlobalFooter = ({
  legalDisclaimer,
  style = 'default',
  renderCdxFooter = false,
  ...cdxFooterProps
}: FooterProps) => {
  const { locale, defaultLoggedOutConfig, fallbackLoggedOutConfig } =
    useLocaleContext()
  const countryFromIp = Cookies.get('ip_country')?.toUpperCase() || 'US'
  const localeCode = getLocaleCodeWithoutRegion(locale)
  const countryFromUrl = getRegionCodeFromLocale(
    locale,
    defaultLoggedOutConfig,
    fallbackLoggedOutConfig!,
  )
  const localeInformation = {
    selectedLocaleCode: locale,
    localeCodeToDisplayName,
    supportedLocales: langPickerSupportedLocales,
  }

  const { background } = customComponentsThemeMap[style]

  return renderCdxFooter ? (
    <CDXFooter legalDisclaimer={legalDisclaimer} {...cdxFooterProps} />
  ) : (
    <ErrorBoundary>
      <SectionComponentLayout
        as="footer"
        hasSpacingHorizontal
        background={background}
      >
        <Div spacingVertical={6} justifyContent="start">
          {legalDisclaimer && (
            <LegalDisclaimer legalDisclaimer={legalDisclaimer} />
          )}
        </Div>
        <SharedFooter
          userLocaleInfo={{
            localeCode: locale,
            countryFromUrl,
            countryFromIp,
            languageCode: localeCode,
            localeFromUrl: locale,
          }}
          localeInformation={localeInformation}
        />
      </SectionComponentLayout>
    </ErrorBoundary>
  )
}
