import type { PaletteAlias } from '@cbhq/cds-web'
// eslint-disable-next-line no-restricted-imports
import { Button as CDSButton } from '@cbhq/cds-web/buttons/Button'
import type { ButtonProps } from '@cbhq/cds-web/buttons/Button'
// eslint-disable-next-line no-restricted-imports
import { Pressable as CDSPressable } from '@cbhq/cds-web/system/Pressable'
import type { PressableInternalProps } from '@cbhq/cds-web/system/Pressable'

import { useAnalyticsContext } from '../../providers/AnalyticsProvider'
import { Div } from '../Div'

type CDXButtonProps = Omit<ButtonProps, 'to'> & { 'data-qa'?: string }

export const Button = ({ 'data-qa': dataQa, ...props }: CDXButtonProps) => {
  const { analyticsPrefix } = useAnalyticsContext()

  const finalDataQa = `${analyticsPrefix}${
    dataQa && analyticsPrefix ? '-' : ''
  }${dataQa}`

  return (
    <Div>
      <CDSButton data-qa={finalDataQa} {...props} />
    </Div>
  )
}

type CDXPressableProps = Omit<PressableInternalProps, 'to' | 'background'> & {
  background?: PaletteAlias
  'data-qa'?: string
}

export const Pressable = ({
  'data-qa': dataQa,
  ...props
}: CDXPressableProps) => {
  const { analyticsPrefix } = useAnalyticsContext()

  const finalDataQa = `${analyticsPrefix}${
    dataQa && analyticsPrefix ? '-' : ''
  }${dataQa}`

  return (
    <CDSPressable data-qa={finalDataQa} background="transparent" {...props} />
  )
}
