import { useMemo } from 'react'

import { colorToVariable } from '../../componentsThemeMap'
import { useInView } from '../../hooks/useInView'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'
import { scrollToAnchor } from '../../utils/scrollToAnchor'

export type QuickAnchor = {
  text: string
  anchor: string
}

export type EditorialArticleNavigationListProps = {
  quickScrollContent?: QuickAnchor[]
  height?: number
}

export const EditorialArticleNavigationList = ({
  quickScrollContent,
  height = 300,
}: EditorialArticleNavigationListProps) => {
  const anchors = useMemo(
    () => quickScrollContent?.map((q) => q.anchor),
    [quickScrollContent],
  )

  const anchorIndexInView = useInView(anchors)

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="EditorialArticleNavigationList">
        <Div
          as="ul"
          display="block"
          spacingStart={6}
          overflowY="auto"
          height="100%"
          maxHeight={`calc(100vh - ${height}px)`}
        >
          {quickScrollContent?.map(({ text, anchor }, index) => {
            return (
              <Div as="li" key={index} position="relative">
                <Div
                  display="list-item"
                  css={{
                    background: 'inherit',
                    cursor: 'pointer',
                    listStyleType: 'none',
                    ':hover > p': {
                      color: colorToVariable('primary'),
                    },
                  }}
                  spacingVertical={1}
                  spacingEnd={1}
                  onClick={() => scrollToAnchor(anchor, 250)}
                >
                  <>
                    {anchorIndexInView === index && (
                      <Div
                        position="absolute"
                        height="100%"
                        width="2px"
                        left={-16}
                        top={0}
                        background="primary"
                      />
                    )}
                    <Text
                      variant="label2"
                      as="p"
                      color={
                        anchorIndexInView === index ? 'primary' : 'foreground'
                      }
                    >
                      {text}
                    </Text>
                  </>
                </Div>
              </Div>
            )
          })}
        </Div>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
