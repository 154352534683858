import { ShareLinkSvg } from '../../../ui/ShareLinks/ShareLinkSvg'

export const LinkChain = ({ fill = 'white', ...rest }) => {
  return (
    <ShareLinkSvg {...rest}>
      <path
        d="M8.71163 10.4972C8.68227 10.4681 8.64261 10.4518 8.60128 10.4518C8.55996 10.4518 8.5203 10.4681 8.49094 10.4972L6.22148 12.7667C5.17073 13.8174 3.39735 13.9287 2.23723 12.7667C1.07516 11.6046 1.18648 9.83318 2.23723 8.78243L4.50669 6.51297C4.56723 6.45243 4.56723 6.35282 4.50669 6.29228L3.72937 5.51496C3.70001 5.48588 3.66035 5.46957 3.61902 5.46957C3.5777 5.46957 3.53804 5.48588 3.50868 5.51496L1.23922 7.78442C-0.413073 9.43671 -0.413073 12.1104 1.23922 13.7608C2.89151 15.4111 5.56525 15.4131 7.21559 13.7608L9.48504 11.4913C9.54559 11.4308 9.54559 11.3312 9.48504 11.2706L8.71163 10.4972ZM13.7622 1.23971C12.11 -0.412584 9.43622 -0.412584 7.78588 1.23971L5.51447 3.50916C5.48539 3.53853 5.46908 3.57819 5.46908 3.61951C5.46908 3.66084 5.48539 3.70049 5.51447 3.72986L6.28983 4.50523C6.35038 4.56577 6.44999 4.56577 6.51053 4.50523L8.77999 2.23577C9.83074 1.18502 11.6041 1.0737 12.7642 2.23577C13.9263 3.39784 13.815 5.16927 12.7642 6.22001L10.4948 8.48947C10.4657 8.51884 10.4494 8.55849 10.4494 8.59982C10.4494 8.64115 10.4657 8.6808 10.4948 8.71017L11.2721 9.48749C11.3326 9.54803 11.4322 9.54803 11.4928 9.48749L13.7622 7.21803C15.4126 5.56574 15.4126 2.892 13.7622 1.23971ZM9.41669 4.7728C9.38732 4.74372 9.34766 4.72741 9.30634 4.72741C9.26501 4.72741 9.22536 4.74372 9.19599 4.7728L4.77231 9.19453C4.74323 9.22389 4.72692 9.26355 4.72692 9.30488C4.72692 9.3462 4.74323 9.38586 4.77231 9.41522L5.54572 10.1886C5.60626 10.2492 5.70587 10.2492 5.76641 10.1886L10.1881 5.7669C10.2487 5.70636 10.2487 5.60675 10.1881 5.54621L9.41669 4.7728Z"
        fill={fill}
      />
    </ShareLinkSvg>
  )
}
