import styled from 'styled-components'

import { BackgroundContainer } from './BackgroundContainer'
import { useSectionSpacingContext } from '../providers/SectionSpacingProvider'
import type { DivProps } from '../ui/Div'
import { Div } from '../ui/Div'
import type { ResponsiveValue } from '../ui/Div/types'

export type SectionComponentLayoutProps = {
  children: React.ReactNode
  hasSpacingTop?: boolean
  hasSpacingHorizontal: boolean
  hasNonDefaultBackgroundSpacingTop?: boolean
  hasNonDefaultBackgroundSpacingVertical?: boolean
  hasSpacingVertical?: boolean
  fullViewportWidth?: boolean
  background?: Required<DivProps['background']>
  flexDirection?: DivProps['flexDirection']
  height?: DivProps['height']
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  anchor?: string
}

export const SectionComponentLayout = ({
  children,
  hasSpacingTop = false,
  hasSpacingHorizontal,
  hasNonDefaultBackgroundSpacingVertical,
  hasNonDefaultBackgroundSpacingTop,
  hasSpacingVertical,
  background = 'background',
  as = 'section',
  fullViewportWidth,
  flexDirection = 'column',
  height,
  anchor,
}: SectionComponentLayoutProps) => {
  const {
    horizontalSpacing,
    spacingVerticalAround,
    spacingBetweenSections,
    anchorsPosition,
  } = useSectionSpacingContext()

  const nonDefaultBackground =
    background !== 'transparent' && background !== 'background'

  return (
    <SpacingContainer
      as={as}
      spacingTop={hasSpacingTop ? spacingBetweenSections : undefined}
      $nonDefaultBackground={nonDefaultBackground}
      className={
        nonDefaultBackground ? NON_DEFAULT_BACKGROUND_CLASS : undefined
      }
    >
      {anchor && (
        <Div
          as="span"
          id={anchor}
          css={{
            display: 'block',
            position: 'relative',
            top: anchorsPosition,
            visibility: 'hidden',
          }}
        />
      )}

      <BackgroundContainer
        background={background}
        fullViewportWidth={fullViewportWidth}
        spacingHorizontal={hasSpacingHorizontal ? horizontalSpacing : undefined}
        spacingTop={
          hasNonDefaultBackgroundSpacingTop && nonDefaultBackground
            ? spacingVerticalAround
            : undefined
        }
        spacingVertical={
          hasSpacingVertical ||
          (hasNonDefaultBackgroundSpacingVertical && nonDefaultBackground)
            ? spacingVerticalAround
            : undefined
        }
        flexDirection={flexDirection}
        width="100%"
        height={height}
      >
        {children}
      </BackgroundContainer>
    </SpacingContainer>
  )
}

const NON_DEFAULT_BACKGROUND_CLASS = 'non-default-background'

const SpacingContainer = styled(Div)<{
  $nonDefaultBackground?: boolean
  spacingTop: ResponsiveValue<number>
}>`
  flex-direction: column;
  width: 100%;
  max-width: 100vw;

  ${({ $nonDefaultBackground }) =>
    $nonDefaultBackground &&
    // If next sibling also has non default background, then the sections should not have spacing between.
    `
    & + .${NON_DEFAULT_BACKGROUND_CLASS} {
      padding-top: 0 !important;
    }
    `}
`
