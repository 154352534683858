import { HeroEditorialContent } from './HeroEditorialContent'
import type { HeroEditorialContentProps } from './HeroEditorialContent'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Image } from '../../ui/Image'
import type { ImageFit } from '../../ui/Image'

export type HeroEditorialImageFit = ImageFit

export type HeroEditorialProps = HeroEditorialContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  imageFit?: HeroEditorialImageFit
  style?: ComponentsThemeStyle
  bordered?: boolean
  anchor?: string
}

const DEFAULT_ALT_TEXT = 'Hero image'

export const HeroEditorial = ({
  mediaUrl,
  mediaAlt,
  imageFit,
  bordered,
  style = 'default',
  anchor,
  ...contentProps
}: HeroEditorialProps) => {
  const { background } = componentsThemeMap[style]

  const textContent = <HeroEditorialContent style={style} {...contentProps} />

  const imageContent = (
    <Image
      priority
      loading="eager"
      alt={mediaAlt || DEFAULT_ALT_TEXT}
      src={mediaUrl}
      maxHeight={{ sm: 170, md: 353, lg: 638 }}
      imageFit={imageFit}
      bordered={bordered}
    />
  )

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HeroEditorial">
        <SectionComponentLayout
          as="header"
          hasSpacingHorizontal
          background={background}
          anchor={anchor}
        >
          <TwoColumnLayout
            mainContentPosition="top"
            mainContent={textContent}
            secondaryContent={imageContent}
            gap={mediaUrl ? 4 : 0}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
