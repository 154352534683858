import type { Entry } from 'contentful'

import { mapEntryLink, mapLinkGroup } from './navlink'
import type {
  CDXSettingsLinkGroupFields,
  CDXSettingsNavLinkFields,
} from './navlink'
import { getEntryProps } from '../utils/getEntryProps'

export type CDXSettingsFooterFields = {
  socialMediaLinks?: Entry<CDXSettingsNavLinkFields>[]
  columnOne?: Entry<CDXSettingsLinkGroupFields>[]
  columnTwo?: Entry<CDXSettingsLinkGroupFields>[]
  columnThree?: Entry<CDXSettingsLinkGroupFields>[]
}

export const mapFooterProps = (
  footer: Entry<CDXSettingsFooterFields> | undefined,
) => {
  const { socialMediaLinks, columnOne, columnTwo, columnThree } = getEntryProps(
    footer,
    {},
  )

  const mappedFooterSocialMediaLinks = socialMediaLinks?.map(mapEntryLink)

  return {
    socialMediaLinks: mappedFooterSocialMediaLinks || [],
    columnOne: columnOne?.map(mapLinkGroup) || [],
    columnTwo: columnTwo?.map(mapLinkGroup) || [],
    columnThree: columnThree?.map(mapLinkGroup) || [],
  }
}
