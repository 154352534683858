import type { Block, TopLevelBlock } from '@contentful/rich-text-types'

type BlockWithCustomProp = Block & {
  content: Pick<Block, 'content'>['content'] & Array<{ toCapitalize?: boolean }>
}

export const capitalizeRichtextParagraph = (
  nodes: BlockWithCustomProp[] & TopLevelBlock[],
) => {
  if (!nodes || nodes.length === 0) return
  let prevNode = ''

  nodes.forEach((node) => {
    const isNotSameAsPrevNode =
      prevNode !== 'paragraph' &&
      prevNode !== 'blockquote' &&
      prevNode !== 'heading-2' &&
      prevNode !== 'embedded-asset-block' &&
      prevNode !== 'embedded-entry-block' &&
      prevNode !== 'unordered-list' &&
      prevNode !== 'ordered-list' &&
      prevNode !== 'empty-paragraph'

    const isParagraphToCapitalize =
      node.nodeType === 'paragraph' &&
      node.content[0]?.nodeType === 'text' &&
      node.content[0]?.value

    const isEmptyParagraph =
      node.content.length === 1 &&
      node.nodeType === 'paragraph' &&
      node.content[0]?.nodeType === 'text' &&
      !node.content[0]?.value

    if (isNotSameAsPrevNode && isParagraphToCapitalize) {
      node.content[0]['toCapitalize'] = true
    }
    prevNode = isEmptyParagraph ? 'empty-paragraph' : node.nodeType
  })
}
