import { useCallback, useState } from 'react'

import styled from 'styled-components'

import { palette } from '@cbhq/cds-web/tokens'

import type { SubNavSearchBarProps } from './SubNavSearchBar'
import { SubNavSearchBar } from './SubNavSearchBar'
import {
  colorToVariable,
  componentsThemeMap,
} from '../../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../../constants'
import { useOnScroll } from '../../../hooks/useOnScroll'
import { SlideInOut } from '../../../layout/SlideInOutContainer'
import { AnalyticsProvider } from '../../../providers/AnalyticsProvider'
import { Div } from '../../../ui/Div'
import { ErrorBoundary } from '../../../ui/ErrorBoundary'
import { Link } from '../../../ui/Link'
import { NAVBAR_SUB_NAV_HEIGHT, SUBNAV_CONTAINER_Z_INDEX } from '../constants'

export type SubNavProps = {
  productName?: string
  productUrl?: string
  subNavLinks: SubNavLinkProps[]
  hasSearch?: boolean
  style?: 'dark' | 'default'
  regionDropdownCTA?: React.ReactNode
  showOnMobileOnly?: boolean
} & SubNavSearchBarProps

export type SubNavLinkProps = {
  label: string
  url?: string
  active: boolean
  isTag?: boolean
}

export const SubNav = ({
  productName,
  productUrl,
  subNavLinks,
  hasSearch,

  searchTags,
  hasBanner,
  onSearch,
  style = 'default',
  regionDropdownCTA,
  showOnMobileOnly = false,
}: SubNavProps) => {
  const colors = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="SubNav">
        <SubnavContainer>
          <Div
            as="nav"
            justifyContent="center"
            width="100%"
            background={colors.background}
            display={{ lg: showOnMobileOnly ? 'none' : 'flex' }}
            css={{
              borderBottom: '1px solid',
              borderColor: colorToVariable('line'),
            }}
          >
            <Div
              zIndex={2}
              width="100%"
              maxWidth={1228}
              spacingHorizontal={3}
              height={NAVBAR_SUB_NAV_HEIGHT}
            >
              {productName ? (
                <Div
                  flexGrow={{ lg: 1 }}
                  flexShrink={1}
                  flexBasis={0}
                  alignItems="center"
                  spacingEnd={3}
                  css={{ whiteSpace: 'nowrap' }}
                >
                  <Link
                    href={productUrl || '/'}
                    variant="body"
                    data-qa={ANALYTICS_KEY.ProductName}
                  >
                    <Div as="span" color={colors.foreground}>
                      {productName}
                    </Div>
                  </Link>
                </Div>
              ) : (
                <Div display={{ sm: 'none', lg: 'block' }} flex={1} />
              )}
              <Div
                flexGrow={1}
                flexShrink={1}
                flexBasis={0}
                alignItems="center"
                gap={{ sm: 2, md: 4 }}
                spacingStart={{ md: 3 }}
                spacingEnd={3}
                spacingTop={2}
                justifyContent="flex-start"
                overflow={{ sm: 'scroll', lg: 'visible' }}
                overflowY="hidden"
                css={{
                  '::-webkit-scrollbar': {
                    width: 0,
                    height: 4,
                  },
                  'scrollbar-width': 'none',
                  '-ms-overflow-style': 'none',
                  '&:hover': {
                    '::-webkit-scrollbar': {
                      width: '100%',
                      background: palette.primaryWash,
                    },
                    'scrollbar-width': '100%',
                    '-ms-overflow-style': 'initital',
                  },
                }}
              >
                {subNavLinks.map(({ url, label, active }, index) => (
                  <Div
                    height="100%"
                    key={url || label || `sub_nav_link_index_${index}`}
                    css={{
                      span: {
                        whiteSpace: 'nowrap',
                        '&:hover': {
                          color: palette.primary,
                        },
                      },
                    }}
                  >
                    <Link
                      href={url || ''}
                      title={label}
                      variant="label1"
                      data-qa={`${ANALYTICS_KEY.SubNavLink}${index}`}
                    >
                      <Div
                        as="span"
                        color={active ? 'primary' : colors.foreground}
                      >
                        {label}
                      </Div>
                    </Link>
                  </Div>
                ))}
              </Div>
              <Div
                display={{ sm: hasSearch ? 'block' : 'none', lg: 'block' }}
                flex={{ sm: 0, lg: 1 }}
              >
                {hasSearch && (
                  <Div
                    justifyContent="flex-end"
                    alignItems="center"
                    height="100%"
                  >
                    <SubNavSearchBar
                      hasBanner={hasBanner}
                      searchTags={searchTags}
                      onSearch={onSearch}
                    />
                  </Div>
                )}
              </Div>
              {!!regionDropdownCTA && regionDropdownCTA}
            </Div>
          </Div>
        </SubnavContainer>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

const SubnavContainer = ({ children }: React.PropsWithChildren<{}>) => {
  const [visible, setVisible] = useState(true)

  const show = useCallback(() => setVisible(false), [])
  const hide = useCallback(() => setVisible(true), [])

  useOnScroll({
    onScrollUp: hide,
    onScrollDown: show,
    disabledUp: visible,
    disabledDown: !visible,
  })

  return <SlideInOutUnderneat show={visible}>{children}</SlideInOutUnderneat>
}

const SlideInOutUnderneat = styled(SlideInOut)`
  z-index: ${SUBNAV_CONTAINER_Z_INDEX};
`
