import type { Asset, Entry } from 'contentful'

import type { NavbarProps } from '@cbhq/cdx-components'

import { mapSettingNavLink, mapEntryLink } from './navlink'
import type {
  CDXSettingsLinkGroupFields,
  CDXSettingsNavLinkFields,
} from './navlink'
import { getEntryProps } from '../utils/getEntryProps'

export const CDX_SETTINGS_NAVBAR_ID = 'cdxSettingsNavbar'

export type CDXSettingsNavbarFields = {
  logoNavLink: Entry<CDXSettingsNavLinkFields>
  primaryCta?: Entry<CDXSettingsNavLinkFields>
  secondaryCta?: Entry<CDXSettingsNavLinkFields>
  navLinks?: Entry<CDXSettingsNavLinkFields | CDXSettingsLinkGroupFields>[]
}

export type NavLinkFields = {
  url?: string
  label?: string
  trapdoorLinks: Entry<TrapdoorFields>[]
}

export type TrapdoorFields = {
  icon?: Asset
  header: string
  subhead?: string
  labelCta?: string
  url?: string
  isProductLink: boolean
}

export const mapNavbarProps = (
  navbarEntry?: Entry<CDXSettingsNavbarFields>,
): NavbarProps => {
  const { logoNavLink, navLinks, primaryCta, secondaryCta } = getEntryProps(
    navbarEntry,
    {},
  )

  const mappedNavbarLinks = navLinks?.map((entry) => {
    const entryProps = getEntryProps(entry)

    if ((entryProps as CDXSettingsNavLinkFields).label) {
      const singleNavLink = entryProps as CDXSettingsNavLinkFields

      return {
        label: singleNavLink.label,
        url: singleNavLink.url,
      }
    }

    const linkGroup = entryProps as CDXSettingsLinkGroupFields

    return {
      label: linkGroup.category,
      trapdoorLinks: linkGroup.links?.map(mapSettingNavLink),
      productLink: linkGroup.categoryLink
        ? mapSettingNavLink(linkGroup.categoryLink)
        : undefined,
    }
  })

  const logoProps = logoNavLink && mapEntryLink(logoNavLink)
  const mainCtaProps = primaryCta && mapEntryLink(primaryCta)
  const secondaryCtaProps = secondaryCta && mapEntryLink(secondaryCta)

  return {
    primaryLogoTitle: logoProps?.label,
    primaryLogoHref: logoProps?.url,
    navLinks: mappedNavbarLinks || [],

    secondaryCtaLabel: secondaryCtaProps?.label,
    secondaryCtaUrl: secondaryCtaProps?.url,
    primaryCtaLabel: mainCtaProps?.label,
    primaryCtaUrl: mainCtaProps?.url,
  }
}

// TODO: temporary mapping until we redefine our theme variables in Contentful, and navbar only supports default and dark for now
export const navbarThemeMap: {
  [key in 'Dark' | 'Light' | 'Blue' | 'Default']: NavbarProps['style']
} = {
  Default: 'default',
  Blue: 'default',
  Dark: 'dark',
  Light: 'default',
}
