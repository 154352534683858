import { useRef, useState } from 'react'

import { IconButton } from '@cbhq/cds-web/buttons/IconButton'
import { Icon } from '@cbhq/cds-web/icons/Icon'

import { SearchInput } from './SearchInput'
import { colorToVariable } from '../../../componentsThemeMap'
import { legacyButtonRadius } from '../../../constants'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { useOnScroll } from '../../../hooks/useOnScroll'
import { SlideIn } from '../../../layout/SlideInContainer'
import { useGlobalMicrocopy } from '../../../providers/MicrocopyProvider'
import { Div } from '../../../ui/Div'
import type { PillTag } from '../../../ui/Tags'
import { Text } from '../../../ui/Text'
import { NAVBAR_BANNER_HEIGHT, NAVBAR_NAV_HEIGHT } from '../constants'

export type SubNavSearchBarProps = {
  searchTags?: PillTag[]
  hasBanner?: boolean
  onSearch?: (term: string) => void
  searchLabel?: string
}

export const SubNavSearchBar = ({
  searchTags = [],
  hasBanner,
  onSearch,
  searchLabel,
}: SubNavSearchBarProps) => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const microcopies = useGlobalMicrocopy()

  useClickOutside(() => setShowSearchBar(false), containerRef, showSearchBar)

  useOnScroll({
    onScrollUp: () => setShowSearchBar(false),
    onScrollDown: () => setShowSearchBar(false),
    disabledUp: !showSearchBar,
    disabledDown: !showSearchBar,
  })

  const bannerHeight = Boolean(hasBanner) ? NAVBAR_BANNER_HEIGHT : 0

  const handleSearch = (nextTerm: string) => {
    setShowSearchBar(false)
    if (onSearch) onSearch(nextTerm)
  }

  return (
    <Div ref={containerRef} position={{ sm: 'unset', md: 'relative' }}>
      <Text
        height={36}
        width={220}
        spacing={1}
        spacingStart={5}
        variant="label2"
        background="gray5"
        color="foregroundMuted"
        display={{ sm: 'none', md: 'flex' }}
        onClick={() => setShowSearchBar(true)}
        css={{
          borderRadius: legacyButtonRadius,

          cursor: 'text',
          border: '1px solid transparent',
          ':hover': {
            border: '1px solid',
            borderColor: colorToVariable('primary'),
          },

          '> div': {
            top: 12,
            left: 14,
            position: 'absolute !important',
          },
        }}
      >
        <Icon name="search" size="s" color="primary" />

        {searchLabel || microcopies.global.search}
      </Text>
      <Div display={{ md: 'none' }}>
        <IconButton
          transparent
          name="search"
          variant="primary"
          onPress={() => setShowSearchBar(true)}
        />
      </Div>

      {showSearchBar && (
        <Div
          zIndex={1}
          position="absolute"
          left={{ sm: 0, md: -120 }}
          width={{ sm: '100%', md: 340 }}
          spacingHorizontal={{ sm: 3, md: 0 }}
          top={{ sm: bannerHeight + NAVBAR_NAV_HEIGHT + 9, md: 0 }}
        >
          <SlideIn>
            <Div
              width="100%"
              alignItems="center"
              flexDirection="column"
              background="background"
              css={{
                borderRadius: legacyButtonRadius,
                border: '1px solid rgba(91, 99, 110, 0.2)',
                boxShadow: '0px 0px 23px rgba(0, 0, 0, 0.08)',
              }}
            >
              <Div
                width="100%"
                spacingVertical={1}
                spacingHorizontal={2}
                flexDirection="column"
              >
                <SearchInput
                  onSearch={handleSearch}
                  showSearchBar={showSearchBar}
                  setShowSearchBar={setShowSearchBar}
                />
              </Div>

              {searchTags && (
                <>
                  <Div maxWidth={1200} width="100%" flexDirection="column">
                    <Div
                      flexDirection="column"
                      spacingHorizontal={2}
                      spacingVertical={{ sm: 1, lg: 2 }}
                    >
                      <Text variant="label1" as="p" color="foregroundMuted">
                        {microcopies.global.suggestedTopics}
                      </Text>

                      {searchTags.map(({ label, url }) => (
                        <Div
                          key={label}
                          spacingTop={1}
                          maxWidth={600}
                          onClick={() => handleSearch(url || '')}
                        >
                          <Text
                            as="p"
                            variant="label2"
                            color="foregroundMuted"
                            css={{
                              ':hover': {
                                color: colorToVariable('primary'),
                                cursor: 'pointer',
                              },
                            }}
                          >
                            {label}
                          </Text>
                        </Div>
                      ))}
                    </Div>
                  </Div>
                </>
              )}
            </Div>
          </SlideIn>
        </Div>
      )}
    </Div>
  )
}
