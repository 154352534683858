export const formatDateWithoutTimeZone = (
  date?: string | null,
  options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  },
): string | undefined => {
  if (!date) return undefined
  return new Date(new Date(date).toISOString().slice(0, -1)).toLocaleDateString(
    undefined,
    options,
  )
}

export const formatDateWithTimeZone = (
  date?: string | null,
  options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: '2-digit',
  },
): string | undefined => {
  if (!date) return undefined
  return new Date(new Date(date).toISOString().slice(0, -1)).toLocaleDateString(
    undefined,
    options,
  )
}
