import { memo, useContext, useMemo } from 'react'

import type { Entry } from 'contentful'

import type { CMSComponentMap, CMSProviderProps } from './CMSProvider'
import { CMSContext } from './CMSProvider'
import { useLogger } from '../../providers/LoggerProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'

export const renderNestedComponents = (
  content: Array<Entry<any>>,
  onError: CMSProviderProps['onError'],
  components: CMSComponentMap = {},
  options?: Record<string, any>,
): React.ReactElement<any>[] | null => {
  if (!content) return null

  return content.map((contentEntry, index) => {
    const contentId = contentEntry?.sys?.contentType?.sys?.id

    if (!contentId) return null

    const Component = components[contentId] || undefined

    if (Component === undefined) {
      onError(`Missing component for ${contentId}`)
      return null
    }

    const props = contentEntry.fields

    return (
      <ErrorBoundary key={index} onError={onError}>
        <Component {...props} options={options} />
      </ErrorBoundary>
    )
  }) as React.ReactElement<any>[]
}

export const CMSContent: React.FunctionComponent<{
  content: Array<Entry<any>> | Entry<any>
  additionalProps?: Record<string, any>
}> = memo(({ content, additionalProps }) => {
  const { onError, components } = useContext(CMSContext)
  const { logError } = useLogger()

  const updatedOptions = useMemo(
    () => ({ ...additionalProps, renderNestedComponents }),
    [additionalProps],
  )
  return renderNestedComponents(
    Array.isArray(content) ? content : [content],
    logError || onError,
    components,
    updatedOptions,
  ) as any
})

CMSContent.displayName = 'CMSContent'
