import styled from 'styled-components'

import { Div } from '../ui/Div'
import type { DivProps } from '../ui/Div'

type BackgroundContainerProps = Omit<DivProps, 'background'> & {
  background: Required<DivProps['background']>
  fullViewportWidth?: boolean
}

export const BackgroundContainer = ({
  children,
  fullViewportWidth = true,
  background,
  ...props
}: BackgroundContainerProps) => {
  const shouldRenderFullWidth =
    fullViewportWidth &&
    background !== 'background' &&
    background !== 'transparent'

  return (
    <Div
      position="relative"
      background={!shouldRenderFullWidth ? background : undefined}
      {...props}
    >
      {shouldRenderFullWidth ? (
        <>
          <BackgroundBleed background={background} />

          <Div
            position="relative"
            flexDirection={props.flexDirection}
            width="100%"
          >
            {children}
          </Div>
        </>
      ) : (
        children
      )}
    </Div>
  )
}

export const BackgroundBleed = styled(Div)`
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  position: absolute;
  top: 0;
  bottom: 0;
`
