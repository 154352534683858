import { useRef, useState, useCallback, useMemo, useEffect } from 'react'

import styled from 'styled-components'

import { IconButton } from '@cbhq/cds-web/buttons/IconButton'
import { Divider } from '@cbhq/cds-web/layout/Divider'
import { palette } from '@cbhq/cds-web/tokens'
import {
  EditorialArticleNavigationSelect,
  SlideIn,
  SlideInOut,
  Div,
  ShareLinks,
  Text,
  useCanonicalUrl,
  useClickOutside,
  useInView,
  useOnScroll,
} from '@cbhq/cdx-components'

import type { SidebarProps } from './Sidebar'
import { useContentfulMicrocopy } from '../../../globals/microcopy'

export const MobileSidebar = ({
  anchors = [],
  showSocialSharingLinks,
}: SidebarProps) => {
  const [showShareBar, setShowShareBar] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const canonicalUrl = useCanonicalUrl()
  const microcopies = useContentfulMicrocopy()

  useClickOutside(() => setShowShareBar(false), containerRef, showShareBar)

  useOnScroll({
    onScrollUp: () => setShowShareBar(false),
    onScrollDown: () => setShowShareBar(false),
    disabledDown: !showShareBar,
    disabledUp: !showShareBar,
  })

  return (
    <Div as="nav" position="relative" ref={containerRef}>
      <MobileSidebarContainer anchors={anchors}>
        <Div position="relative" alignItems="center">
          {anchors.length > 1 && (
            <EditorialArticleNavigationSelect quickScrollContent={anchors}>
              {showSocialSharingLinks && (
                <IconButton
                  variant="primary"
                  transparent={!showShareBar}
                  name="share"
                  onPress={() => setShowShareBar((s) => !s)}
                />
              )}
            </EditorialArticleNavigationSelect>
          )}
        </Div>

        <Divider />

        {showSocialSharingLinks && showShareBar && (
          <SlideIn>
            <Div
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              spacingVertical={3}
              spacingHorizontal={2}
            >
              <Text variant="label1" color="foregroundMuted" as="p">
                {microcopies.global?.share}
              </Text>
              {canonicalUrl && <ShareLinks canonicalUrl={canonicalUrl} />}
            </Div>
            <Divider />
          </SlideIn>
        )}
      </MobileSidebarContainer>
    </Div>
  )
}

const MobileSidebarContainer = ({
  children,
  anchors,
}: React.PropsWithChildren<SidebarProps>) => {
  const ids = useMemo(() => anchors?.map((q) => q.anchor), [anchors])

  const anchorIndexInView = useInView(ids, 20)

  const [selectInView, setSelectInView] = useState(anchorIndexInView !== -1)

  useEffect(() => {
    setSelectInView(anchorIndexInView !== -1)
  }, [anchorIndexInView])

  const [visible, setVisible] = useState(false)

  const show = useCallback(() => setVisible(false), [])
  const hide = useCallback(() => setVisible(true), [])

  useOnScroll({
    onScrollUp: show,
    onScrollDown: hide,
    disabledUp: !visible,
    disabledDown: visible,
  })

  return (
    <FullSizeContainer show={visible && selectInView}>
      {children}
    </FullSizeContainer>
  )
}

const FullSizeContainer = styled(SlideInOut)<{ top?: number }>`
  z-index: 1;
  background: ${palette.background};

  flex-direction: column;
  position: absolute;
  width: 100vw;

  top: ${({ top = 0 }) => top}px;
  height: fit-content;
`
