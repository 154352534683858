import { lazy, Suspense } from 'react'

import FallbackImage from './imageFallback.svg'
import { SkeletonLoader } from '../../ui/SkeletonLoader'
import { Div } from '../Div'
import type { ImageProps } from '../Image'
import { Image } from '../Image'
import type { DynamicLottieProps as ResponsiveLottieProps } from '../Lottie'
import type { VideoPlayerProps } from '../VideoPlayer'

const LazyVideoPlayer = lazy(async () => ({
  default: (await import('../VideoPlayer')).VideoPlayer,
}))

const LazyLottiePlayer = lazy(async () => ({
  default: (await import('../Lottie')).DynamicLottie,
}))

export type MediaType = 'image' | 'video' | 'lotti'

export type MediaProps = {
  mediaType: 'lotti' | 'video' | 'image'
} & Partial<ResponsiveLottieProps> &
  Partial<VideoPlayerProps> &
  Partial<ImageProps>

export const Media = (props: MediaProps) => {
  return props.mediaType === 'image' ? (
    <MediaComponent {...props} />
  ) : (
    <Suspense fallback={<SkeletonLoader />}>
      <MediaComponent {...props} />
    </Suspense>
  )
}

export const MediaComponent = ({
  mediaType,
  thumbnailAlt,
  thumbnailUrl,
  hasLightbox,
  ...mediaProps
}: MediaProps) => {
  if (mediaType === 'image') {
    return (
      <Image
        alt="default alt"
        {...(mediaProps as ImageProps)}
        src={(mediaProps as ImageProps).src || (FallbackImage as any)}
      />
    )
  }

  if (mediaType === 'video') {
    return (
      <Div flexDirection="column" width="100%" justifyContent="center" flex={1}>
        <LazyVideoPlayer
          {...(mediaProps as VideoPlayerProps)}
          thumbnailAlt={thumbnailAlt}
          thumbnailUrl={thumbnailUrl}
          hasLightbox={hasLightbox}
        />
      </Div>
    )
  }
  if (mediaType === 'lotti') {
    return (
      <Div flexDirection="column" width="100%" justifyContent="center">
        <LazyLottiePlayer {...(mediaProps as ResponsiveLottieProps)} />
      </Div>
    )
  }

  return null
}
