import { Div } from '../../Div'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type QuoteProps = RichTextPropsType & {
  css?: Record<string, any>
}

export const Quote = ({
  secondaryColor,
  css,
}: QuoteProps = {}): RenderNodePropsTypes => {
  return function quote(node, children) {
    return (
      <Div
        as="blockquote"
        display="block"
        overflow="hidden"
        color={secondaryColor}
        css={
          css || {
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: 40,
            marginInlineEnd: 40,
          }
        }
      >
        {children}
      </Div>
    )
  }
}
