import { useMemo } from 'react'

import type { Document } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'

import { LogoMark } from '@cbhq/cds-web/icons/LogoMark'
import { Avatar } from '@cbhq/cds-web/media/Avatar'
import type { RichTextOption, PillTag } from '@cbhq/cdx-components'
import {
  Div,
  PillTags,
  Text,
  ArticleSections,
  RichText,
  SectionComponentLayout,
} from '@cbhq/cdx-components'

import { authorBioFormatOptions } from './authorBioFormatOptions'
import type { CDXGlobalAuthorFields } from '../../globals/author'
import { useContentfulMicrocopy } from '../../globals/microcopy'
import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalTagFields } from '../../globals/tag'
import { getEntryProps } from '../../utils/getEntryProps'

export const EDITORIAL_CONTENT_MAX_WIDTH = '700px'

export type EditorialBodyProps = {
  content: Document
  tags?: Entry<CDXGlobalTagFields>[]
  authors?: Entry<CDXGlobalAuthorFields>[]
  richTextOptions?: RichTextOption
  isEmbeddedView?: boolean
}

export const EditorialBody = ({
  content,
  authors = [],
  tags = [],
  richTextOptions,
  isEmbeddedView = false,
}: EditorialBodyProps) => {
  const pillTags = useMemo(() => {
    return tags
      .filter((tag) => tag.fields.type !== 'internal')
      .map((tag) => {
        const { label, link } = getEntryProps(tag)
        const tagLink = mapEntryLink(link)

        return {
          label,
          url: tagLink.url && `/${tagLink.url}`,
        }
      }) as PillTag[]
  }, [tags])

  const authorsRender = authors
    .filter((author) => !!author.fields.authorBioRichText)
    .map(({ fields: { authorName, authorBioRichText, authorImage } }) => ({
      name: authorName,
      bio: authorBioRichText,
      photoUrl: authorImage?.fields?.file?.url,
    }))

  return (
    <SectionComponentLayout hasSpacingHorizontal>
      <Div
        flexDirection="column"
        maxWidth={isEmbeddedView ? undefined : EDITORIAL_CONTENT_MAX_WIDTH}
      >
        <ArticleSections content={content} richTextOptions={richTextOptions} />

        {content && (
          <Div spacingTop={5}>
            <LogoMark size={32} />
          </Div>
        )}
        {pillTags.length > 0 && (
          <Div flexDirection="column" spacingTop={8} gap={2}>
            <PillTags tags={pillTags} />
          </Div>
        )}

        {authorsRender?.length > 0 && (
          <Div spacingTop={8}>
            <AuthorsBio authors={authorsRender} />
          </Div>
        )}
      </Div>
    </SectionComponentLayout>
  )
}

export type AuthorsBioProps = {
  authors?: {
    name: string
    photoUrl?: string
    bio?: Document
  }[]
}

export const AuthorsBio = ({ authors = [] }: AuthorsBioProps) => {
  const microcopies = useContentfulMicrocopy()

  const aboutAuthorsText =
    authors.length === 1
      ? authors?.[0].name
      : `${authors
          .map((author) => author.name)
          .slice(0, authors.length - 1)
          .join(', ')} ${microcopies.global?.and} ${
          authors[authors.length - 1].name
        }`

  return (
    <Div flexDirection="column">
      <Div gap={2} flexWrap="wrap">
        {authors.map(({ name, photoUrl }, index) => (
          <Avatar alt={name} src={photoUrl} size="xxl" key={index} />
        ))}

        <Div flexDirection="column" justifyContent="center">
          <Text variant="label1" as="p">
            {microcopies.global?.about} {aboutAuthorsText}
          </Text>
        </Div>
      </Div>

      {authors.map(({ bio }, index) => {
        if (bio)
          return (
            <Div flexDirection="column" key={index} spacingTop={2}>
              <RichText
                richTextOptions={authorBioFormatOptions}
                content={bio}
              />
            </Div>
          )
      })}
    </Div>
  )
}
