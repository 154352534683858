import { ShareLinkSvg } from '../../../ui/ShareLinks/ShareLinkSvg'

export const Linkedin = ({ fill = 'white', ...rest }) => {
  return (
    <ShareLinkSvg {...rest}>
      <path
        d="M1.80279 3.61926C2.79845 3.61926 3.60558 2.81212 3.60558 1.81646C3.60558 0.820809 2.79845 0.0136719 1.80279 0.0136719C0.807137 0.0136719 0 0.820809 0 1.81646C0 2.81212 0.807137 3.61926 1.80279 3.61926Z"
        fill={fill}
      />
      <path
        d="M5.30676 4.98351V14.9854H8.41221V10.0392C8.41221 8.73411 8.65775 7.47018 10.276 7.47018C11.872 7.47018 11.8917 8.96234 11.8917 10.1216V14.9862H14.9988V9.5012C14.9988 6.8069 14.4188 4.73633 11.2697 4.73633C9.75772 4.73633 8.74426 5.56604 8.32982 6.35126H8.2878V4.98351H5.30676ZM0.246094 4.98351H3.35649V14.9854H0.246094V4.98351Z"
        fill={fill}
      />
    </ShareLinkSvg>
  )
}
