import styled from 'styled-components'

import type { SpectrumAlias } from '@cbhq/cds-common'

import { formatCustomColor } from '../../../componentsThemeMap'
import { Link } from '../../Link'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type HyperLinkProps = RichTextPropsType & {
  dataQa?: string
  target?: string
  styledLink?: string
}

export const InlineHyperLink = ({
  dataQa,
  target = '_self',
  foregroundInlineLink = 'blue60',
}: HyperLinkProps = {}): RenderNodePropsTypes => {
  return function hyperLink(node, children) {
    return children ? (
      <StyledInlineLink
        $color={foregroundInlineLink}
        data-qa={dataQa}
        target={target}
        href={node?.data?.target?.fields?.file?.url || node.data.uri || ''}
      >
        {children}
      </StyledInlineLink>
    ) : null
  }
}

export const StyledInlineLink = styled(Link)<{
  $color: SpectrumAlias
}>`
  word-break: break-word;
  span {
    ${({ $color }) => `
      text-decoration: underline !important;
      color: ${formatCustomColor($color)};
    `}
  }
`
