import { Fragment, useMemo } from 'react'

import { Divider } from '@cbhq/cds-web/layout'
import { Div, Text, LinkPressable } from '@cbhq/cdx-components'

import { groupRelatedArticles } from './groupRelatedArticles'
import type { ComposePageEntry } from '../../../globals/composePage'
import { getComposeEntryProps } from '../../../globals/composePage'
import { useContentfulMicrocopy } from '../../../globals/microcopy'
import type { EditorialTemplateFields } from '../index'

export const RelatedStories = ({
  articles,
}: {
  articles: ComposePageEntry<EditorialTemplateFields>[]
}) => {
  const microcopies = useContentfulMicrocopy()

  const groupedArticles = useMemo(
    () => groupRelatedArticles(articles),
    [articles],
  )

  return (
    <Div
      as="aside"
      maxWidth={320}
      spacingEnd={2}
      spacingTop={4}
      spacingStart={6}
      spacingBottom={2}
      flexDirection="column"
      display={{ sm: 'none', md: 'flex' }}
    >
      <Text variant="legal" color="foregroundMuted" spacingBottom={2}>
        {microcopies.blog?.relatedStories}
      </Text>

      {groupedArticles &&
        Object.entries(groupedArticles).map(([label, articles], index) => (
          <Fragment key={label}>
            <Div flexDirection="column">
              <Text variant="caption" color="foregroundMuted">
                {label.toUpperCase()}
              </Text>
              {articles &&
                articles.map((article, index) => {
                  const { headline, composeSlug } =
                    getComposeEntryProps(article)
                  return (
                    <LinkPressable key={index} href={`/${composeSlug}`}>
                      <Text
                        variant="body"
                        spacingTop={1}
                        color="foregroundMuted"
                      >
                        {headline}
                      </Text>
                    </LinkPressable>
                  )
                })}
            </Div>
            {Object.keys(groupedArticles).length > index + 1 && (
              <Divider spacingVertical={2} />
            )}
          </Fragment>
        ))}
    </Div>
  )
}
