import { useEffect, useMemo, useState } from 'react'

import Cookies from 'js-cookie'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { GlobalNav } from '@cb/components'
import type { GlobalNavComponentProps, UserLocaleInfo } from '@cb/components'
import {
  BANNER_HEIGHT_0,
  BANNER_HEIGHT_45,
  GLOBAL_HEADER_BREAKPOINT_DESKTOP,
  GLOBAL_HEADER_CONTAINER_Z_INDEX,
  SubNav,
  UK_BANNER_HEIGHT,
  useLocaleContext,
} from '@cbhq/cdx-components'

import type { TemplateLayoutProps } from './PageTemplateLayout'
import {
  langPickerSupportedLocales,
  localeCodeToDisplayName,
} from '../globals/locales'
import { getProjectConfigProps } from '../globals/projectSettings'
import { getSiteConfigProps } from '../globals/siteSettings'
import {
  getLocaleCodeWithoutRegion,
  getRegionCodeFromLocale,
} from '../utils/splitLocale'

type Banner = {
  message: string
  url?: string
}

const Header = ({
  isMobile,
  projectConfig,
  siteConfig,
  path,
  onSearch,
  banner,
  userLocaleInfo,
  localeInformation,
}: TemplateLayoutProps &
  GlobalNavComponentProps & {
    isMobile: boolean
    banner?: Banner
  }) => {
  const {
    productName,
    productUrl = '',
    subNavLinks,
    hasSearch,
    searchTags,
    secondaryCtaUrl,
    primaryCtaUrl,
    primaryCtaLabel,
    navbarStyleOverride,
    secondaryLogoHref,
    subNavSecondaryLink,
  } = useMemo(
    () => getProjectConfigProps(projectConfig, path),
    [projectConfig, path],
  )

  const { navbar } = useMemo(() => getSiteConfigProps(siteConfig), [siteConfig])
  const { messages, locale: intlLocale } = useIntl()
  const { locale, defaultLoggedOutConfig, fallbackLoggedOutConfig } =
    useLocaleContext()
  const countryFromIp = Cookies.get('ip_country')?.toUpperCase() || 'US'
  const localeCode = getLocaleCodeWithoutRegion(locale)
  const countryFromUrl = getRegionCodeFromLocale(
    locale,
    defaultLoggedOutConfig,
    fallbackLoggedOutConfig!,
  )

  return (
    <>
      {navbar && (
        <GlobalNav
          key={userLocaleInfo?.countryFromIp}
          userLocaleInfo={{
            ...userLocaleInfo,
            localeCode: locale,
            countryFromUrl,
            countryFromIp,
            languageCode: localeCode,
            localeFromUrl: locale,
          }}
          initIsMobile={isMobile}
          localeInformation={{
            ...localeInformation,
            selectedLocaleCode: localeCode,
          }}
          useRelativeLinks
          themeMode={navbarStyleOverride || navbar.style}
          subNavLinks={
            !hasSearch && subNavLinks.length ? subNavLinks : undefined
          }
          useNewLogo
          mainCTA={{ url: primaryCtaUrl, label: primaryCtaLabel }}
          secondaryCTA={{
            url: secondaryCtaUrl,
            label: navbar.secondaryCtaLabel,
          }}
          signInUrl={secondaryCtaUrl || navbar.secondaryCtaUrl}
          signUpUrl={primaryCtaUrl || navbar.primaryCtaUrl}
          productName={!hasSearch && productUrl ? productName : undefined}
          productUrl={!hasSearch ? productUrl : undefined}
          banner={banner}
          customLogoUrl={secondaryLogoHref}
          locale={intlLocale}
          messages={messages}
          isGlobalPreferencesEnabled={true}
          isCountrySwitchBannerEnabled={true}
          subNavSecondaryLink={subNavSecondaryLink}
        />
      )}
      {hasSearch && (
        <SubNav
          productName={productName}
          productUrl={productUrl}
          subNavLinks={subNavLinks}
          hasSearch={hasSearch}
          searchTags={searchTags}
          onSearch={onSearch}
          hasBanner={!!banner}
        />
      )}
    </>
  )
}

export const ResponsiveHeader = (props: TemplateLayoutProps) => {
  const { projectConfig, siteConfig, path } = props
  const [banner, setBanner] = useState<Banner | undefined>(undefined)
  const [bannerHeight, setBannerHeight] = useState<number>(BANNER_HEIGHT_45)
  const [userLocaleInfo, setUserLocaleInfo] = useState<UserLocaleInfo>({
    countryFromIp: '',
  })

  const ipCountryFromCookie = Cookies.get('ip_country')?.toUpperCase()
  useEffect(() => {
    const { banner: projectSettingsBanner } = getProjectConfigProps(
      projectConfig,
      path,
    )
    const { banner: siteSettingsBanner } = getSiteConfigProps(siteConfig)

    const bannerFromSettings = projectSettingsBanner || siteSettingsBanner

    if (ipCountryFromCookie === 'GB') {
      setUserLocaleInfo({ countryFromIp: 'GB' })
    }
    setBanner(bannerFromSettings)
    setBannerHeight(
      bannerFromSettings || ipCountryFromCookie === 'GB'
        ? BANNER_HEIGHT_0
        : BANNER_HEIGHT_45,
    )
  }, [path, ipCountryFromCookie, projectConfig, siteConfig])

  return (
    <>
      {[false, true].map((isMobile, index) => (
        <LayoutHeader
          bannerHeight={
            isMobile &&
            ipCountryFromCookie === 'GB' &&
            bannerHeight === BANNER_HEIGHT_45
              ? UK_BANNER_HEIGHT
              : bannerHeight
          }
          isMobile={isMobile}
          key={index}
        >
          <Header
            {...props}
            isMobile={isMobile}
            banner={banner}
            userLocaleInfo={{
              ...userLocaleInfo,
            }}
            localeInformation={{
              selectedLocaleCode: '',
              localeCodeToDisplayName,
              supportedLocales: langPickerSupportedLocales,
            }}
          />
        </LayoutHeader>
      ))}
    </>
  )
}

const LayoutHeader = styled.div<{ bannerHeight: number; isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: sticky;
  z-index: ${GLOBAL_HEADER_CONTAINER_Z_INDEX};
  top: 0;

  & > div:first-child {
    position: unset;
    height: ${({ bannerHeight }) => bannerHeight}px;
  }

  & > header {
    position: unset;
    height: auto;

    & > nav a,
    nav span {
      position: static;
    }
  }

  display: none;
  @media ${(props) =>
      props.isMobile
        ? `(max-width: ${GLOBAL_HEADER_BREAKPOINT_DESKTOP}px)`
        : `(min-width: ${GLOBAL_HEADER_BREAKPOINT_DESKTOP}px)`} {
    width: 100%;
    display: flex;
  }
`
