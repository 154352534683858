import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import type {
  NavbarProps,
  PillTag,
  SubNavLinkProps,
} from '@cbhq/cdx-components'

import type { CDXSettingsFooterFields } from './footer'
import { navbarThemeMap } from './navbar'
import { mapEntryLink } from './navlink'
import type { CDXSettingsNavLinkFields } from './navlink'
import type { WayfindingCardArticleFields } from '../components/WayfindingCardArticle'
import { getComposeEntryProps } from '../globals/composePage'
import { getEntryProps } from '../utils/getEntryProps'
import { removeLeadingSlash } from '../utils/removeLeadingSlash'

type SubNavSecondaryLink = {
  label: string
  url: string
}

export type CDXSettingsProjectFields = {
  bannerText?: string
  bannerLink?: Entry<CDXSettingsNavLinkFields>

  productName?: string
  productLink?: Entry<CDXSettingsNavLinkFields>
  secondaryProductLink?: Entry<CDXSettingsNavLinkFields>

  subNavLinks?: Entry<CDXSettingsNavLinkFields>[]
  sharedContent?: Entry<any>[]
  legalDisclaimer?: Document

  hasSearch?: boolean
  suggestedSearchTerms: string[]
  searchLink?: Entry<CDXSettingsNavLinkFields>

  fallbackImage: Asset
  seoFallbackImage: Asset
  defaultSeoDescription?: string

  productId?: string
  hasFooter?: boolean
  hideNavbarLinks?: boolean
  showSignInButton?: boolean
  customLogo?: Asset
  customLogoHref?: string
  navbarTheme?: 'Dark' | 'Light' | 'Blue' | 'Default'
  customNavbarCta?: Entry<CDXSettingsNavLinkFields>
  customNavbarSecondaryCta?: Entry<CDXSettingsNavLinkFields>
  googleSearchConsoleVerificationTag?: string

  customFooter?: Entry<CDXSettingsFooterFields>[]
  defaultEditorialWayfinding?: Entry<WayfindingCardArticleFields>
  flashMessages?: Record<string, string>
}

export type ProjectConfigProps = {
  productName?: string
  productUrl?: string
  subNavLinks: SubNavLinkProps[]
  sharedContent?: Entry<any>[]

  subNavSecondaryLink?: SubNavSecondaryLink
  hasSearch: boolean
  suggestedSearchTerms: string[]
  searchPath: string

  searchTags: PillTag[]
  legalDisclaimer?: Document
  secondaryCtaLabel?: string
  secondaryCtaUrl?: string
  primaryCtaLabel?: string
  primaryCtaUrl?: string
  primaryLogoImageUrl?: string
  secondaryLogoHref?: string

  banner?: {
    message: string
    url?: string
  }

  navbarStyleOverride?: NavbarProps['style']
}

export const getProjectConfigProps = (
  projectConfig: Entry<CDXSettingsProjectFields> | undefined,
  path: string,
): ProjectConfigProps => {
  const {
    productName,
    productLink,
    secondaryProductLink,
    legalDisclaimer,
    subNavLinks,
    sharedContent,
    hasSearch = false,
    suggestedSearchTerms = [],
    customNavbarCta,
    customNavbarSecondaryCta,
    bannerText,
    bannerLink,
    navbarTheme,
    customLogo,
    customLogoHref,
  } = getEntryProps(projectConfig)

  const {
    label: productLinkLabel,
    url,
    composePage: productLinkContent,
  } = getEntryProps(productLink)
  const { composeSlug: productLinkSlug } =
    getComposeEntryProps(productLinkContent)

  const { label: secondaryProductName, url: secondaryProductUrl } =
    getEntryProps(secondaryProductLink)

  const mappedSubNavLinks = subNavLinks?.map((link: any) => {
    const slug = removeLeadingSlash(
      link.fields?.url || link.fields?.composePage?.fields?.slug || path,
    )
    const { url, label, urlLabel } = mapEntryLink(link)

    return {
      ...{ url: url ? url : path, label, urlLabel },
      active: removeLeadingSlash(path) === slug,
    }
  })

  const searchTags = suggestedSearchTerms.map((term) => ({
    label: term,
    url: term,
  }))

  const searchPath = getSearchPath(projectConfig)

  const { label: customNavbarCtaLabel, url: customNavbarCtaUrl } =
    getEntryProps(customNavbarCta)

  const {
    label: customNavbarSecondaryCtaLabel,
    url: customNavbarSecondaryCtaUrl,
  } = getEntryProps(customNavbarSecondaryCta)

  const subNavSecondaryLink =
    secondaryProductUrl && secondaryProductName
      ? {
          label: secondaryProductName,
          url: secondaryProductUrl,
        }
      : undefined

  return {
    productName: productName || productLinkLabel,
    productUrl: productLinkSlug || url,

    subNavSecondaryLink,
    subNavLinks: mappedSubNavLinks || [],
    sharedContent,

    hasSearch,
    suggestedSearchTerms,
    searchPath,

    searchTags,
    legalDisclaimer,
    primaryLogoImageUrl: customLogo?.fields?.file?.url,
    secondaryLogoHref: customLogoHref,
    primaryCtaLabel: customNavbarCtaLabel,
    primaryCtaUrl: customNavbarCtaUrl,
    secondaryCtaLabel: customNavbarSecondaryCtaLabel,
    secondaryCtaUrl: customNavbarSecondaryCtaUrl,

    banner: bannerText
      ? {
          message: bannerText,
          url: bannerLink ? mapEntryLink(bannerLink).url : '',
        }
      : undefined,
    navbarStyleOverride: navbarTheme
      ? navbarThemeMap[navbarTheme as keyof typeof navbarThemeMap]
      : undefined,
  }
}

const replaceSearchUrl = (url: string): string => {
  const regex = /^https:\/\/[^\/]+\.([^\/]+\.[^\/]+)(?:\/(.+?)\/)?.*/
  const match = url.match(regex)
  if (match) {
    const basePath = match[2]
    return url.replace(regex, `/${basePath}/search`)
  }
  return url
}

export const getSearchPath = (
  projectSettings: Entry<CDXSettingsProjectFields> | undefined,
): string => {
  const { searchLink, productLink } = getEntryProps(projectSettings)
  // const basePath = productLink?.fields?.url || ''

  const { url } = mapEntryLink(searchLink)

  const newUrl = replaceSearchUrl(url)
  return newUrl
}
