import { Div } from '../../ui/Div'
import { Pressable } from '../Button/index'
import { Text } from '../Text'

export type Option = {
  value?: string
  label: string
}

export type SelectOptionsProps = {
  changeHandler: (data: Option) => void
  optionValues: Array<Option>
  selectedOption?: Option
}

type SelectOptionsInnerProps = {
  top?: number
}

export const SelectOptions = ({
  optionValues,
  changeHandler,
  selectedOption,
  top,
}: SelectOptionsProps & SelectOptionsInnerProps) => {
  if (optionValues.length === 0) {
    return null
  }

  return (
    <Div
      flexDirection="column"
      position="absolute"
      top={top}
      width="100%"
      background="background"
      elevation={1}
      zIndex={1}
      maxHeight="560px"
      borderRadius="rounded"
      maxWidth="inherit"
      overflow="auto"
    >
      {optionValues?.map((option, index) => (
        <Pressable
          key={`select-option-${index}`}
          as="button"
          background={
            selectedOption?.value === option.value
              ? 'backgroundAlternate'
              : 'background'
          }
          onPress={() => changeHandler(option)}
          borderRadius="rounded"
          noScaleOnPress
        >
          <Div
            flexDirection="column"
            spacingHorizontal={3}
            spacingVertical={1}
            minHeight={56}
            justifyContent="center"
          >
            <Text
              variant="headline"
              as="p"
              overflow="truncate"
              color="foreground"
              css={{ textAlign: 'start' }}
            >
              {option.label}
            </Text>
          </Div>
        </Pressable>
      ))}
    </Div>
  )
}
