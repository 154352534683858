import type { Document } from '@contentful/rich-text-types'

import { editorialHeroFormatOptions } from './editorialHeroFormatOptions'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { useCanonicalUrl } from '../../providers/CanonicalUrlProvider'
import { useGlobalMicrocopy } from '../../providers/MicrocopyProvider'
import type { CTAButtonsProps } from '../../ui/CTAButtons'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { Link } from '../../ui/Link'
import { RichText } from '../../ui/RichText'
import { ShareLinks } from '../../ui/ShareLinks'
import { Text } from '../../ui/Text'

export type HeroEditorialContentProps = CTAButtonsProps & {
  headline: string
  subhead?: Document

  author?: string
  datePublished?: string
  categoryTagLabel?: string
  categoryTagUrl?: string
  readTime?: string
  showSocialSharingLinks?: boolean
}

type HeroEditorialContentInnerProps = {
  style: ComponentsThemeStyle
}

export const HeroEditorialContent = ({
  headline,
  subhead,

  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,

  author,
  datePublished,
  categoryTagLabel,
  categoryTagUrl,
  readTime,

  style,
  showSocialSharingLinks,
}: HeroEditorialContentProps & HeroEditorialContentInnerProps) => {
  const { foreground, foregroundSecondary, background, primary } =
    componentsThemeMap[style]

  const canonicalUrl = useCanonicalUrl()

  const microcopies = useGlobalMicrocopy()

  return (
    <Div
      flexGrow={1}
      spacingTop={8}
      overflow="hidden"
      flexDirection="column"
      justifyContent="center"
    >
      <Text as="h1" variant="display2" color={foreground}>
        {headline}
      </Text>

      {subhead && (
        <Div spacingTop={{ sm: 1, md: 2, lg: 3 }} flexDirection="column">
          <RichText
            richTextOptions={editorialHeroFormatOptions}
            content={subhead}
            color={foregroundSecondary}
          />
        </Div>
      )}

      <CTAButtons
        ctaButtonLabel={ctaButtonLabel}
        ctaButtonUrl={ctaButtonUrl}
        secondaryButtonLabel={secondaryButtonLabel}
        secondaryButtonUrl={secondaryButtonUrl}
        contentBackground={background}
      />

      <Div
        spacingTop={2}
        rowGap={2}
        columnGap={{ sm: 2, md: 4 }}
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems={{ sm: 'start', md: 'baseline' }}
        flexWrap="wrap"
      >
        <Text variant="legal" as="p" color={foregroundSecondary}>
          {microcopies.global.by} {author || microcopies.global.author}
        </Text>

        <Div flexWrap="wrap" alignItems="baseline">
          {categoryTagLabel &&
            (categoryTagUrl ? (
              <Link
                href={categoryTagUrl || ''}
                data-qa={ANALYTICS_KEY.Category}
              >
                <Text
                  variant="legal"
                  css={{ textDecoration: 'underline' }}
                  as="p"
                  color={primary}
                >
                  {categoryTagLabel}
                </Text>
              </Link>
            ) : (
              <Text variant="legal" as="p" color={foreground}>
                {categoryTagLabel}
              </Text>
            ))}

          <Text variant="legal" as="p" color={foregroundSecondary}>
            {categoryTagLabel && (datePublished || readTime) && ', '}
            {datePublished}
          </Text>

          {readTime && (
            <Text variant="legal" as="p" color={foregroundSecondary}>
              {(categoryTagLabel || datePublished) && ', '}
              {readTime} {microcopies.global.readTime}
            </Text>
          )}
        </Div>

        {showSocialSharingLinks && canonicalUrl && (
          <Div display={{ sm: 'flex', md: 'none' }}>
            <ShareLinks canonicalUrl={canonicalUrl} />
          </Div>
        )}
      </Div>
    </Div>
  )
}
