import { Div } from '../../Div'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type ListProps = RichTextPropsType & {
  as: keyof JSX.IntrinsicElements | React.ComponentType<any>
  css?: Record<string, any>
}

export const List = ({
  as,
  css,
  color,
  secondaryColor,
}: ListProps): RenderNodePropsTypes => {
  const listStyleType =
    as === 'ol' ? { listStyleType: 'decimal' } : { listStyleType: 'disc' }

  return function list(node, children) {
    return (
      <Div
        as={as}
        display="block"
        spacingStart={3}
        css={css || listStyleType}
        color={color}
      >
        {children}
      </Div>
    )
  }
}
