import { useEffect, useState } from 'react'

import styled, { css, keyframes } from 'styled-components'

const slideInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const slideOutAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
`

export type SlideInOutProps = {
  show: boolean
  className?: string
  children?: React.ReactNode
}

export const SlideInOut = ({ show, className, children }: SlideInOutProps) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [setMounted])

  return (
    <SlideInOutContainer className={className} show={show} mounted={mounted}>
      {children}
    </SlideInOutContainer>
  )
}

export const SlideInOutContainer = styled.div<{
  show: boolean
  mounted: boolean
}>`
  width: 100%;

  animation: ${slideInAnimation} ${({ mounted }) => (mounted ? 200 : 0)}ms
    ease-in;

  ${({ show, mounted }) =>
    !show &&
    css`
      animation: ${mounted ? 200 : 0}ms ${slideOutAnimation} forwards;
    `}
`
