import type { ComposePageEntry } from '../../../globals/composePage'
import type { EditorialTemplateFields } from '../index'

type RelatedArticles = {
  [key: string]: ComposePageEntry<EditorialTemplateFields>[]
}

export const groupRelatedArticles = (
  articles: ComposePageEntry<EditorialTemplateFields>[],
): RelatedArticles => {
  const filteredArticles: RelatedArticles = {}

  articles &&
    articles.forEach((article) => {
      const tag = article?.fields?.content?.fields?.tags
        ? article.fields.content.fields.tags[0]?.fields.label
        : ''

      if (!tag) return

      if (filteredArticles[tag]) {
        filteredArticles[tag].push(article)
        return
      }
      filteredArticles[tag] = [article]
    })

  return filteredArticles
}
