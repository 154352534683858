export const getLocaleCodeWithoutRegion = (locale: string): string => {
  return locale.toLowerCase().split(/[_-]+/)[0] || 'en'
}

type SupportedCountryFromUrl = 'US' | 'DE' | 'GB' | 'FR'

export const getRegionCodeFromLocale = (
  locale: string = '',
  defaultLoggedOutConfig: Record<string, any> = {},
  fallbackLoggedOutConfig: string[],
): SupportedCountryFromUrl => {
  // If there's no locale in url or the locale is a fallback locale
  if (!locale || fallbackLoggedOutConfig.includes(locale)) {
    return 'US'
  }

  // If there's no delimiter, then it is a default_single_path locale
  const country = locale.split('-')[1] || locale
  if (defaultLoggedOutConfig[country]) {
    return defaultLoggedOutConfig[country]
      .country_code as SupportedCountryFromUrl
  }

  return 'US'
}

export default getRegionCodeFromLocale
