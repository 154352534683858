import type { ResponsiveValue } from '../../Div/types'
import { Text } from '../../Text'
import type { TextVariant } from '../../Text'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type ParagraphProps = RichTextPropsType & {
  display?: string
  css?: Record<string, string | number>
  variant?: ResponsiveValue<TextVariant>
}

export const Paragraph = ({
  css,
  color,
  variant = 'body',
  display = 'block',
  paragraphSpacingTop,
}: ParagraphProps = {}): RenderNodePropsTypes =>
  function paragraph(node, children) {
    const isCodeType = node.content.some(
      (item: any) =>
        item.marks &&
        item.marks.some((mark: Record<string, string>) => mark.type === 'code'),
    )
    return (
      <Text
        as={isCodeType ? 'div' : 'p'}
        color={color}
        display={display}
        variant={variant}
        spacingTop={paragraphSpacingTop}
        css={{
          wordBreak: 'normal',
          overflowWrap: 'anywhere',
          whiteSpace: 'break-spaces',
          ...css,
        }}
      >
        {children}
      </Text>
    )
  }
