import { CMSContent } from '../../CMSContent'
import { Div } from '../../Div'
import type { RichTextPropsType, RenderNodePropsTypes } from '../types'

type BlocksEmbeddedEntryProps = RichTextPropsType & {
  additionalProps?: Record<string, any>
}

export const BlockEmbeddedEntry = ({
  defaultSpacingTop,
  additionalProps,
}: BlocksEmbeddedEntryProps = {}): RenderNodePropsTypes =>
  function embeddedEntry(node, children) {
    return (
      <Div display="block" spacingTop={defaultSpacingTop}>
        <CMSContent
          content={node?.data?.target}
          additionalProps={additionalProps}
        />
      </Div>
    )
  }
