import type { Entry } from 'contentful'

import type { PartialPaletteConfig, Spectrum } from '@cbhq/cds-web'
import type { ThemeProviderProps } from '@cbhq/cds-web/system/ThemeProvider'
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider'
import {
  Div,
  useLogger,
  CMSProvider,
  MicrocopyProvider,
} from '@cbhq/cdx-components'
import type { CMSComponentMap } from '@cbhq/cdx-components'

import { fallbackFonts } from '../globals/fallbackFonts'
import type { CDXSettingsSiteFields } from '../globals/siteSettings'
import { getSiteConfigMicrocopies } from '../globals/siteSettings'

export type PageTemplateProvidersProps = {
  componentMap?: CMSComponentMap
  cdsThemeMode?: Spectrum
  cdsPalette?: PartialPaletteConfig
  locale?: string
}

type PageTemplateProviderInnerProps = {
  children?: React.ReactNode | React.ReactNode[]
  siteConfig?: Entry<CDXSettingsSiteFields>
}

const ThemeProviderWithChildren = ThemeProvider as React.FunctionComponent<
  React.PropsWithChildren<ThemeProviderProps>
>

/**
 * Sets up component maps and themes for a given template.
 * This ComponentProvider depends on cdx-component's ComponentProvider being instantiated.
 */
export const PageTemplateProviders = ({
  componentMap = {},
  children,
  siteConfig,
  locale = 'en',
  cdsPalette,
  cdsThemeMode,
}: PageTemplateProvidersProps & PageTemplateProviderInnerProps) => {
  const { logError } = useLogger()
  const microcopies = getSiteConfigMicrocopies(siteConfig)

  return (
    <MicrocopyProvider microcopies={microcopies}>
      <CMSProvider locale={locale} onError={logError} components={componentMap}>
        <ThemeProviderWithChildren spectrum={cdsThemeMode} palette={cdsPalette}>
          <Div css={{ fontFamily: `'CoinbaseSans', ${fallbackFonts}` }}>
            {children}
          </Div>
        </ThemeProviderWithChildren>
      </CMSProvider>
    </MicrocopyProvider>
  )
}
